import { createAsyncThunk } from '@reduxjs/toolkit';
import { differenceBy, find, groupBy, maxBy, unionBy } from 'lodash';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { services } from 'services';
import * as Types from 'types';
import {
  SELECT_CURRICULUM_CREATOR,
  SELECT_QUESTION,
  SELECT_TYPES,
  SYSTEMMASTER,
  COMPANIES,
  QUESTIONS,
  ATTACH,
  USERS,
  SELECT_OFFICIAL_CURRICULUM_NAME,
  OFFICIAL_CURRICULUM_MASTER,
  QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM,
  LEVEL4S_OFFICIAL_CURRICULUM,
  LEVEL3S_OFFICIAL_CURRICULUM,
  LEVEL2S_OFFICIAL_CURRICULUM,
  LEVEL1S_OFFICIAL_CURRICULUM,
} from 'configs';
import { browserLogger } from 'libs/logger';

export const getDataLevel1 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level1sOfficialCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level1sOfficialCurriculum.ResponseType>(
      LEVEL1S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('Question/thunk/getDataLevel1', LEVEL1S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level2sOfficialCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level2sOfficialCurriculum.ResponseType>(
      LEVEL2S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('Question/thunk/getDataLevel2', LEVEL2S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel3 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level3sOfficialCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level3sOfficialCurriculum.ResponseType>(
      LEVEL3S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('Question/thunk/getDataLevel3', LEVEL3S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel4 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level4sOfficialCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level4sOfficialCurriculum.ResponseType>(
      LEVEL4S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('Question/thunk/getDataLevel4', LEVEL4S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuesLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevelOfficialCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQuesLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } =
      await services.search<Types.QuestionAssignLevelOfficialCurriculum.ResponseType>(
        QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
        {
          ...req,
          sort_fields: [{ id: 'sort_order', order: 'asc' }],
        }
      );
    browserLogger.info(
      'Question/thunk/getDataQuesLevel',
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.name, req);
    browserLogger.info('Question/thunk/getDataQues', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUser = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.name, req);
    browserLogger.info('Question/thunk/getUser', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataTableQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.TreeTraining>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataTableQuestion', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { data } = await services.search<Types.OfficialCurriculumMaster.ResponseType>(
      OFFICIAL_CURRICULUM_MASTER.name,
      req
    );
    browserLogger.info(
      'Question/thunk/getDataTableQuestion',
      OFFICIAL_CURRICULUM_MASTER.name,
      data
    );
    const { authContainer } = getState() as Types.RootState;

    const responseStatus = await Promise.all([
      dispatch(
        getDataLevel1({
          conditions: [
            {
              id: 'provider_id',
              search_value: [authContainer?.userInfo?.company_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel2({
          conditions: [
            {
              id: 'provider_id',
              search_value: [authContainer?.userInfo?.company_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel3({
          conditions: [
            {
              id: 'provider_id',
              search_value: [authContainer?.userInfo?.company_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel4({
          conditions: [
            {
              id: 'provider_id',
              search_value: [authContainer?.userInfo?.company_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQuesLevel({
          conditions: [
            {
              id: 'provider_id',
              search_value: [authContainer?.userInfo?.company_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQues({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    let resultArray: Array<any> = [];
    let arrayQuestion: Array<any> = [];
    let flattenedResultArray: Array<Types.TreeTraining> = [];
    let resultMatchUser: Array<any> = [];

    if (
      getDataLevel1.fulfilled.match(responseStatus[0]) &&
      getDataLevel2.fulfilled.match(responseStatus[1]) &&
      getDataLevel3.fulfilled.match(responseStatus[2]) &&
      getDataLevel4.fulfilled.match(responseStatus[3]) &&
      getDataQuesLevel.fulfilled.match(responseStatus[4]) &&
      getDataQues.fulfilled.match(responseStatus[5]) &&
      getUser.fulfilled.match(responseStatus[6])
    ) {
      const dataLevel1 = responseStatus[0].payload.items;
      const dataLevel2 = responseStatus[1].payload.items;
      const dataLevel3 = responseStatus[2].payload.items;
      const dataLevel4 = responseStatus[3].payload.items;
      const dataAssignLevel = responseStatus[4].payload.items;
      const formatQuestions = responseStatus[5].payload.items.map((item) => ({
        ...item,
        original_question_code: item.original_question_code || item.code,
        version_number: item.version_number || 1,
      }));

      const dataQues = Object.values(groupBy(formatQuestions, 'original_question_code')).map(
        (items: Array<Types.Questions.ResponseType>) =>
          maxBy(items, (item) =>
            typeof item.version_number === 'string'
              ? parseInt(item.version_number, 10)
              : item.version_number
          )
      ) as Array<Types.Questions.ResponseType>;

      const dataUser = responseStatus[6].payload.items;
      resultArray = await Promise.all(
        data.items.flatMap((item) => {
          const baseObject = {
            curriculum_code: item.official_curriculum_code,
            curriculum_name: item.name,
            curriculum_description: item.description,
            curriculum_sort_order: item.sort_order,
            required_curriculum: item.required_curriculum,
          };
          const matchedLevel1Items = dataLevel1.filter(
            (c) => c.official_curriculum_code === item.official_curriculum_code
          );

          return matchedLevel1Items.flatMap((level1Item) => {
            const mergedObject1 = {
              ...baseObject,
              level1_i_id: level1Item.i_id,
              level1_name: level1Item.name,
              level1_code: level1Item.code,
              level1_sort_order: level1Item.sort_order,
            };

            const matchedLevel2Items = dataLevel2.filter(
              (level2Item) => level2Item.level1_code === level1Item.code
            );

            return matchedLevel2Items.flatMap((level2Item) => {
              const mergedObject2 = {
                ...mergedObject1,
                level2_i_id: level2Item.i_id,
                level2_name: level2Item.name,
                level2_code: level2Item.code,
                level2_sort_order: level2Item.sort_order,
              };
              const matchedLevel3Items = dataLevel3.filter(
                (level3Item) => level3Item.level2_code === level2Item.code
              );

              return matchedLevel3Items.flatMap((level3Item) => {
                const mergedObject3 = {
                  ...mergedObject2,
                  level3_i_id: level3Item.i_id,
                  level3_name: level3Item.name,
                  level3_code: level3Item.code,
                  level3_sort_order: level3Item.sort_order,
                };
                const matchedLevel4Items = dataLevel4.filter(
                  (level4Item) => level4Item.level3_code === level3Item.code
                );

                return matchedLevel4Items.flatMap((level4Item) => {
                  const mergedObject4 = {
                    ...mergedObject3,
                    level4_i_id: level4Item.i_id,
                    level4_name: level4Item.name,
                    level4_code: level4Item.code,
                    level4_sort_order: level4Item.sort_order,
                  };
                  const matchedQuestionItems = dataAssignLevel
                    .filter((que) => que.level4_code === level4Item.code)
                    .map((que) => ({
                      ...que,
                      question_sort_order: que.sort_order,
                      question_assign_level_i_id: que.i_id,
                    }));

                  return matchedQuestionItems
                    .flatMap((ques) => {
                      const questionFounded = find(dataQues, { code: ques.code });

                      if (questionFounded) {
                        return {
                          ...mergedObject4,
                          question_code_i_id: questionFounded.i_id,
                          i_id: questionFounded.i_id,
                          question_code: questionFounded.code,
                          question_name: questionFounded.name,
                          createdby: questionFounded.createdby,
                          attach_fileID1: questionFounded.attach_fileID1,
                          attach_fileID2: questionFounded.attach_fileID2,
                          attach_fileID3: questionFounded.attach_fileID3,
                          question_description: questionFounded.description,
                          problems1_attach_fileID: questionFounded.problems1_attach_fileID,
                          problems2_attach_fileID: questionFounded.problems2_attach_fileID,
                          problems3_attach_fileID: questionFounded.problems3_attach_fileID,
                          comment_attach_fileID1: questionFounded.comment_attach_fileID1,
                          comment_attach_fileID2: questionFounded.comment_attach_fileID2,
                          comment_attach_fileID3: questionFounded.comment_attach_fileID3,
                          question_score: questionFounded.score,
                          score: questionFounded.score,
                          question_comment: questionFounded.comment,
                          question_answer: questionFounded.answer,
                          question_time_limit: questionFounded.time_limit,
                          problems1: questionFounded.problems1,
                          problems2: questionFounded.problems2,
                          problems3: questionFounded.problems3,
                          question_sort_order: ques.question_sort_order || 0,
                          question_assign_level_i_id: ques.question_assign_level_i_id,
                          original_question_code: questionFounded?.original_question_code
                            ? questionFounded.original_question_code
                            : questionFounded.code,
                          version_number: questionFounded.version_number
                            ? questionFounded.version_number
                            : 1,
                        };
                      }
                    })
                    .filter(Boolean);
                });
              });
            });
          });
        })
      );

      flattenedResultArray = resultArray.flat();
      const question = dataQues.map((que) => ({
        ...que,
        curriculum_code: '',
        curriculum_name: '',
        required_curriculum: '',
        question_code_i_id: que.i_id,
        question_code: que.code,
        question_name: que.name,
        createdby: que.createdby,
        question_description: que.description,
        score: que.score,
        original_question_code: que?.original_question_code ? que.original_question_code : que.code,
        version_number: que.version_number ? que.version_number : 1,
      }));
      arrayQuestion = differenceBy(
        question,
        unionBy(flattenedResultArray, 'question_code'),
        'question_code'
      );

      resultMatchUser = await Promise.all(
        [...flattenedResultArray, ...arrayQuestion].flatMap((j) => {
          const Obj = {
            ...j,
          };
          const matchUser = dataUser.filter((u) => u.login_id === j.createdby);
          if (!matchUser.length) {
            return Obj;
          }
          return matchUser.flatMap((m) => ({
            ...Obj,
            creator: m.name,
          }));
        })
      );
    }

    return {
      ...data,
      items: [...resultMatchUser],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectCurriculums.ResponseSelectType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectCurriculums.ResponseSelectType>(
      SELECT_OFFICIAL_CURRICULUM_NAME.name,
      req
    );
    browserLogger.info(
      'Question/thunk/getSelectCurriculum',
      SELECT_OFFICIAL_CURRICULUM_NAME.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectTypes = createAsyncThunk<
  Types.GetItemResponseType<Types.CreateEditSkillCheckSelectType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectTypes', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.CreateEditSkillCheckSelectType>(
      SELECT_TYPES.name,
      req
    );
    browserLogger.info('Question/thunk/getSelectTypes', SELECT_TYPES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCurriculumCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumCreatorSelect>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectCurriculumCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.CurriculumCreatorSelect>(
      SELECT_CURRICULUM_CREATOR.name,
      req
    );
    browserLogger.info(
      'Question/thunk/getSelectCurriculumCreator',
      SELECT_CURRICULUM_CREATOR.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.QuestionSelect>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.QuestionSelect>(SELECT_QUESTION.name, req);
    browserLogger.info('Question/thunk/getSelectQuestion', SELECT_QUESTION.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/createImages', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);
    browserLogger.info('Question/thunk/createFileAttach', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTIONS.name, req);
    browserLogger.info('Question/thunk/deleteQuestion', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteQuestionByConditions = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteQuestionByConditions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileQuestion = createAsyncThunk<
  any,
  Types.DeleteFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteFileQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteFile(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getAttachQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.name, req);
    browserLogger.info('Question/thunk/getAttachQuestion', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.name, req);
    browserLogger.info('Question/thunk/deleteFile', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editQuestion = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Questions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/editQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(QUESTIONS.name, req);
    browserLogger.info('Question/thunk/editQuestion', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionDetail = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getQuestionDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.name, req);
    browserLogger.info('Question/thunk/getQuestionDetail', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAllQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataAllQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.name, req);
    browserLogger.info('Question/thunk/getDataAllQuestion', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSystemmaster = createAsyncThunk<
  Types.GetItemResponseType<Types.GetSystemmasterType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSystemmaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.GetSystemmasterType>(SYSTEMMASTER.name, req);
    browserLogger.info('Question/thunk/getSystemmaster', SYSTEMMASTER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateSystemmaster = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Questions.ResponseUpdateSystemmasterType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/updateSystemmaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(SYSTEMMASTER.name, req);
    browserLogger.info('Question/thunk/updateSystemmaster', SYSTEMMASTER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateTimeLimitCompany = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/updateTimeLimitCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.name, req);
    browserLogger.info('Question/thunk/updateTimeLimitCompany', COMPANIES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCompany = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getCompany', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.name, req);
    browserLogger.info('Question/thunk/getCompany', COMPANIES.name, data);
    dispatch(stopLoading());
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
