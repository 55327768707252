import React, { useEffect, useRef, useState } from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Checkbox, Select } from 'antd';

import { DatePicker, SelectField } from 'components';
import SaleInformationStyled from './styles';
import Chart from './Chart';
import Table from './Table';
import * as Types from 'types';
import { AnyObject } from 'types';
import { useAppDispatch } from 'hooks';
import {
  getDataReportMonthlyUsagesAccumulativeDataCorporation,
  getDataReportMonthlyUsagesAccumulativeDataIndividual,
  getDataReportSaleInformation,
  getDataReportSaleInformationAccumulativeData,
  getDataReportSaleInformationBreakdown,
  getDataReportSaleInformationBreakdownDetail,
} from 'pages/Report/SaleInformation/thunk';
import { useSelector } from 'react-redux';
import { reportSaleInformationSelector } from 'pages/Report/SaleInformation/selectors';
import Label from 'components/Label';
import PDFSaleInformation from 'pages/Report/SaleInformation/PDFSaleInformation';
import ModalConfirmExport from 'components/Modal/ConfirmExportFile';
import { exportPDFFitOnePage } from 'libs/utils/exportPDF';
import { uniq } from 'lodash';
import dayjs from 'dayjs';
import { groupSaleReportBreakdownData, groupSaleReportData } from 'libs/utils/report';
import { exportCsv } from 'libs/utils/exportCsv';
import { pdf } from '@react-pdf/renderer';
import PDFMonthlyByUserDevelopment from 'pages/Report/SaleInformation/Table/PDFMonthlyByUserDevelopment';
import saveAs from 'file-saver';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { getMonthListFromDateRanger } from 'libs/utils/calculate';
import {
  resetData,
  setDateRanger,
  setFilterUser,
  setSaleType,
  setUserType,
} from 'pages/Report/SaleInformation/slice';
import { SALE_INFORMATION_REPORT_TYPE } from 'constant/report';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface SaleInformationProp {
  openConfirmExport: boolean;
  setOpenConfirmExport: React.Dispatch<React.SetStateAction<boolean>>;
}

const USER_TYPE = {
  ALL: '全ユーザー',
  CORPORATE: '法人ユーザー',
  PERSONAL: '個人ユーザー',
};

const SALE_TYPE = {
  ALL: 'ALL',
  USER_FEE: 'ユーザー利用料',
  SKILL_CHECK_USAGE_FEE: 'スキルチェック利用料',
  STORAGE_USAGE_FEE: 'ストレージ利用料',
};

const SaleInformation: React.FC<SaleInformationProp> = (props) => {
  const { openConfirmExport, setOpenConfirmExport } = props;
  const [visibleExpansionOfSale, setVisibleExpansionOfSale] = useState<boolean>(false);
  const [visibleCountUsage, setVisibleCountUsage] = useState<boolean>(false);
  const [valueSelect, setValueSelect] = useState<string | undefined>();
  const [segmented, setSegmented] = useState<number>(0);
  const [selectedDates, setSelectedDates] = useState<any>([]);

  const {
    reportSaleInformationList,
    dateRanger,
    reportSaleInformationBreakdownList,
    reportSaleInformationBreakdownDetailList,
    userOptions,
  } = useSelector(reportSaleInformationSelector);

  const dispatch = useAppDispatch();
  const ref = useRef(null);

  const formik = useFormik({
    initialValues: {
      aggregation_method: undefined,
      name: '',
      user_type: USER_TYPE.ALL,
      sale_type: SALE_TYPE.ALL,
      target_month: [],
    },
    onSubmit: async (values) => {
      setValueSelect(values.aggregation_method);
      dispatch(setUserType(values.user_type));
      dispatch(setSaleType(values.sale_type));
      dispatch(setFilterUser(values.name));
      dispatch(setDateRanger(values.target_month));
      dispatch(resetData(values));
      await fetchData(getConditions(values), values.aggregation_method);
    },
    onReset: () => {
      dispatch(setUserType(USER_TYPE.ALL));
      dispatch(setSaleType(SALE_TYPE.ALL));
      dispatch(setFilterUser(''));
      setValueSelect(undefined);
      setSelectedDates([]);
      formik.setFieldValue('aggregation_method', undefined);
      formik.setFieldValue('target_month', []);
    },
  });

  useEffect(() => {
    if (
      valueSelect === SALE_INFORMATION_REPORT_TYPE.MONTHLY_TRENDS_BY_USER.key &&
      visibleCountUsage &&
      dateRanger?.length
    ) {
      fetchReportBreakDownDetails();
    }
    if (
      valueSelect === SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_TRENDS_BY_USER.key &&
      visibleCountUsage &&
      dateRanger?.length
    ) {
      fetchReportMonthlyUsagesAccumulativeDataIndividual();
    }
  }, [visibleCountUsage, valueSelect, dateRanger]);

  const fetchData = async (conditions: Array<Types.ConditionsType>, aggregationMethod?: string) => {
    if (!selectedDates || !selectedDates.length || !selectedDates[0] || !selectedDates[1]) {
      return;
    }
    dispatch(startLoading());
    if (aggregationMethod === SALE_INFORMATION_REPORT_TYPE.MONTHLY_TREND.key) {
      await fetchReports(conditions);
    }
    if (aggregationMethod === SALE_INFORMATION_REPORT_TYPE.MONTHLY_TRENDS_BY_USER.key) {
      await fetchReportBreakDowns(conditions);
    }

    if (aggregationMethod === SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_CHANGE.key) {
      await fetchReportSaleInformationAccumulativeData(conditions);
    }
    if (aggregationMethod === SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_TRENDS_BY_USER.key) {
      await fetchReportMonthlyUsagesAccumulativeDataCorporation(conditions);
    }

    dispatch(stopLoading());
  };

  const fetchReports = async (conditions: Array<Types.ConditionsType>) => {
    await dispatch(
      getDataReportSaleInformation({
        conditions,
        page: 1,
        per_page: 0,
      })
    );
  };

  const fetchReportBreakDowns = async (conditions: Array<Types.ConditionsType>) => {
    await dispatch(
      getDataReportSaleInformationBreakdown({
        conditions,
        page: 1,
        per_page: 0,
        total_count_timeout_sec: 300,
        data_result_timeout_sec: 300,
      })
    );
  };

  const fetchReportBreakDownDetails = async () => {
    dispatch(startLoading());
    await dispatch(
      getDataReportSaleInformationBreakdownDetail({
        conditions: [
          {
            id: 'target_month',
            search_value: dateRanger,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  const fetchReportSaleInformationAccumulativeData = async (
    conditions: Array<Types.ConditionsType>
  ) => {
    await dispatch(
      getDataReportSaleInformationAccumulativeData({
        conditions,
        page: 1,
        per_page: 0,
      })
    );
  };

  const fetchReportMonthlyUsagesAccumulativeDataCorporation = async (
    conditions: Array<Types.ConditionsType>
  ) => {
    await dispatch(
      getDataReportMonthlyUsagesAccumulativeDataCorporation({
        conditions,
        page: 1,
        per_page: 0,
      })
    );
  };

  const fetchReportMonthlyUsagesAccumulativeDataIndividual = async () => {
    dispatch(startLoading());
    await dispatch(
      getDataReportMonthlyUsagesAccumulativeDataIndividual({
        conditions: [
          {
            id: 'target_month',
            search_value: dateRanger,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  const getConditions = (values: AnyObject) => {
    const conditions: Array<Types.ConditionsType> = [];
    Object.keys(values).forEach((key) => {
      if (values[key as keyof typeof values] && !['user_type', 'sale_type'].includes(key)) {
        const value = values[key as keyof typeof values];
        conditions.push({
          id: key,
          search_value:
            key === 'target_month'
              ? getMonthListFromDateRanger(value?.[0], value?.[1])
              : [String(value)],
          exact_match: true,
        });
      }
    });
    return conditions;
  };

  const onChange = (e: CheckboxChangeEvent) => {
    if (!visibleExpansionOfSale) {
      setVisibleCountUsage(false);
    }
    setVisibleExpansionOfSale(e.target.checked);
  };

  // useEffect(() => {
  //   Promise.all([
  //     dispatch(
  //       getSelectUser({
  //         page: 1,
  //         per_page: 0,
  //       })
  //     ),
  //   ]);
  // }, [dispatch]);

  const handleExport = async (value: string) => {
    if (value === 'pdf') {
      await handleExportPdf();
    } else {
      onExportCsv();
    }
    setOpenConfirmExport(false);
  };

  const handleExportPdf = async () => {
    if (
      !valueSelect ||
      [
        SALE_INFORMATION_REPORT_TYPE.MONTHLY_TREND.key,
        SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_CHANGE.key,
      ].includes(valueSelect)
    ) {
      exportPDFFitOnePage(ref, `売上情報.pdf`, 'l', true);
    } else {
      const blob = await pdf(
        <PDFMonthlyByUserDevelopment
          data={reportSaleInformationBreakdownList}
          reportSaleInformationBreakdownDetailList={reportSaleInformationBreakdownDetailList}
          reportType={valueSelect}
          filters={formik.values}
          dateRanger={dateRanger}
          visibleExpansionOfSale={visibleExpansionOfSale}
          visibleCountUsage={visibleCountUsage}
          listSelectUsers={userOptions}
        />
      ).toBlob();
      saveAs(blob, '売上情報.pdf');
    }
  };

  const onExportCsv = () => {
    if (
      valueSelect &&
      [
        SALE_INFORMATION_REPORT_TYPE.MONTHLY_TREND.key,
        SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_CHANGE.key,
      ].includes(valueSelect)
    ) {
      exportCsvBySaleType();
    }
    if (
      valueSelect &&
      [
        SALE_INFORMATION_REPORT_TYPE.MONTHLY_TRENDS_BY_USER.key,
        SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_TRENDS_BY_USER.key,
      ].includes(valueSelect)
    ) {
      exportCsvByUsers();
    }
  };

  const exportCsvBySaleType = () => {
    const dates =
      dateRanger && dateRanger.length
        ? dateRanger
        : uniq(reportSaleInformationList.map((obj) => obj.target_month));
    const headers = [
      { label: 'ユーザー種類', key: 'user_type' },
      { label: '売上種類', key: 'sales_type' },
      ...dates.map((date) => ({
        label: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        key: date,
      })),
    ];
    let listCsv = [];
    let result = groupSaleReportData(reportSaleInformationList, dates, true);
    result[USER_TYPE.ALL] = {
      ...result[USER_TYPE.ALL],
      [USER_TYPE.CORPORATE]: result[USER_TYPE.CORPORATE][SALE_TYPE.ALL],
      [USER_TYPE.PERSONAL]: result[USER_TYPE.PERSONAL][SALE_TYPE.ALL],
    };

    if (
      valueSelect &&
      [
        SALE_INFORMATION_REPORT_TYPE.MONTHLY_TREND.key,
        SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_CHANGE.key,
      ].includes(valueSelect) &&
      !visibleExpansionOfSale
    ) {
      listCsv = Object.values(USER_TYPE).map((userType) => {
        return {
          user_type: userType,
          sales_type: SALE_TYPE.ALL,
          ...result[userType][SALE_TYPE.ALL],
        };
      });
    }

    if (
      valueSelect &&
      [
        SALE_INFORMATION_REPORT_TYPE.MONTHLY_TREND.key,
        SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_CHANGE.key,
      ].includes(valueSelect) &&
      visibleExpansionOfSale &&
      !visibleCountUsage
    ) {
      Object.keys(result).forEach((userType) => {
        const userTypeData = result[userType];
        Object.keys(userTypeData).forEach((salesType) => {
          listCsv.push({
            user_type: userType,
            sales_type: salesType,
            ...(userType === USER_TYPE.ALL || salesType === SALE_TYPE.ALL
              ? userTypeData[salesType] || {}
              : userTypeData[salesType].data),
          });
        });
      });
    }

    if (
      valueSelect &&
      [
        SALE_INFORMATION_REPORT_TYPE.MONTHLY_TREND.key,
        SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_CHANGE.key,
      ].includes(valueSelect) &&
      visibleExpansionOfSale &&
      visibleCountUsage
    ) {
      Object.keys(result).forEach((userType) => {
        const userTypeData = result[userType];
        Object.keys(userTypeData).forEach((salesType) => {
          listCsv.push({
            user_type: userType,
            sales_type: salesType,
            ...(userType === USER_TYPE.ALL || salesType === SALE_TYPE.ALL
              ? userTypeData[salesType] || {}
              : userTypeData[salesType].data),
          });
          if (userType !== USER_TYPE.ALL && salesType !== SALE_TYPE.ALL) {
            listCsv.push({
              user_type: userType,
              sales_type:
                salesType === SALE_TYPE.USER_FEE
                  ? 'ユーザー数（人）'
                  : salesType === SALE_TYPE.SKILL_CHECK_USAGE_FEE
                  ? 'スキルチェック利用数（回）'
                  : 'ストレージ利用量（GB）',
              ...userTypeData[salesType].child,
            });
          }
        });
      });
    }

    exportCsv(listCsv, headers, '売上情報.csv');
  };

  const exportCsvByUsers = () => {
    const dates =
      dateRanger && dateRanger.length
        ? dateRanger
        : uniq(reportSaleInformationList.map((obj) => obj.target_month));
    const defaultHeaders = [
      { label: 'ユーザー種類', key: 'user_type' },
      { label: 'ユーザー名', key: 'user_name' },
      { label: 'ユーザーID', key: 'user_id' },
    ];
    const dateHeaders = [
      ...dates.map((date) => ({
        label: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        key: date,
      })),
    ];
    let listCsv = [];
    let result = groupSaleReportBreakdownData(
      reportSaleInformationBreakdownList,
      reportSaleInformationBreakdownDetailList,
      dates,
      false
    );
    if (!visibleExpansionOfSale) {
      const headers = [...defaultHeaders, ...dateHeaders];
      listCsv = [
        {
          user_type: USER_TYPE.ALL,
          user_name: '-',
          user_id: '-',
          ...result[USER_TYPE.ALL][SALE_TYPE.ALL],
        },
        {
          user_type: USER_TYPE.CORPORATE,
          user_name: '-',
          user_id: '-',
          ...result[USER_TYPE.CORPORATE].total[SALE_TYPE.ALL].data,
        },
        ...Object.keys(result[USER_TYPE.CORPORATE].items).map((companyId) => {
          const companyData = result[USER_TYPE.CORPORATE].items[companyId];
          return {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            ...companyData[SALE_TYPE.ALL].data,
          };
        }),
        {
          user_type: USER_TYPE.PERSONAL,
          user_name: '-',
          user_id: '-',
          ...result[USER_TYPE.PERSONAL].total[SALE_TYPE.ALL].data,
        },
        ...Object.keys(result[USER_TYPE.PERSONAL].items).map((companyId) => {
          const companyData = result[USER_TYPE.PERSONAL].items[companyId];
          return {
            user_type: USER_TYPE.PERSONAL,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            ...companyData[SALE_TYPE.ALL].data,
          };
        }),
      ];
      exportCsv(listCsv, headers, '売上情報.csv');
    }

    if (visibleExpansionOfSale && !visibleCountUsage) {
      const headers = [...defaultHeaders, { label: '売上種類', key: 'sale_type' }, ...dateHeaders];
      let companyDataCsv: AnyObject[] = [];
      for (const companyId in result[USER_TYPE.CORPORATE].items) {
        const companyData = result[USER_TYPE.CORPORATE].items[companyId];
        companyDataCsv = [
          ...companyDataCsv,
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: '-',
            ...companyData[SALE_TYPE.ALL].data,
          },
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.USER_FEE,
            ...companyData[SALE_TYPE.USER_FEE].data,
          },
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
            ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
          },
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.STORAGE_USAGE_FEE,
            ...companyData[SALE_TYPE.STORAGE_USAGE_FEE].data,
          },
        ];
      }
      let userDataCsv: AnyObject[] = [];
      for (const companyId in result[USER_TYPE.PERSONAL].items) {
        const companyData = result[USER_TYPE.PERSONAL].items[companyId];
        userDataCsv = [
          ...userDataCsv,
          {
            user_type: USER_TYPE.PERSONAL,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: '-',
            ...companyData[SALE_TYPE.ALL].data,
          },
          {
            user_type: USER_TYPE.PERSONAL,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.USER_FEE,
            ...companyData[SALE_TYPE.USER_FEE].data,
          },
          {
            user_type: USER_TYPE.PERSONAL,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
            ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
          },
        ];
      }
      listCsv = [
        {
          user_type: USER_TYPE.ALL,
          user_name: '-',
          user_id: '-',
          sale_type: '-',
          ...result[USER_TYPE.ALL][SALE_TYPE.ALL],
        },
        {
          user_type: USER_TYPE.CORPORATE,
          user_name: '-',
          user_id: '-',
          sale_type: '-',
          ...result[USER_TYPE.CORPORATE].total[SALE_TYPE.ALL].data,
        },
        {
          user_type: USER_TYPE.CORPORATE,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.USER_FEE,
          ...result[USER_TYPE.CORPORATE].total[SALE_TYPE.USER_FEE].data,
        },
        {
          user_type: USER_TYPE.CORPORATE,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          ...result[USER_TYPE.CORPORATE].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
        },
        {
          user_type: USER_TYPE.CORPORATE,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.STORAGE_USAGE_FEE,
          ...result[USER_TYPE.CORPORATE].total[SALE_TYPE.STORAGE_USAGE_FEE].data,
        },
        ...companyDataCsv,
        {
          user_type: USER_TYPE.PERSONAL,
          user_name: '-',
          user_id: '-',
          sale_type: '-',
          ...result[USER_TYPE.PERSONAL].total[SALE_TYPE.ALL].data,
        },
        {
          user_type: USER_TYPE.PERSONAL,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.USER_FEE,
          ...result[USER_TYPE.PERSONAL].total[SALE_TYPE.USER_FEE].data,
        },
        {
          user_type: USER_TYPE.PERSONAL,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          ...result[USER_TYPE.PERSONAL].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
        },
        ...userDataCsv,
      ];
      exportCsv(listCsv, headers, '売上情報.csv');
    }

    if (visibleExpansionOfSale && visibleCountUsage) {
      const headers = [
        ...defaultHeaders,
        { label: '売上種類 / 利用件数', key: 'sale_type' },
        ...dateHeaders,
      ];
      let companyDataCsv: AnyObject[] = [];
      for (const companyId in result[USER_TYPE.CORPORATE].items) {
        const companyData = result[USER_TYPE.CORPORATE].items[companyId];
        companyDataCsv = [
          ...companyDataCsv,
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: '-',
            ...companyData[SALE_TYPE.ALL].data,
          },
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.USER_FEE,
            ...companyData[SALE_TYPE.USER_FEE].data,
          },
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: 'ユーザー数（人）',
            ...companyData[SALE_TYPE.USER_FEE].child,
          },
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
            ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
          },
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: 'スキルチェック利用数（回）',
            ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
          },
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.STORAGE_USAGE_FEE,
            ...companyData[SALE_TYPE.STORAGE_USAGE_FEE].data,
          },
          {
            user_type: USER_TYPE.CORPORATE,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: 'ストレージ利用量（GB）',
            ...companyData[SALE_TYPE.STORAGE_USAGE_FEE].data,
          },
        ];
      }
      let userDataCsv: AnyObject[] = [];
      for (const companyId in result[USER_TYPE.PERSONAL].items) {
        const companyData = result[USER_TYPE.PERSONAL].items[companyId];
        userDataCsv = [
          ...userDataCsv,
          {
            user_type: USER_TYPE.PERSONAL,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: '-',
            ...companyData[SALE_TYPE.ALL].data,
          },
          {
            user_type: USER_TYPE.PERSONAL,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.USER_FEE,
            ...companyData[SALE_TYPE.USER_FEE].data,
          },
          {
            user_type: USER_TYPE.PERSONAL,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: 'ユーザー数（人）',
            ...companyData[SALE_TYPE.USER_FEE].child,
          },
          {
            user_type: USER_TYPE.PERSONAL,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
            ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
          },
          {
            user_type: USER_TYPE.PERSONAL,
            user_name: companyData.name || '-',
            user_id: companyData.code || '-',
            sale_type: 'スキルチェック利用数（回）',
            ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
          },
        ];
      }
      listCsv = [
        {
          user_type: USER_TYPE.ALL,
          user_name: '-',
          user_id: '-',
          sale_type: '-',
          ...result[USER_TYPE.ALL][SALE_TYPE.ALL],
        },
        {
          user_type: USER_TYPE.CORPORATE,
          user_name: '-',
          user_id: '-',
          sale_type: '-',
          ...result[USER_TYPE.CORPORATE].total[SALE_TYPE.ALL].data,
        },
        {
          user_type: USER_TYPE.CORPORATE,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.USER_FEE,
          ...result[USER_TYPE.CORPORATE].total[SALE_TYPE.USER_FEE].data,
        },
        {
          user_type: USER_TYPE.CORPORATE,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          ...result[USER_TYPE.CORPORATE].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
        },
        {
          user_type: USER_TYPE.CORPORATE,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.STORAGE_USAGE_FEE,
          ...result[USER_TYPE.CORPORATE].total[SALE_TYPE.STORAGE_USAGE_FEE].data,
        },
        ...companyDataCsv,
        {
          user_type: USER_TYPE.PERSONAL,
          user_name: '-',
          user_id: '-',
          sale_type: '-',
          ...result[USER_TYPE.PERSONAL].total[SALE_TYPE.ALL].data,
        },
        {
          user_type: USER_TYPE.PERSONAL,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.USER_FEE,
          ...result[USER_TYPE.PERSONAL].total[SALE_TYPE.USER_FEE].data,
        },
        {
          user_type: USER_TYPE.PERSONAL,
          user_name: '-',
          user_id: '-',
          sale_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          ...result[USER_TYPE.PERSONAL].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
        },
        ...userDataCsv,
      ];
      exportCsv(listCsv, headers, '売上情報.csv');
    }
  };

  const requiredCondition = valueSelect === undefined || !selectedDates || !selectedDates.length;

  const onChangeReportType = (value: number) => {
    formik.setFieldValue('aggregation_method', value);
    setSegmented(0);
  };
  return (
    <>
      {valueSelect &&
        [
          SALE_INFORMATION_REPORT_TYPE.MONTHLY_TREND.key,
          SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_CHANGE.key,
        ].includes(valueSelect) && (
          <div
            ref={ref}
            style={{
              position: 'absolute',
              width: 1512,
              right: 9999,
            }}
          >
            <PDFSaleInformation
              formik={formik}
              visibleExpansionOfSale={visibleExpansionOfSale}
              visibleCountUsage={visibleCountUsage}
              segmented={segmented}
              valueSelect={valueSelect}
              selectedDates={selectedDates}
            />
          </div>
        )}
      <SaleInformationStyled>
        <div className="container">
          <div className="wrap-filter">
            <span className="label">集計条件</span>
            <div className="aggregation-conditions">
              <FormikProvider value={formik}>
                <Form layout="vertical">
                  <div className="form-search">
                    <div className="input-group">
                      <Form.Item
                        name="aggregation_method"
                        className="item"
                        label={<Label required>集計方法選択</Label>}
                      >
                        <Select
                          data-testid="aggregation-method-select"
                          value={formik.values.aggregation_method}
                          onChange={onChangeReportType}
                          showSearch
                          allowClear
                          placeholder="指定なし"
                          filterOption={(input, option) =>
                            JSON.stringify(option?.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        >
                          {Object.values(SALE_INFORMATION_REPORT_TYPE).map((item, idx) => (
                            <Option
                              key={item.key}
                              data-testid={`aggregation-method-select-opt-${idx}`}
                              value={item.key}
                            >
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="target_month"
                        className="item date"
                        label={<Label required>集計期間</Label>}
                      >
                        <RangePicker
                          value={selectedDates}
                          allowClear
                          name="date-item"
                          format="YYYY/MM"
                          picker="month"
                          placeholder={['集計開始日', '集計終了日']}
                          onChange={(dates) => {
                            setSelectedDates(dates);
                            if (dates) {
                              const start = dates[0] ? dates[0].format('YYYYMM') : '';
                              const end = dates[1] ? dates[1].format('YYYYMM') : '';
                              formik.setFieldValue('target_month', [start, end]);
                            } else {
                              formik.setFieldValue('target_month', [null, null]);
                            }
                          }}
                          getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                        />
                      </Form.Item>
                      <Form.Item
                        name="user_type"
                        className="item"
                        label={<span className="text-label">ユーザー種類選択</span>}
                      >
                        <SelectField
                          data-testid="user-type-select"
                          name="user_type"
                          showSearch
                          allowClear
                          placeholder="指定なし"
                          filterOption={(input, option) =>
                            JSON.stringify(option?.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option data-testid="user-type-select-opt-0" value={USER_TYPE.ALL}>
                            ALL
                          </Option>
                          <Option value={USER_TYPE.CORPORATE}>法人ユーザー</Option>
                          <Option value={USER_TYPE.PERSONAL}>個人ユーザー</Option>
                        </SelectField>
                      </Form.Item>
                      <Form.Item
                        name="sale_type"
                        className="item"
                        label={<span className="text-label">売上種類選択</span>}
                      >
                        <SelectField
                          data-testid="sales-type-select"
                          name="sale_type"
                          showSearch
                          allowClear
                          placeholder="指定なし"
                          filterOption={(input, option) =>
                            JSON.stringify(option?.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option data-testid="sales-type-select-opt-0" value={SALE_TYPE.ALL}>
                            ALL
                          </Option>
                          <Option value={SALE_TYPE.USER_FEE}>ユーザー利用料</Option>
                          <Option value={SALE_TYPE.SKILL_CHECK_USAGE_FEE}>
                            スキルチェック利用料
                          </Option>
                          <Option value={SALE_TYPE.STORAGE_USAGE_FEE}>ストレージ利用料</Option>
                        </SelectField>
                      </Form.Item>
                      {formik.values.aggregation_method === 1 && (
                        <Form.Item
                          name="name"
                          className="item"
                          label={<span className="text-label">ユーザー検索</span>}
                        >
                          <SelectField
                            name="name"
                            showSearch
                            allowClear
                            placeholder="指定なし"
                            filterOption={(input, option) =>
                              JSON.stringify(option?.children)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {userOptions &&
                              userOptions.map((value) => (
                                <Option value={value.value} key={value.value}>
                                  {value.label}
                                </Option>
                              ))}
                          </SelectField>
                        </Form.Item>
                      )}
                    </div>

                    <div className="group-btn">
                      <span
                        data-testid="report-filter-reset-btn"
                        className="label-reset"
                        onClick={() => {
                          formik.setFieldValue('target_month', [null, null]);
                          formik.resetForm();
                          setValueSelect(undefined);
                          setSelectedDates([]);
                        }}
                      >
                        リセット
                      </span>
                      <SubmitButton className="btn-search" loading={false}>
                        表示
                      </SubmitButton>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
          <div className="sub-container">
            <div className="wrap-segmented">
              <div className="left-side">
                <span className="label">レポートタイプ：</span>
                <div className="segmented">
                  <div
                    className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                    onClick={() => {
                      setSegmented(0);
                    }}
                  >
                    <TableOutlined className="icon" />
                    <span>表</span>
                  </div>
                  <div
                    className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}${
                      valueSelect &&
                      [
                        SALE_INFORMATION_REPORT_TYPE.MONTHLY_TRENDS_BY_USER.key,
                        SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_TRENDS_BY_USER.key,
                      ].includes(valueSelect)
                        ? ' btn-disable'
                        : ''
                    }`}
                    onClick={() => {
                      if (
                        valueSelect &&
                        [
                          SALE_INFORMATION_REPORT_TYPE.MONTHLY_TREND.key,
                          SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_CHANGE.key,
                        ].includes(valueSelect)
                      ) {
                        setSegmented(1);
                      }
                    }}
                  >
                    <BarChartOutlined className="icon" />
                    <span>グラフ</span>
                  </div>
                </div>
                <div className="item-checkbox">
                  <Checkbox checked={visibleExpansionOfSale} onChange={onChange}>
                    売上種類展開
                  </Checkbox>
                </div>
                <div className="item-checkbox">
                  <Checkbox
                    disabled={!visibleExpansionOfSale}
                    checked={visibleCountUsage && visibleExpansionOfSale}
                    onClick={() => setVisibleCountUsage(!visibleCountUsage)}
                  >
                    利用件数表示
                  </Checkbox>
                </div>
              </div>
            </div>
            {requiredCondition && (
              <div className="errorPanel">
                {!valueSelect ? '集計条件を選択してください' : '集計期間を選択してください'}
              </div>
            )}
            {!requiredCondition && segmented === 0 && (
              <Table
                data={reportSaleInformationList}
                visibleExpansionOfSale={visibleExpansionOfSale}
                visibleCountUsage={visibleCountUsage}
                valueSelect={valueSelect}
              />
            )}
            {!requiredCondition && segmented !== 0 && (
              <Chart
                visibleExpansionOfSale={visibleExpansionOfSale}
                visibleCountUsage={visibleCountUsage}
                data={reportSaleInformationList}
              />
            )}
          </div>
        </div>
      </SaleInformationStyled>
      <ModalConfirmExport
        visible={openConfirmExport}
        setVisible={setOpenConfirmExport}
        onSubmit={handleExport}
        exportPdfDescription="エクスポートは開始月から12か月間のみ実行されます。"
      />
    </>
  );
};

export default SaleInformation;
