import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import * as Types from 'types';
import { AnyObject } from 'types';
import styles from './styles';
import { ChartInactiveIconPdf, TableIconPdf } from 'assets/svgPDF';
import React, { useMemo } from 'react';
import _, { uniq } from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import Tabs from 'components/ReactPDF/Tabs';
import SelectBox from 'components/ReactPDF/SelectBox';
import DateRangerpicker from 'components/ReactPDF/DateRangerpicker';
import CheckBox from 'components/ReactPDF/CheckBox';
import { groupSaleReportBreakdownData } from 'libs/utils/report';
import { SALE_INFORMATION_REPORT_TYPE, SALE_TYPE, USER_TYPE } from 'constant/report';
import dayjs from 'dayjs';
import { formatComma } from 'libs/utils/format';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

const reportTypeOptions = Object.values(SALE_INFORMATION_REPORT_TYPE).map((item) => ({
  value: item.key,
  label: item.name,
}));

const userTypeOptions = [
  {
    value: '0',
    label: 'ALL',
  },
  {
    value: '1',
    label: '法人ユーザー',
  },
  {
    value: '2',
    label: '個人ユーザー',
  },
];

const salesTypeOptions = [
  {
    value: '0',
    label: 'ALL',
  },
  {
    value: '1',
    label: 'ユーザー利用料',
  },
  {
    value: '2',
    label: 'スキルチェック利用料',
  },
  {
    value: '3',
    label: 'ストレージ利用料',
  },
];

interface PDFMonthlyByUserDevelopmentProps {
  data: Types.SaleReportInformation.SaleReportInformationBreakdown[];
  reportSaleInformationBreakdownDetailList: Types.SaleReportInformation.SaleReportInformationBreakdownDetail[];
  reportType?: string;
  rangerDate?: string[];
  filters?: AnyObject;
  total?: number;
  visibleCountUsage?: boolean;
  visibleExpansionOfSale?: boolean;
  dateRanger?: string[];
  listSelectUsers: Types.IOption[];
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 20;

const TABS_ARRAY = [
  { key: '売上情報', label: '売上情報' },
  { key: 'ユーザー情報', label: 'ユーザー情報' },
  { key: 'OFFICIALカリキュラム情報', label: 'OFFICIALカリキュラム情報' },
  { key: 'スキルチェック実施情報', label: 'スキルチェック実施情報' },
];

const MonthlyByUserDevelopmentTable = ({
  dataSource,
  dates,
  visibleCountUsage,
  visibleExpansionOfSale,
}: {
  dataSource: AnyObject[];
  dates: string[];
  visibleCountUsage?: boolean;
  visibleExpansionOfSale?: boolean;
}) => {
  const columns = useMemo(() => {
    const dateColumns = dates.map((item, index) => ({
      title: dayjs(item, 'YYYYMM').format('YYYY/MM'),
      key: item,
      width: Math.floor(630 / dates.length),
    }));
    return [
      {
        width: 230,
        title: !visibleExpansionOfSale
          ? 'ユーザー種類　/　ユーザー名・ID'
          : visibleExpansionOfSale && !visibleCountUsage
          ? 'ユーザー種類　/　ユーザー名・ID　/　売上種類'
          : 'ユーザー種類　/　ユーザー名・ID　/　売上種類　/　件数',
        key: 'name',
      },
      ...dateColumns,
    ];
  }, [dates, visibleExpansionOfSale, visibleCountUsage]);

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow]}>
        {columns.map((column, index) => (
          <View
            key={index}
            style={[
              styles.wrapCell,
              { width: column.width, textAlign: index === 0 ? 'left' : 'center' },
            ]}
          >
            <Text style={[styles.tableCell, styles.headerCell]}>{column.title}</Text>
          </View>
        ))}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
            row.styleRow,
          ]}
        >
          {columns.map((column, index) =>
            index === 0 ? (
              <View
                key={index}
                style={[styles.wrapCell, styles.cellTitle, { width: columns[index].width }]}
              >
                <View style={[styles.level, row.styleLevel]} />
                <View
                  style={[
                    styles.tableCell,
                    styles.cellTitleValue,
                    { borderLeft: 0 },
                    row.styleTitle,
                  ]}
                >
                  <View style={[styles.itemName]}>
                    <Text style={styles.name}>{row.name || '-'}</Text>
                    {row.code && <Text style={styles.code}>{row.code || '-'}</Text>}
                  </View>
                </View>
              </View>
            ) : (
              <View style={[styles.wrapCell, { width: columns[index].width }]}>
                <Text style={[styles.tableCell, styles.textRight, row.styleValue]}>
                  {row[column.key] ? formatComma(row[column.key]) : '-'}
                </Text>
              </View>
            )
          )}
        </View>
      ))}
    </View>
  );
};

const PDFMonthlyByUserDevelopment = (props: PDFMonthlyByUserDevelopmentProps) => {
  const {
    data,
    filters = {},
    total,
    reportType,
    visibleCountUsage,
    visibleExpansionOfSale,
    reportSaleInformationBreakdownDetailList,
    dateRanger,
    listSelectUsers,
  } = props;

  const dates = useMemo(
    () =>
      (dateRanger && dateRanger.length
        ? dateRanger
        : uniq(data.map((obj) => obj.target_month))
      ).slice(0, EXPORT_TARGET_MONTH_COUNT),
    [data, dateRanger]
  );

  const selectUserOptions = listSelectUsers
    ? listSelectUsers.map((obj) => ({
        label: obj.label || '',
        value: obj.value || '',
      }))
    : [];

  const filterUserTypes = useMemo(() => {
    const userType = filters.user_type;
    if (!userType || userType === USER_TYPE.ALL) {
      return Object.values(USER_TYPE);
    }
    return Object.values(USER_TYPE).filter((user) => user === userType);
  }, [filters]);

  const allData = useMemo(() => {
    const groupData = groupSaleReportBreakdownData(
      data,
      reportSaleInformationBreakdownDetailList,
      dates
    );
    const filterSaleType = filters.sale_type || SALE_TYPE.ALL;
    const filterUser = filters.name;
    const showUserFee = [SALE_TYPE.ALL, SALE_TYPE.USER_FEE].includes(filterSaleType);
    const showSkillCheckFee = [SALE_TYPE.ALL, SALE_TYPE.SKILL_CHECK_USAGE_FEE].includes(
      filterSaleType
    );
    const showStorageFee = [SALE_TYPE.ALL, SALE_TYPE.STORAGE_USAGE_FEE].includes(filterSaleType);
    const showAllUser = filterUserTypes.includes(USER_TYPE.ALL);
    const showCorporateUser = filterUserTypes.includes(USER_TYPE.CORPORATE);
    const showPersonalUser = filterUserTypes.includes(USER_TYPE.PERSONAL);
    if (visibleExpansionOfSale && visibleCountUsage) {
      let res: AnyObject[] = [];
      if (showAllUser) {
        res.push({
          styleRow: styles.rowTotalAll,
          name: USER_TYPE.ALL,
          ...groupData[USER_TYPE.ALL][SALE_TYPE.ALL],
        });
      }
      if (showCorporateUser) {
        res.push({
          name: USER_TYPE.CORPORATE,
          styleRow: styles.rowTotalExpand,
          ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.ALL].data,
        });
        if (showUserFee) {
          res = [
            ...res,
            {
              name: SALE_TYPE.USER_FEE,
              styleLevel: { ...styles.secondChildLevel },
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.USER_FEE].data,
            },
            {
              name: 'ユーザー数',
              styleLevel: { ...styles.secondChildLevel },
              styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.USER_FEE].child,
            },
          ];
        }
        if (showSkillCheckFee) {
          res = [
            ...res,
            {
              name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
              styleLevel: { ...styles.secondChildLevel },
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
            },
            {
              name: 'スキルチェック利用数',
              styleLevel: { ...styles.secondChildLevel },
              styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
            },
          ];
        }
        if (showStorageFee) {
          res = [
            ...res,
            {
              name: SALE_TYPE.STORAGE_USAGE_FEE,
              styleLevel: { ...styles.secondChildLevel },
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.STORAGE_USAGE_FEE].data,
            },
            {
              name: 'ストレージ利用量',
              styleLevel: { ...styles.secondChildLevel, ...styles.borderBottomSolid },
              styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.STORAGE_USAGE_FEE].child,
            },
          ];
        }
      }
      Object.keys(groupData[USER_TYPE.CORPORATE].items).forEach((companyId) => {
        const companyData = groupData[USER_TYPE.CORPORATE].items[companyId];
        if (filterUser && companyData.name !== filterUser) {
          return;
        }
        res = [
          ...res,
          {
            styleLevel: styles.companyLevel,
            styleTitle: { ...styles.borderBottomDash, ...styles.noBorderLeft },
            styleValue: { ...styles.borderBottomDash },
            code: companyData.code,
            name: companyData.name,
            ...companyData[SALE_TYPE.ALL].data,
          },
        ];
        if (showUserFee) {
          res = [
            ...res,
            {
              styleLevel: styles.companyChildLevel,
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              name: SALE_TYPE.USER_FEE,
              ...companyData[SALE_TYPE.USER_FEE].data,
            },
            {
              styleLevel: styles.companyChildLevel,
              styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              name: 'ユーザー数',
              ...companyData[SALE_TYPE.USER_FEE].child,
            },
          ];
        }
        if (showSkillCheckFee) {
          res = [
            ...res,
            {
              styleLevel: styles.companyChildLevel,
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
              ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
            },
            {
              styleLevel: styles.companyChildLevel,
              styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              name: 'スキルチェック利用数',
              ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
            },
          ];
        }
        if (showStorageFee) {
          res = [
            ...res,
            {
              styleLevel: styles.companyChildLevel,
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              name: SALE_TYPE.STORAGE_USAGE_FEE,
              ...companyData[SALE_TYPE.STORAGE_USAGE_FEE].data,
            },
            {
              styleLevel: { ...styles.companyChildLevel, ...styles.borderBottomSolid },
              styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              name: 'ストレージ利用量',
              ...companyData[SALE_TYPE.STORAGE_USAGE_FEE].child,
            },
          ];
        }
      });
      if (showPersonalUser) {
        res = [
          ...res,
          {
            name: USER_TYPE.PERSONAL,
            styleRow: styles.rowTotalExpand,
            ...groupData[USER_TYPE.PERSONAL].total[SALE_TYPE.ALL].data,
          },
        ];
        if (showUserFee) {
          res = [
            ...res,
            {
              name: SALE_TYPE.USER_FEE,
              styleLevel: { ...styles.secondChildLevel },
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              ...groupData[USER_TYPE.PERSONAL].total[SALE_TYPE.USER_FEE].data,
            },
            {
              name: 'ユーザー数',
              styleLevel: { ...styles.secondChildLevel },
              styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              ...groupData[USER_TYPE.PERSONAL].total[SALE_TYPE.USER_FEE].child,
            },
          ];
        }
        if (showSkillCheckFee) {
          res = [
            ...res,
            {
              name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
              styleLevel: { ...styles.secondChildLevel },
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              ...groupData[USER_TYPE.PERSONAL].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
            },
            {
              name: 'スキルチェック利用数',
              styleLevel: { ...styles.secondChildLevel, ...styles.borderBottomSolid },
              styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
              ...groupData[USER_TYPE.PERSONAL].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
            },
          ];
        }
        Object.keys(groupData[USER_TYPE.PERSONAL].items).forEach((companyId) => {
          const companyData = groupData[USER_TYPE.PERSONAL].items[companyId];
          if (filterUser && companyData.name !== filterUser) {
            return;
          }
          res = [
            ...res,
            {
              styleRow: styles.rowTotalExpand,
              code: companyData.code,
              name: companyData.name,
              ...companyData[SALE_TYPE.ALL].data,
            },
          ];
          if (showUserFee) {
            res = [
              ...res,
              {
                styleLevel: { ...styles.secondChildLevel },
                styleTitle: { ...styles.borderBottomDash },
                styleValue: { ...styles.borderBottomDash },
                name: SALE_TYPE.USER_FEE,
                ...companyData[SALE_TYPE.USER_FEE].data,
              },
              {
                styleLevel: { ...styles.secondChildLevel },
                styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
                styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
                name: 'ユーザー数',
                ...companyData[SALE_TYPE.USER_FEE].child,
              },
            ];
          }
          if (showSkillCheckFee) {
            res = [
              ...res,
              {
                styleLevel: { ...styles.secondChildLevel },
                styleTitle: { ...styles.borderBottomDash },
                styleValue: { ...styles.borderBottomDash },
                name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
                ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
              },
              {
                styleLevel: { ...styles.secondChildLevel, ...styles.borderBottomSolid },
                styleTitle: { ...styles.borderBottomSolid, ...styles.backgroundGray },
                styleValue: { ...styles.borderBottomSolid, ...styles.backgroundGray },
                name: 'スキルチェック利用数',
                ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
              },
            ];
          }
        });
      }

      return res;
    }
    if (visibleExpansionOfSale) {
      let res: AnyObject[] = [];
      if (showAllUser) {
        res.push({
          styleRow: styles.rowTotalAll,
          name: USER_TYPE.ALL,
          ...groupData[USER_TYPE.ALL][SALE_TYPE.ALL],
        });
      }
      if (showCorporateUser) {
        res.push({
          name: USER_TYPE.CORPORATE,
          styleRow: styles.rowTotalExpand,
          ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.ALL].data,
        });
        if (showUserFee) {
          res.push({
            name: SALE_TYPE.USER_FEE,
            styleRow: styles.backgroundGray,
            styleLevel: { ...styles.secondChildLevel },
            styleTitle: { ...styles.borderBottomDash },
            styleValue: { ...styles.borderBottomDash },
            ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.USER_FEE].data,
          });
        }
        if (showSkillCheckFee) {
          res.push({
            name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
            styleRow: styles.backgroundGray,
            styleLevel: styles.secondChildLevel,
            styleTitle: { ...styles.borderBottomDash },
            styleValue: { ...styles.borderBottomDash },
            ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
          });
        }
        if (showStorageFee) {
          res.push({
            name: SALE_TYPE.STORAGE_USAGE_FEE,
            styleRow: styles.backgroundGray,
            styleLevel: { ...styles.secondChildLevel, ...styles.borderBottomSolid },
            styleTitle: { ...styles.borderBottomSolid },
            styleValue: { ...styles.borderBottomSolid },
            ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.STORAGE_USAGE_FEE].data,
          });
        }
        Object.keys(groupData[USER_TYPE.CORPORATE].items).forEach((companyId) => {
          const companyData = groupData[USER_TYPE.CORPORATE].items[companyId];
          if (filterUser && companyData.name !== filterUser) {
            return;
          }
          res = [
            ...res,
            {
              styleLevel: styles.companyLevel,
              styleTitle: { ...styles.borderBottomDash, ...styles.noBorderLeft },
              styleValue: { ...styles.borderBottomDash },
              code: companyData.code,
              name: companyData.name,
              ...companyData[SALE_TYPE.ALL].data,
            },
          ];
          if (showUserFee) {
            res.push({
              name: SALE_TYPE.USER_FEE,
              styleLevel: styles.companyChildLevel,
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              ...companyData[SALE_TYPE.USER_FEE].data,
            });
          }
          if (showSkillCheckFee) {
            res.push({
              styleLevel: styles.companyChildLevel,
              name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
            });
          }
          if (showStorageFee) {
            res.push({
              styleLevel: { ...styles.companyChildLevel, ...styles.borderBottomSolid },
              name: SALE_TYPE.STORAGE_USAGE_FEE,
              styleTitle: { ...styles.borderBottomSolid },
              styleValue: { ...styles.borderBottomSolid },
              ...companyData[SALE_TYPE.STORAGE_USAGE_FEE].data,
            });
          }
        });
      }
      if (showPersonalUser) {
        res = [
          ...res,
          {
            name: USER_TYPE.PERSONAL,
            styleRow: styles.rowTotalExpand,
            ...groupData[USER_TYPE.PERSONAL].total[SALE_TYPE.ALL].data,
          },
        ];
        if (showUserFee) {
          res.push({
            name: SALE_TYPE.USER_FEE,
            styleLevel: styles.secondChildLevel,
            styleRow: styles.backgroundGray,
            styleTitle: { ...styles.borderBottomDash },
            styleValue: { ...styles.borderBottomDash },
            ...groupData[USER_TYPE.PERSONAL].total[SALE_TYPE.USER_FEE].data,
          });
        }
        if (showSkillCheckFee) {
          res.push({
            name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
            styleRow: styles.backgroundGray,
            styleLevel: { ...styles.secondChildLevel, ...styles.borderBottomSolid },
            styleTitle: { ...styles.borderBottomSolid },
            styleValue: { ...styles.borderBottomSolid },
            ...groupData[USER_TYPE.PERSONAL].total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
          });
        }
        Object.keys(groupData[USER_TYPE.PERSONAL].items).forEach((companyId) => {
          const companyData = groupData[USER_TYPE.PERSONAL].items[companyId];
          if (filterUser && companyData.name !== filterUser) {
            return;
          }
          res = [
            ...res,
            {
              styleLevel: styles.companyLevel,
              styleTitle: { ...styles.borderBottomDash, ...styles.noBorderLeft },
              styleValue: { ...styles.borderBottomDash },
              code: companyData.code,
              name: companyData.name,
              ...companyData[SALE_TYPE.ALL].data,
            },
          ];
          if (showUserFee) {
            res.push({
              styleLevel: styles.companyChildLevel,
              styleTitle: { ...styles.borderBottomDash },
              styleValue: { ...styles.borderBottomDash },
              name: SALE_TYPE.USER_FEE,
              ...companyData[SALE_TYPE.USER_FEE].data,
            });
          }
          if (showSkillCheckFee) {
            res.push({
              styleLevel: styles.companyChildLevel,
              styleTitle: { ...styles.borderBottomSolid },
              styleValue: { ...styles.borderBottomSolid },
              name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
              ...companyData[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
            });
          }
        });
      }

      return res;
    }
    if (!visibleExpansionOfSale) {
      let res: AnyObject[] = [];
      if (showAllUser) {
        res = [
          {
            styleRow: styles.rowTotalAll,
            name: USER_TYPE.ALL,
            ...groupData[USER_TYPE.ALL][SALE_TYPE.ALL],
          },
        ];
      }
      if (showCorporateUser) {
        res = [
          ...res,
          {
            name: USER_TYPE.CORPORATE,
            styleRow: styles.rowTotal,
            ...groupData[USER_TYPE.CORPORATE].total[SALE_TYPE.ALL].data,
          },
          ...Object.keys(groupData[USER_TYPE.CORPORATE].items)
            .map((companyId) => {
              const companyData = groupData[USER_TYPE.CORPORATE].items[companyId];
              if (filterUser && companyData.name !== filterUser) {
                return null;
              }
              return {
                code: companyData.code,
                name: companyData.name,
                styleLevel: styles.companyLevel,
                styleTitle: { ...styles.borderBottomDash, ...styles.noBorderLeft },
                styleValue: { ...styles.borderBottomDash },
                ...companyData[SALE_TYPE.ALL].data,
              };
            })
            .filter((obj) => !!obj),
        ];
      }
      if (showPersonalUser) {
        res = [
          ...res,
          {
            name: USER_TYPE.PERSONAL,
            styleRow: styles.rowTotal,

            ...groupData[USER_TYPE.PERSONAL].total[SALE_TYPE.ALL].data,
          },
          ...Object.keys(groupData[USER_TYPE.PERSONAL].items)
            .map((companyId) => {
              const companyData = groupData[USER_TYPE.PERSONAL].items[companyId];
              if (filterUser && companyData.name !== filterUser) {
                return null;
              }
              return {
                code: companyData.code,
                name: companyData.name,
                styleTitle: { ...styles.borderBottomDash, ...styles.noBorderLeft },
                styleValue: { ...styles.borderBottomDash },
                styleLevel: styles.companyLevel,
                ...companyData[SALE_TYPE.ALL].data,
              };
            })
            .filter((obj) => !!obj),
        ];
      }
      return res;
    }
    return [];
  }, [filters, data, dates, visibleExpansionOfSale, visibleCountUsage, filterUserTypes]);

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>レポート</Text>
          </View>
          <Tabs tabs={TABS_ARRAY} activeKey="売上情報" />
          <Text style={styles.conditionText}>集計条件</Text>
          <View style={styles.searchPanel}>
            <SelectBox
              label="集計方法選択"
              placeholder="指定なし"
              options={reportTypeOptions}
              value={reportType}
              selectBoxStyle={{ width: 150 }}
            />
            <DateRangerpicker
              label="集計期間"
              placeholder="指定なし"
              value={filters.target_month}
            />
            <SelectBox
              label="ユーザー種類選択"
              placeholder="指定なし"
              options={userTypeOptions}
              value={filters.user_type || '0'}
              selectBoxStyle={{ width: 150 }}
            />
            <SelectBox
              label="売上種類選択"
              placeholder="指定なし"
              options={salesTypeOptions}
              value={filters.sale_type || '0'}
              selectBoxStyle={{ width: 150 }}
            />
            <SelectBox
              label="ユーザー検索"
              placeholder="指定なし"
              options={selectUserOptions}
              value={filters.name || 'ALL'}
              selectBoxStyle={{ width: 150 }}
            />
          </View>
          <View style={styles.reportTypeGroups}>
            <View style={styles.reportTypeGroup}>
              <View style={styles.reportTypeGroup}>
                <Text style={styles.reportTypeLabel}>レポートタイプ：</Text>
                <View style={styles.reportTable}>
                  <TableIconPdf />
                  <Text style={styles.reportTableText}>表</Text>
                </View>
                <View style={styles.reportChart}>
                  <ChartInactiveIconPdf />
                  <Text style={styles.reportChartText}>グラフ</Text>
                </View>
              </View>
              <View style={styles.checkboxGroups}>
                <View style={styles.checkboxGroup}>
                  <CheckBox checked={visibleExpansionOfSale} label="売上種類展開" />
                </View>
                <View style={styles.checkboxGroup}>
                  <CheckBox checked={visibleCountUsage} label="売上種類展開" />
                </View>
              </View>
            </View>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1- {allData.length} </Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{total || allData.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
          </View>
          <View style={styles.body}>
            <MonthlyByUserDevelopmentTable
              dataSource={allData.slice(0, FIRST_PAGE_ROW_COUNT)}
              visibleCountUsage={visibleCountUsage}
              visibleExpansionOfSale={visibleExpansionOfSale}
              dates={dates}
            />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {allData.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(allData.slice(FIRST_PAGE_ROW_COUNT, allData.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <MonthlyByUserDevelopmentTable
                  dataSource={page}
                  visibleCountUsage={visibleCountUsage}
                  visibleExpansionOfSale={visibleExpansionOfSale}
                  dates={dates}
                />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFMonthlyByUserDevelopment;
