import * as Types from 'types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from 'services';
export const getDataGmoShop = createAsyncThunk<
  Types.GetItemResponseObject<Types.GmoShopDetails.ResponseType>
>('gmo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.applicationVariable('GMO_SHOP_ID');
    return { data: data };
  } catch (error) {
    return rejectWithValue(error);
  }
});
