import { createAsyncThunk } from '@reduxjs/toolkit';
import { chain, filter, find, groupBy, join, map, maxBy, sortBy, uniq, uniqBy } from 'lodash';

import { memoizedConvertFlatDataFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { curriculumService, services } from 'services';
import * as Types from 'types';
import {
  QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM,
  OFFICIAL_CURRICULUM_USER_AGGREGATION,
  USER_ASSIGN_REQUIRED_CURRICULUM,
  PUBLISHED_RELEASE_NOTE_SECTION,
  CURRICULUM_PUBLISHED_HISTORY,
  LEVEL1S_OFFICIAL_CURRICULUM,
  LEVEL4S_OFFICIAL_CURRICULUM,
  LEVEL3S_OFFICIAL_CURRICULUM,
  LEVEL2S_OFFICIAL_CURRICULUM,
  OFFICIAL_CURRICULUM_EXPORT,
  OFFICIAL_CURRICULUM_MASTER,
  PUBLISHED_RELEASE_NOTE,
  QUESTION_ASSIGN_LEVEL,
  RELEASE_NOTE_SECTION,
  CURRICULUM_EXPORT,
  CURRICULUM_TRANS,
  KNOWLEDGE_TO,
  RELEASE_NOTE,
  CURRICULUMS,
  KNOWLEDGE,
  QUESTIONS,
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  ATTACH,
} from 'configs';
import { getUser } from 'pages/QuestionMaster/thunk';
import { browserLogger } from 'libs/logger';

export const createCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Curriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/createCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(OFFICIAL_CURRICULUM_MASTER.name, req);
    browserLogger.info('curriculum/thunk/createCurriculum', OFFICIAL_CURRICULUM_MASTER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(OFFICIAL_CURRICULUM_MASTER.name, req);
    browserLogger.info('curriculum/thunk/updateCurriculum', OFFICIAL_CURRICULUM_MASTER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel1 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level.ResponseType>(
      LEVEL1S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('curriculum/thunk/getDataLevel1', LEVEL1S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level.ResponseType>(
      LEVEL2S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('curriculum/thunk/getDataLevel2', LEVEL2S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel3 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level.ResponseType>(
      LEVEL3S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('curriculum/thunk/getDataLevel3', LEVEL3S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel4 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level.ResponseType>(
      LEVEL4S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('curriculum/thunk/getDataLevel4', LEVEL4S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuesLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataQuesLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionAssignLevel.ResponseType>(
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info(
      'curriculum/thunk/getDataQuesLevel',
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.name, req);
    browserLogger.info('curriculum/thunk/getDataQues', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const setPublishCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/setPublishCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(OFFICIAL_CURRICULUM_MASTER.name, req);
    browserLogger.info(
      'curriculum/thunk/setPublishCurriculum',
      OFFICIAL_CURRICULUM_MASTER.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(OFFICIAL_CURRICULUM_MASTER.name, req);
    browserLogger.info('curriculum/thunk/deleteCurriculum', OFFICIAL_CURRICULUM_MASTER.name, data);
    return { ...data, item: { i_id: req.id } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumUserUsing = createAsyncThunk<
  Types.GetItemResponseType<Types.Curriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Curriculum.ResponseType>(CURRICULUMS.name, {
      ...req,
      sort_fields: [{ id: 'code', order: 'asc' }],
    });
    browserLogger.info('curriculum/thunk/getCurriculum', CURRICULUMS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteCurriculumByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteCurriculumByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(CURRICULUMS.name, req);
    browserLogger.info('curriculum/thunk/deleteCurriculumByCondition', CURRICULUMS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLevel1sByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteLevel1sByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(LEVEL_1.name, req);
    browserLogger.info('curriculum/thunk/deleteLevel1sByCondition', LEVEL_1.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLevel2sByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteLevel2sByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(LEVEL_2.name, req);
    browserLogger.info('curriculum/thunk/deleteLevel2sByCondition', LEVEL_2.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLevel3sByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteLevel3sByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(LEVEL_3.name, req);
    browserLogger.info('curriculum/thunk/deleteLevel3sByCondition', LEVEL_3.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLevel4sByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteLevel4sByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(LEVEL_4.name, req);
    browserLogger.info('curriculum/thunk/deleteLevel4sByCondition', LEVEL_4.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteQuestionByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteQuestionByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(QUESTIONS.name, req);
    browserLogger.info('curriculum/thunk/deleteQuestionByCondition', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteQuestionAssignLevel4OfficialByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'curriculum/thunk/deleteQuestionAssignLevel4OfficialByCondition',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.deleteItemByConditions(QUESTION_ASSIGN_LEVEL.name, req);
      browserLogger.info(
        'curriculum/thunk/deleteQuestionAssignLevel4OfficialByCondition',
        QUESTION_ASSIGN_LEVEL.name,
        data
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUserAssignCurriculumByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteUserAssignCurriculumByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(
      USER_ASSIGN_REQUIRED_CURRICULUM.name,
      req
    );
    browserLogger.info(
      'curriculum/thunk/deleteUserAssignCurriculumByCondition',
      USER_ASSIGN_REQUIRED_CURRICULUM.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteKnowledgeByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteKnowledgeByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(KNOWLEDGE.name, req);
    browserLogger.info('curriculum/thunk/deleteKnowledgeByCondition', KNOWLEDGE.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteKnowledgeToByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteKnowledgeToByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(KNOWLEDGE_TO.name, req);
    browserLogger.info('curriculum/thunk/deleteKnowledgeToByCondition', KNOWLEDGE_TO.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLevelCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Level.ResponseType> & { level: number },
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/createLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.createLevel(req.level, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateLevelCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Level.ResponseType> & { level: number },
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.updateLevel(req.level, {
      id: req.id,
      data: {
        ...req.data,
        is_force_update: true,
        return_item_result: true,
      },
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLevelCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType & { level: number },
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.deleteLevel(req.level, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const curriculumExportDataCSV = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ItemExport>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/curriculumExportDataCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ItemExport>(CURRICULUM_EXPORT.name, req);
    browserLogger.info('curriculum/thunk/curriculumExportDataCSV', CURRICULUM_EXPORT.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const curriculumExportDataCSV2 = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ItemExport>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/curriculumExportDataCSV2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ItemExport>(OFFICIAL_CURRICULUM_EXPORT.name, req);
    browserLogger.info(
      'curriculum/thunk/curriculumExportDataCSV2',
      OFFICIAL_CURRICULUM_EXPORT.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('Curriculum/thunk/uploadFileToS3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createImages = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Curriculum/thunk/createImages', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);
    browserLogger.info('Curriculum/thunk/createImages', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  any,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.name, req);
    browserLogger.info('curriculum/thunk/deleteFileAttach', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteReleaseNote = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(RELEASE_NOTE.name, req);
    browserLogger.info('curriculum/thunk/deleteReleaseNote', RELEASE_NOTE.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deletePublishedReleaseNote = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deletePublishedReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(PUBLISHED_RELEASE_NOTE.name, req);
    browserLogger.info(
      'curriculum/thunk/deletePublishedReleaseNote',
      PUBLISHED_RELEASE_NOTE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteCurriculumPublishedHistory = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(CURRICULUM_PUBLISHED_HISTORY.name, req);
    browserLogger.info(
      'curriculum/thunk/deleteCurriculumPublishedHistory',
      CURRICULUM_PUBLISHED_HISTORY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deletePublishedReleaseNoteSection = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deletePublishedReleaseNoteSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(
      PUBLISHED_RELEASE_NOTE_SECTION.name,
      req
    );
    browserLogger.info(
      'curriculum/thunk/deletePublishedReleaseNoteSection',
      PUBLISHED_RELEASE_NOTE_SECTION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteReleaseNoteSection = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteReleaseNoteSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(RELEASE_NOTE_SECTION.name, req);
    browserLogger.info(
      'curriculum/thunk/deleteReleaseNoteSection',
      RELEASE_NOTE_SECTION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachCurriculumFile = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getAttachCurriculumFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.name, req);
    browserLogger.info('curriculum/thunk/getAttachCurriculumFile', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);
    browserLogger.info('curriculum/thunk/createFileAttach', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionAssignedToCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevelOfficialCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getQuestionAssignedToCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } =
      await services.search<Types.QuestionAssignLevelOfficialCurriculum.ResponseType>(
        QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
        req
      );
    browserLogger.info(
      'curriculum/thunk/getQuestionAssignedToCurriculum',
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionAssignedLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getQuestionAssignedLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionAssignLevel.ResponseType>(
      QUESTION_ASSIGN_LEVEL.name,
      req
    );
    browserLogger.info(
      'curriculum/thunk/getQuestionAssignedLevel',
      QUESTION_ASSIGN_LEVEL.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReleaseNote = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataReleaseNoteID', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(RELEASE_NOTE.name, req);
    browserLogger.info('curriculum/thunk/getDataReleaseNoteID', RELEASE_NOTE.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const isValid = (node: Types.TreeItem<Types.CurriculumItemType>) => {
  if (node.columnIndex === 4) {
    return node.children?.length! === 0;
  }
  if (!node?.children?.length) {
    return true;
  }
  return node.children.some(isValid);
};

export const getDataCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.CurriculumTable.ResponseType> & {
    dataCurriculumTree: Array<Types.TreeItem<Types.CurriculumItemType>>;
    dataCurriculums: Types.Curriculum.ResponseType[];
    dataQuestions: Types.Questions.ResponseType[];
    dataLevel1s: Types.Level.ResponseType[];
    dataLevel2s: Types.Level.ResponseType[];
    dataLevel3s: Types.Level.ResponseType[];
    dataLevel4s: Types.Level.ResponseType[];
    dataKeyDontHaveQuestion: string[];
    questionKeys: string[];
  },
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataCurriculum', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { data } = await services.search<Types.Curriculum.ResponseType>(
      OFFICIAL_CURRICULUM_MASTER.name,
      {
        ...req,
        sort_fields: [{ id: 'official_curriculum_code', order: 'asc' }],
      }
    );
    browserLogger.info('curriculum/thunk/getDataCurriculum', OFFICIAL_CURRICULUM_MASTER.name, data);
    const { authContainer } = getState() as Types.RootState;

    const conditions = [
      {
        id: 'company_id',
        search_value: [authContainer?.userInfo?.company_id],
      },
    ];

    const responseStatus = await Promise.all([
      dispatch(
        getDataLevel1({
          conditions,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel2({
          conditions,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel3({
          conditions,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel4({
          conditions,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQuesLevel({
          conditions,
          include_item_ref: true,
          include_links: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQues({
          conditions: [
            {
              id: 'company_id',
              search_value: [join([authContainer?.userInfo?.company_id, '000000001'], '|')],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    const resultArray: Array<Types.CurriculumTable.ResponseType> = [];
    let dataUserFilter: Array<any> = [];

    if (
      getDataLevel1.fulfilled.match(responseStatus[0]) &&
      getDataLevel2.fulfilled.match(responseStatus[1]) &&
      getDataLevel3.fulfilled.match(responseStatus[2]) &&
      getDataLevel4.fulfilled.match(responseStatus[3]) &&
      getDataQuesLevel.fulfilled.match(responseStatus[4]) &&
      getDataQues.fulfilled.match(responseStatus[5]) &&
      getUser.fulfilled.match(responseStatus[6])
    ) {
      const dataLevel1 = responseStatus[0].payload.items;
      const dataLevel2 = responseStatus[1].payload.items;
      const dataLevel3 = responseStatus[2].payload.items;
      const dataLevel4 = responseStatus[3].payload.items;
      const dataAssignLevel = responseStatus[4].payload.items;
      const formatQuestions = responseStatus[5].payload.items.map((item) => ({
        ...item,
        original_question_code: item.original_question_code || item.code,
        version_number: item.version_number || 1,
      }));

      const dataQues = Object.values(groupBy(formatQuestions, 'original_question_code')).map(
        (items: Array<Types.Questions.ResponseType>) =>
          maxBy(items, (item) =>
            typeof item.version_number === 'string'
              ? parseInt(item.version_number, 10)
              : item.version_number
          )
      ) as Array<Types.Questions.ResponseType>;

      const dataUser = responseStatus[6].payload.items;
      dataUserFilter = responseStatus[6].payload.items;

      const dataNewItems = filter(
        data.items,
        (item) =>
          !!item.createdby &&
          !!find(dataUser, {
            login_id: item.createdby,
          })
      );

      dataNewItems.flatMap((item) => {
        const matchedLevel1Items = filter(
          dataLevel1,
          (level1) =>
            !!item.official_curriculum_code &&
            level1.official_curriculum_code === item.official_curriculum_code
        );

        const baseObject = {
          rowKey: item.official_curriculum_code,
          curriculum_id: item.i_id,
          curriculum_code: item.official_curriculum_code,
          curriculum_name: item.name,
          curriculum_description: item.description,
          curriculum_fileID: item.fileID,
          curriculum_publish: Number(item.publish ?? 0),
          curriculum_probs_count: Number(item.probs_count ?? 0),
          curriculum_sort_order: Number(item.sort_order ?? 0),
          required_curriculum: Number(item.required_curriculum ?? 0),
          official_curriculum: Number(item.official_curriculum ?? 0),
        };

        if (matchedLevel1Items.length) {
          matchedLevel1Items.flatMap((level1Item) => {
            const baseObject1 = {
              ...baseObject,
              rowKey: `${item.official_curriculum_code}_${level1Item.code}`,
              level1_i_id: level1Item.i_id,
              level1_name: level1Item.name ?? '',
              level1_code: level1Item.code,
              level1_sort_order: Number(level1Item.sort_order ?? 0),
            };
            const matchedLevel2Items = filter(dataLevel2, { level1_code: level1Item.code });

            if (matchedLevel2Items.length) {
              matchedLevel2Items.flatMap((level2Item) => {
                const baseObject2 = {
                  ...baseObject1,
                  rowKey: `${item.official_curriculum_code}_${level1Item.code}_${level2Item.code}`,
                  level2_i_id: level2Item.i_id,
                  level2_name: level2Item.name ?? '',
                  level2_code: level2Item.code,
                  level2_sort_order: Number(level2Item.sort_order ?? 0),
                };
                const matchedLevel3Items = filter(dataLevel3, { level2_code: level2Item.code });

                if (matchedLevel3Items.length) {
                  matchedLevel3Items.flatMap((level3Item) => {
                    const baseObject3 = {
                      ...baseObject2,
                      rowKey: `${item.official_curriculum_code}_${level1Item.code}_${level2Item.code}_${level3Item.code}`,
                      level3_i_id: level3Item.i_id,
                      level3_name: level3Item.name ?? '',
                      level3_code: level3Item.code,
                      level3_sort_order: Number(level3Item.sort_order ?? 0),
                    };
                    const matchedLevel4Items = filter(dataLevel4, {
                      level3_code: level3Item.code,
                    });

                    if (matchedLevel4Items.length) {
                      matchedLevel4Items.flatMap((level4Item) => {
                        const baseObject4 = {
                          ...baseObject3,
                          rowKey: `${item.official_curriculum_code}_${level1Item.code}_${level2Item.code}_${level3Item.code}_${level4Item.code}`,
                          level4_i_id: level4Item.i_id,
                          level4_name: level4Item.name ?? '',
                          level4_code: level4Item.code,
                          level4_sort_order: Number(level4Item.sort_order ?? 0),
                        };

                        const matchedQuestionItems = filter(dataAssignLevel, {
                          level4_code: level4Item.code,
                        });

                        if (matchedQuestionItems.length) {
                          matchedQuestionItems.flatMap((quesAssign) => {
                            const questionFounded = find(dataQues, { code: quesAssign.code });
                            if (questionFounded) {
                              resultArray.push({
                                ...baseObject4,
                                rowKey: `${item.official_curriculum_code}_${level1Item.code}_${level2Item.code}_${level3Item.code}_${level4Item.code}_${questionFounded.code}`,
                                question_code_i_id: questionFounded.i_id,
                                question_code: questionFounded.code,
                                question_name: questionFounded.name,
                                question_creator: questionFounded.createdby,
                                question_sort_order: Number(quesAssign.sort_order),
                                question_assign_level_i_id: quesAssign.i_id,
                              });
                            } else {
                              resultArray.push(baseObject4);
                            }
                          });
                        } else {
                          resultArray.push(baseObject4);
                        }
                      });
                    } else {
                      resultArray.push(baseObject3);
                    }
                  });
                } else {
                  resultArray.push(baseObject2);
                }
              });
            } else {
              resultArray.push(baseObject1);
            }
          });
        } else {
          resultArray.push(baseObject);
        }
      });
    }

    const flattenedResultArray = chain(uniqBy(resultArray, 'rowKey'))
      .flatten()
      .orderBy(
        [
          'curriculum_code',
          'level1_sort_order',
          'level1_code',
          'level2_sort_order',
          'level2_code',
          'level3_sort_order',
          'level3_code',
          'level4_sort_order',
          'level4_code',
          'question_sort_order',
          'question_code',
        ],
        ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc']
      )
      .value();

    const dataCurriculumTree: Array<Types.TreeItem<Types.CurriculumItemType>> = [];
    let strings: string[] = [];
    flattenedResultArray.forEach((item) => {
      const itemIndex = dataCurriculumTree.findIndex((c) => c.i_id === item.curriculum_id);
      if (itemIndex >= 0) {
        dataCurriculumTree[itemIndex] = memoizedConvertFlatDataFromTree({
          treeData: dataCurriculumTree[itemIndex],
          item,
          strings,
        });
      } else {
        const func = (item: Types.CurriculumTable.ResponseType) => {
          strings = [...strings, item.curriculum_id];
          return [
            {
              i_id: item.question_code_i_id,
              name: item.question_name,
              code: item.question_code,
              curriculum_id: item.curriculum_id,
              probs_count: item.curriculum_probs_count,
              question_assign_level_i_id: item.question_assign_level_i_id,
              creator: item.question_creator,
              sort_order: Number(item.question_sort_order),
              publish: Number(item.curriculum_publish),
              required_curriculum: Number(item.required_curriculum),
              columnIndex: 5,
            },
          ];
        };

        dataCurriculumTree.push({
          i_id: item.curriculum_id,
          curriculum_id: item.curriculum_id,
          probs_count: item.curriculum_probs_count,
          code: item.curriculum_code,
          name: item.curriculum_name,
          description: item.curriculum_description,
          sort_order: Number(item.curriculum_sort_order),
          publish: Number(item.curriculum_publish),
          required_curriculum: Number(item.required_curriculum),
          columnIndex: 0,
          fileID: item.curriculum_fileID,
          children: item.level1_i_id
            ? [
                {
                  i_id: item.level1_i_id,
                  name: item.level1_name,
                  code: item.level1_code,
                  curriculum_id: item.curriculum_id,
                  probs_count: item.curriculum_probs_count,
                  sort_order: Number(item.level1_sort_order),
                  publish: Number(item.curriculum_publish),
                  required_curriculum: Number(item.required_curriculum),
                  columnIndex: 1,
                  children: item.level2_i_id
                    ? [
                        {
                          i_id: item.level2_i_id,
                          name: item.level2_name,
                          code: item.level2_code,
                          probs_count: item.curriculum_probs_count,
                          curriculum_id: item.curriculum_id,
                          sort_order: Number(item.level2_sort_order),
                          publish: Number(item.curriculum_publish),
                          required_curriculum: Number(item.required_curriculum),
                          columnIndex: 2,
                          children: item.level3_i_id
                            ? [
                                {
                                  i_id: item.level3_i_id,
                                  name: item.level3_name,
                                  code: item.level3_code,
                                  probs_count: item.curriculum_probs_count,
                                  curriculum_id: item.curriculum_id,
                                  sort_order: Number(item.level3_sort_order),
                                  publish: Number(item.curriculum_publish),
                                  required_curriculum: Number(item.required_curriculum),
                                  columnIndex: 3,
                                  children: item.level4_i_id
                                    ? [
                                        {
                                          i_id: item.level4_i_id,
                                          name: item.level4_name,
                                          code: item.level4_code,
                                          probs_count: item.curriculum_probs_count,
                                          curriculum_id: item.curriculum_id,
                                          sort_order: Number(item.level4_sort_order),
                                          publish: Number(item.curriculum_publish),
                                          required_curriculum: Number(item.required_curriculum),
                                          columnIndex: 4,
                                          children: item.question_code_i_id ? func(item) : [],
                                        },
                                      ]
                                    : [],
                                },
                              ]
                            : [],
                        },
                      ]
                    : [],
                },
              ]
            : [],
        });
      }
    });
    const array = dataCurriculumTree.filter(isValid);
    return {
      ...data,
      questionKeys: uniq(strings),
      dataKeyDontHaveQuestion: uniq(array.map((item) => item.code ?? '')),
      items: [...flattenedResultArray],
      dataCurriculumTree: dataCurriculumTree,
      dataLevel1s: getDataLevel1.fulfilled.match(responseStatus[0])
        ? responseStatus[0].payload.items
        : [],
      dataLevel2s: getDataLevel2.fulfilled.match(responseStatus[1])
        ? responseStatus[1].payload.items
        : [],
      dataLevel3s: getDataLevel3.fulfilled.match(responseStatus[2])
        ? responseStatus[2].payload.items
        : [],
      dataLevel4s: getDataLevel4.fulfilled.match(responseStatus[3])
        ? responseStatus[3].payload.items
        : [],
      dataQuestions: getDataQues.fulfilled.match(responseStatus[5])
        ? sortBy(
            map(
              Object.values(
                groupBy(
                  [...responseStatus[5].payload.items].map((item) => ({
                    ...item,
                    original_question_code: item?.original_question_code
                      ? item.original_question_code
                      : item.code,
                    version_number: item.version_number ? item.version_number : 1,
                  })),
                  'original_question_code'
                )
              ).map((items: Array<Types.Questions.ResponseType>) =>
                maxBy(items, (item) =>
                  typeof item.version_number === 'string' ? parseInt(item.version_number, 10) : 1
                )
              ) as Array<Types.Questions.ResponseType>,
              (item) => ({
                ...item,
                creator:
                  dataUserFilter.find((item1) => item1.login_id === item.createdby)?.name ??
                  item.creator,
              })
            ),
            'code'
          )
        : [],
      dataCurriculums: data.items,
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCurriculumTrans = createAsyncThunk<
  Types.GetItemResponseType<Types.CurriculumTrans.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataCurriculumTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.CurriculumTrans.ResponseType>(
      CURRICULUM_TRANS.name,
      req
    );
    browserLogger.info('curriculum/thunk/getDataCurriculumTrans', CURRICULUM_TRANS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialCurriculumUserAggregation = createAsyncThunk<
  Types.GetItemResponseType<Types.OfficialCurriculumUserAggregation.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataOfficialCurriculumUserAggregation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.OfficialCurriculumUserAggregation.ResponseType>(
      OFFICIAL_CURRICULUM_USER_AGGREGATION.name,
      req
    );
    browserLogger.info(
      'curriculum/thunk/getDataOfficialCurriculumUserAggregation',
      OFFICIAL_CURRICULUM_USER_AGGREGATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateOfficialCurriculumUserAggregation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.OfficialCurriculumUserAggregation.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateOfficialCurriculumUserAggregation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(OFFICIAL_CURRICULUM_USER_AGGREGATION.name, req);
    browserLogger.info(
      'curriculum/thunk/updateOfficialCurriculumUserAggregation',
      OFFICIAL_CURRICULUM_USER_AGGREGATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialCurriculumAllCompanyAggregation = createAsyncThunk<
  Types.GetItemResponseType<Types.OfficialCurriculumUserAggregation.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'curriculum/thunk/getDataOfficialCurriculumAllCompanyAggregation',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.search<Types.OfficialCurriculumUserAggregation.ResponseType>(
        OFFICIAL_CURRICULUM_USER_AGGREGATION.name,
        req
      );
      browserLogger.info(
        'curriculum/thunk/getDataOfficialCurriculumAllCompanyAggregation',
        OFFICIAL_CURRICULUM_USER_AGGREGATION.name,
        data
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
