import styled from 'styled-components';

type DivProps = { columnIndex?: number; isPublish?: number };

export const DivCustom = styled.div<DivProps>`
  font-weight: 400;
  font-size: 12px;
  border-top: 2px solid
    ${(props) =>
      props.columnIndex === 0
        ? '#2773BA'
        : props.columnIndex === 1
        ? '#F6AC00'
        : props.columnIndex === 2
        ? '#C4C4C4'
        : props.columnIndex === 3
        ? '#71C131'
        : props.columnIndex === 4
        ? '#DD3D63'
        : props.columnIndex === 5
        ? '#00A3A5'
        : 'inherit'};
  .rowWrapper {
    &:nth-child(2) {
      padding: 0;
    }
    &:nth-child(1) {
      .action-bottom {
        padding: 0;
      }
    }
    .ant-popover-inner-content {
      width: auto !important;
    }
    .item-image {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 144px;
      .image {
        max-width: 100%;
        height: auto;
        max-height: 144px;
        object-fit: cover;
      }
    }
    .no-image {
      width: 100%;
      height: 105px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: center;
      background-color: #f0f0f0;
      .text {
        color: #6f95aa;
        font-size: 10px;
        font-weight: 700;
      }
    }
    .icon-logo {
      width: 45px;
      height: 45px;
    }
    border-top: 1px solid
      ${(props) => (props.columnIndex === 0 || props.columnIndex === 4 ? '#F0F0F0' : 'inherit')};
    padding: 10px;
    .rowContentHead {
      display: flex;
      align-items: center;
      .spanHead {
        display: flex;
        align-items: center;
        color: #777777;
        margin-right: 10px;
        .icon {
          margin-right: 2px;
          width: 14px;
          height: 14px;
        }
        .spanText {
          font-size: 10px;
        }
      }
    }
    .rowContent {
      display: flex;
      justify-content: space-between;
      .icons {
        display: flex;
        align-items: center;
        .icon {
          color: #c4c4c4;
          font-size: 18px;
          margin-left: 10px;
          &:hover {
            color: #009acb;
          }
        }
        .icon-disable {
          cursor: not-allowed;
          &:hover {
            color: #c4c4c4;
          }
        }
      }
      .caretIcon {
        color: #bfbfbf;
      }
      .icons:only-child {
        align-self: flex-end;
        margin-left: auto;
      }
      .ant-switch-checked {
        background-color: ${(props) =>
          props.columnIndex === 0 ? '#eb8b55' : props.columnIndex === 4 ? '#DD3D63' : ''};
      }
    }
  }
  .action-bottom {
    padding: 12px 14px 12px 14px;
    .create-level-button {
      width: 137px;
    }
    .disabled {
      background: #cccccc !important;
      border-color: #cccccc !important;
      border-radius: 5px !important;
      color: #777777 !important;
      cursor: not-allowed;
    }
  }
  .bgGrey {
    background-color: #f7f8fa;
  }
  .blOrange {
    border-left: 2px solid #eb8b55;
  }
  .blGreen {
    border-left: 2px solid #b57fcf;
    .rowContent {
      cursor: pointer;
    }
  }
  .blViolet {
    border-left: 2px solid #71c131;
    .rowContent {
      cursor: pointer;
    }
  }
  .blRed {
    border-left: 2px solid #dd3d63;
    .action-bottom {
      padding-bottom: 0px;
    }
    .rowContent {
      padding: 8px;
    }
  }
`;

export const Button = styled.button`
  background: #f6ac00;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
  padding: 5px 15px;
`;

export const DivCustomCanDrag = styled.div<DivProps>`
  .wrap-content {
    padding: 10px;
    p {
      font-weight: 400;
      font-size: 12px;
      color: #555555;
      margin: 0;
      line-height: 18px;
    }
  }
  .wrap-button {
    display: flex;
    width: 100%;
    button,
    .disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      width: 50%;
      border: none;
      background: #009acb;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.05);
      font-weight: 400;
      font-size: 13px;
      color: #ffffff;
      cursor: pointer;
      img {
        margin-right: 5px;
        opacity: 0.5;
      }
      &:hover {
        background: #00abe1;
        img {
          opacity: 1;
        }
      }
      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .disabled {
      background: #cccccc !important;
    }
  }
  .wrap-button-drop {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px 10px 10px;
    gap: 8px;
    .button {
      width: 100%;
      height: 32px;
      font-size: 14px;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
    .button-ok {
      cursor: ${(props) => (props.isPublish === 2 ? 'no-drop' : 'pointer')} !important;
    }
    .ok {
      font-family: 'Lato';
      font-weight: 700;
      color: #ffffff;
      background: ${(props) => (props.isPublish === 2 ? '#cccccc' : '#f6ac00')};
    }
    .cancel {
      font-weight: 400;
      color: #777777;
      background: #ffffff;
      border: 1px solid #d9d9d9;
    }
  }
`;
