import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  ADMIN_REPORT_MONTHLY_USAGES_ACCUMULATIVE_DATA,
  ADMIN_REPORT_MONTHLY_USAGES_ACCUMULATIVE_DATA_CORPORATION,
  ADMIN_REPORT_MONTHLY_USAGES_ACCUMULATIVE_DATA_INDIVIDUAL,
  ADMIN_REPORT_MONTHLY_USAGES_CORPORATION,
  ADMIN_REPORT_MONTHLY_USAGES_INDIVIDUAL,
  ADMIN_REPORT_SALE_INFORMATION,
  ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN,
  ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN_DETAILS,
  ADMIN_REPORT_SALES_INFOMATION_ACCUMULATIVE_DATA,
  ADMIN_REPORT_SALES_INFOMATION_BREAKDOWN_ACCUMULATIVE_DATA,
  SELECT_USERS,
} from 'configs';

import { toObject } from 'libs';
import { AnyObject } from 'types';
import { USER_TYPE } from 'constant/report';
import { browserLogger } from 'libs/logger';

export const getDataReportSaleInformation = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataReportSaleInformation', async (req, { rejectWithValue }) => {
  try {

    const [saleReportsRes, corporationReportsRes, individualReportsRes] = await Promise.all([
      services.filter<AnyObject>(ADMIN_REPORT_SALE_INFORMATION.name, req),
      services.filter(ADMIN_REPORT_MONTHLY_USAGES_CORPORATION.name, {
        ...req,
        conditions: [],
      }),
      services.filter(ADMIN_REPORT_MONTHLY_USAGES_INDIVIDUAL.name, req),
    ]);
    const corporationReportsObj = toObject(
      corporationReportsRes.data.report_results,
      'target_month'
    );
    const individualReportsObj = toObject(individualReportsRes.data.report_results, 'target_month');
    return {
      ...saleReportsRes.data,
      report_results: saleReportsRes.data.report_results.map((obj) => {
        const extraData =
          obj.user_type === USER_TYPE.CORPORATE
            ? corporationReportsObj[obj.target_month]
            : obj.user_type === USER_TYPE.PERSONAL
            ? individualReportsObj[obj.target_month]
            : {};
        console.log(obj.target_month, extraData);
        return {
          ...obj,
          ...(extraData || {}),
        };
      }),
    };
    const { data } = await services.filter(ADMIN_REPORT_SALE_INFORMATION.name, req);
    browserLogger.info(
      'report/thunk/getDataReportSaleInformation',
      ADMIN_REPORT_SALE_INFORMATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReportSaleInformationBreakdown = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataReportSaleInformationBreakdown', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN.name, req);
    browserLogger.info(
      'report/thunk/getDataReportSaleInformationBreakdown',
      ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReportSaleInformationBreakdownDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataReportSaleInformationBreakdownDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(
      ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN_DETAILS.name,
      req
    );
    browserLogger.info(
      'report/thunk/getDataReportSaleInformationBreakdownDetail',
      ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN_DETAILS.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.Selects.SelectUserResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getSelectUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.Selects.SelectUserResponseType>(
      SELECT_USERS.name,
      req
    );
    browserLogger.info('report/thunk/getSelectUser', SELECT_USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReportSaleInformationAccumulativeData = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataReportSaleInformationAccumulativeData', async (req, { rejectWithValue }) => {
  try {
    const [saleReportsRes, corporationReportsRes, individualReportsRes] = await Promise.all([
      services.filter<AnyObject>(ADMIN_REPORT_SALES_INFOMATION_ACCUMULATIVE_DATA.name, req),
      services.filter(ADMIN_REPORT_MONTHLY_USAGES_ACCUMULATIVE_DATA_CORPORATION.name, req),
      services.filter(ADMIN_REPORT_MONTHLY_USAGES_ACCUMULATIVE_DATA_INDIVIDUAL.name, req),
    ]);
    const corporationReportsObj = toObject(
      corporationReportsRes.data.report_results,
      'target_month'
    );
    const individualReportsObj = toObject(individualReportsRes.data.report_results, 'target_month');
    return {
      ...saleReportsRes.data,
      report_results: saleReportsRes.data.report_results.map((obj) => {
        const extraData =
          obj.user_type === USER_TYPE.CORPORATE
            ? corporationReportsObj[obj.target_month]
            : obj.user_type === USER_TYPE.PERSONAL
            ? individualReportsObj[obj.target_month]
            : {};
        return {
          ...obj,
          ...(extraData || {}),
        };
      }),
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReportMonthlyUsagesAccumulativeDataCorporation = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'report/thunk/getDataReportMonthlyUsagesAccumulativeDataCorporation',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(
        ADMIN_REPORT_SALES_INFOMATION_BREAKDOWN_ACCUMULATIVE_DATA.name,
        req
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataReportMonthlyUsagesAccumulativeDataIndividual = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'report/thunk/getDataReportMonthlyUsagesAccumulativeDataIndividual',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.search(
        ADMIN_REPORT_MONTHLY_USAGES_ACCUMULATIVE_DATA.name,
        req
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
