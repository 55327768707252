import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import lodash, { orderBy, uniqBy } from 'lodash';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import { DeleteOutlined, FormOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

import { setHeaderTitle, startLoading, stopLoading } from 'containers/AppSettings/slice';
import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { getUsers } from 'pages/UserManagement/AuthorityMaster/thunk';
import CompletedDeleteModal from 'components/Modal/CompletedDelete';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { Header, PagingNumber, SelectField } from 'components';
import ActionErrorModal from 'components/Modal/ActionError';
import { getHeaderTitle } from 'libs/utils/getHeaderTitle';
import { importEmployeeCsvSchema } from 'libs/validations';
import { DATE_FORMAT, YEAR_MONTH_FORMAT } from 'constant';
import { authSelector } from 'containers/Auth/selectors';
import PerPageSelect from 'components/PerPageSelect';
import PDFUsersDocument from './PDFUsersDocument';
import { userMasterSelector } from './selectors';
import PDFUserDocument from './PDFUserDocument';
import ImportButton from 'components/Buttons';
import UploadCSV from 'components/UploadCSV';
import { routes } from 'navigations/routes';
import { filterListUser } from './slice';
import UserMasterStyled from './styles';
import * as Types from 'types';
import { AnyObject } from 'types';
import {
  HEADER_EMPLOYEE_CSV_V2,
  HEADER_IMPORT_EMPLOYEE_CSV,
} from 'constant/header.export.constant';
import {
  addUserAdmin,
  createAdminAffiliationAssignRole,
  createAdminUsers,
  deleteAdminUsers,
  deleteItemByConditionsAffiliationRole,
  getAdminRoleList,
  getListUserAdmin,
  getSelectAdminDepartment,
  getSelectAdminPosition,
  getSelectAdminUsers,
  getSelectUserAdmin,
  getSubPosition,
} from './thunk';
import {
  getDataUserRegistrationHistory,
  updateUserRegistrationHistory,
} from 'containers/Auth/thunk';
import { SELECT_RECORD } from 'constant/select.constants';
import { config } from 'configs';
import { exportCsv } from 'libs/utils/exportCsv';

const { Option } = Select;

const calculateAge = (birthday: Date) => {
  const birthDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const formatLastLoginDate = (date: Date | string | null | undefined) => {
  if (date === null || date === undefined) {
    return '';
  }
  const lastLoginDate = new Date(date);
  const formattedDate = lastLoginDate.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const formattedTime = lastLoginDate.toLocaleTimeString('ja-JP', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  return `${formattedDate.replace(/\//g, '/')}` + ' ' + `${formattedTime}`;
};

const formatHireDate = (date: Date | string | null | undefined): string => {
  if (date === null || date === undefined) {
    return '';
  }

  const hireDate = new Date(date);

  if (!isNaN(hireDate.getTime())) {
    return `${hireDate.getFullYear()}/${String(hireDate.getMonth() + 1).padStart(2, '0')}`;
  }

  return '';
};

const UserMaster: React.FC = () => {
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [openModalDeleteComplete, setOpenModalDeleteComplete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.EmployeeUser.ResponseType[]>([]);
  const [itemSelected, setItemSelected] = useState<Types.EmployeeUser.ResponseType>();
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const {
    dataSelectAdminAllRole,
    dataListUser,
    dataSelectListUser,
    dataSelectPosition,
    dataSelectDepartment,
  } = useSelector(userMasterSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const { permissionNumber } = usePermission();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const columns: ColumnsType<Types.EmployeeUser.ResponseType> = [
    {
      title: '社員番号',
      dataIndex: 'employee_code',
      key: 'employee_code',
      width: '115px',
      ellipsis: true,
      align: 'left',
      fixed: 'left',
      className: 'col-1-fixed',
    },
    {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      align: 'left',
      fixed: 'left',
      className: 'col-2-fixed',
      render: (text: string) => {
        const splitText = () => {
          const line1 = text.slice(0, 15);
          const line2 = text.slice(15, 15 * 2);
          const line3 = text.slice(15 * 2);
          return (
            <>
              <p>{line1}</p>
              <p>
                {line2}
                {line3 && '...'}
              </p>
            </>
          );
        };
        return splitText();
      },
    },
    {
      title: 'フリガナ',
      dataIndex: 'kana',
      key: 'kana',
      align: 'left',
    },
    {
      title: '所属',
      dataIndex: 'department_name',
      key: 'department_name',
      render: (text: string) => text?.replace(/^\d+ /, ''),
      align: 'left',
    },
    {
      title: '役職',
      dataIndex: 'positions_name',
      key: 'positions_name',
      render: (text: string) => text?.replace(/^\d+ /, ''),
      align: 'left',
    },
    {
      title: '年齢',
      dataIndex: 'birthday',
      key: 'birthday',
      width: '70px',
      render: (text: string) => `${dayjs().diff(text, 'years', false)}歳`,
      align: 'left',
    },
    {
      title: '入社年月日',
      dataIndex: 'hire_date',
      key: 'hire_date',
      width: '90px',
      render: (text: string) => text && `${dayjs(text).format('YYYY/MM')}`,
      align: 'left',
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_date',
      key: 'last_login_date',
      width: '140px',
      render: (text: string) => text && `${dayjs(text).format('YYYY/MM/DD HH:mm')}`,
      align: 'left',
    },
    {
      title: '権限',
      dataIndex: 'role_code',
      key: 'role_code',
      align: 'left',
    },
    {
      title: '編集',
      dataIndex: 'operation',
      width: '60px',
      align: 'center',
      render: (_, item) => (
        <FormOutlined
          disabled={permissionNumber !== 2}
          onClick={() => {
            if (permissionNumber !== 2) return;
            dispatch(setHeaderTitle(getHeaderTitle(routes.EditUserMaster.path)));
            navigate(
              generatePath(routes.EditUserMaster.path, {
                id: item.i_id,
              })
            );
          }}
          className="icon"
        />
      ),
    },
    {
      title: '削除',
      dataIndex: 'operation',
      width: '60px',
      align: 'center',
      render: (_: string, item) => (
        <DeleteOutlined
          disabled={permissionNumber !== 2}
          className="icon"
          onClick={() => {
            if (permissionNumber !== 2) return;
            setVisiblePopupConfirmDelete(true);
            setItemSelected(item);
          }}
        />
      ),
    },
  ];

  const fetchDataUsers = async () => {
    await dispatch(
      getListUserAdmin({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        include_lookups: true,
        page: 1,
        per_page: 0,
        include_item_ref: true,
        omit_total_items: true,
        omit_fields_data: true,
        use_display_id: true,
      })
    );
    await dispatch(
      getSelectUserAdmin({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        include_lookups: true,
        page: 1,
        per_page: 0,
      })
    );
  };

  const fetchDataAdminUserList = useCallback(async () => {
    if (userInfo) {
      dispatch(startLoading());
      await fetchDataUsers();
      dispatch(stopLoading());
    }
  }, [dispatch, userInfo]);

  const formik = useFormik({
    initialValues: {
      employee_code: '',
      name: '',
      department_name: '',
      position_code: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      await dispatch(
        getListUserAdmin({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          include_lookups: true,
          page: 1,
          per_page: 0,
        })
      );

      if (values.employee_code) {
        dispatch(filterListUser({ value: values.employee_code, type: 'employee_code' }));
      }
      if (values.name) {
        dispatch(filterListUser({ value: values.name, type: 'name' }));
      }
      if (values.department_name) {
        dispatch(filterListUser({ value: values.department_name, type: 'department_name' }));
      }
      if (values.position_code) {
        dispatch(filterListUser({ value: values.position_code, type: 'position_code' }));
      }

      dispatch(stopLoading());
      setPage(1);
    },
    onReset: () => {
      setPage(1);
    },
  });

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleSubmitDelete = async () => {
    if (itemSelected?.i_id) {
      const resultAction = await Promise.all([
        dispatch(
          deleteAdminUsers({
            id: itemSelected?.i_id,
          })
        ),
        dispatch(
          deleteItemByConditionsAffiliationRole({
            conditions: [
              { id: 'login_id', search_value: [itemSelected.login_id], exact_match: true },
            ],
          })
        ),
      ]);
      if (
        deleteAdminUsers.fulfilled.match(resultAction[0]) &&
        deleteItemByConditionsAffiliationRole.fulfilled.match(resultAction[1])
      ) {
        fetchDataSelect();
        const resultUserRegisHistory = await dispatch(
          getDataUserRegistrationHistory({
            conditions: [
              {
                id: 'id',
                search_value: [itemSelected?.i_id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 1,
          })
        );

        if (
          getDataUserRegistrationHistory.fulfilled.match(resultUserRegisHistory) &&
          resultUserRegisHistory.payload.items.length
        ) {
          await dispatch(
            updateUserRegistrationHistory({
              id: resultUserRegisHistory.payload.items[0].i_id,
              data: {
                item: {
                  deletedat: dayjs().format('YYYYMM'),
                },
                is_force_update: true,
                use_display_id: true,
              },
            })
          );
        }
        setVisiblePopupConfirmDelete(false);
        await fetchDataAdminUserList();
        setOpenModalDeleteComplete(true);
      }
    }
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const listCsv = selectedRow.map((item) => {
        const findItem = selectedRow.find((i) => i.email === item.email);

        return {
          employee_code: item.employee_code || '',
          name: item.name || '',
          kana: item.kana || '',
          affiliation_code: item.affiliation_id ?? '',
          affiliation_name: item.department_name ?? '',
          position_code: item.position_code ?? '',
          position_name: item?.positions_name ?? '',
          age: item.birthday ? `${calculateAge(item.birthday)}歳` : '-',
          hire_date: formatHireDate(item.hire_date),
          authority_code: findItem?.lookup_items?.role_code?.code || '',
          authority_name: item.role_code || '',
          email: item.email || '',
          last_login_date: formatLastLoginDate(item.last_login_date) || '',
        };
      });

      // const csvString = [
      //   HEADER_EMPLOYEE_CSV_V2.map(({ label }) => label),
      //   ...listCsv.map((item) => Object.values(item).map((v) => `"${v}"`)),
      // ]
      //   .map((e) => e.join(','))
      //   .join('\n');
      // const bom = '\uFEFF';
      // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      // saveAs(file, '社内ユーザーマスタ.csv');
      exportCsv(listCsv, HEADER_EMPLOYEE_CSV_V2, '社内ユーザーマスタ.csv');
    } else {
      if (!selectedRow.length) return;

      if (selectedRow.length === 1) {
        const userObject: Types.EmployeeUser.EmployeeExportPDF = {
          employee_code: selectedRow[0].employee_code || '',
          name: selectedRow[0].name || '',
          kana: selectedRow[0].kana || '',
          login_id: selectedRow[0].login_id || '',
          birthday: selectedRow[0].birthday || '',
          hire_date: selectedRow[0].hire_date || '',
          main_position: {
            affiliation_name: '',
            position_name: '',
            main_role: '',
          },
        };

        const resultAction = await dispatch(
          getSubPosition({
            conditions: [
              { id: 'company_id', search_value: [selectedRow[0].company_id] },
              { id: 'login_id', search_value: [selectedRow[0].login_id], exact_match: true },
            ],
            sort_fields: [{ id: 'sort_order', order: 'asc' }],
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
            include_links: true,
          })
        );

        if (getSubPosition.fulfilled.match(resultAction)) {
          const position = resultAction.payload.items.map((item) => ({
            affiliation_name: item.lookup_items?.affiliation_id?.name || '',
            position_name: item.lookup_items?.positions_code?.name || '',
            main_role: item.main_role || '',
          }));
          const main_position = position.find((pos) => pos.main_role === 'main');
          Object.assign(userObject, { main_position });
        }

        const blob = await pdf(
          <PDFUserDocument employee={userObject} role_code={selectedRow[0].role_code} />
        ).toBlob();
        saveAs(blob, `${selectedRow[0].employee_code}_${selectedRow[0].name}.pdf`);
      } else {
        const blob = await pdf(<PDFUsersDocument employeeSelected={selectedRow} />).toBlob();
        saveAs(blob, '社内ユーザーマスタ.pdf');
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleExtValidateCsv = async (dataImport: AnyObject[]) => {
    if (!userInfo) {
      return undefined;
    }
    const departmentSelectCodes = dataSelectDepartment.map((obj) => obj.affiliation_id);
    const positionSelectCodes = dataSelectPosition.map((obj) => obj.code);

    const positionRoleCodes = dataSelectAdminAllRole.map((obj) => obj.code);

    let duplicatedCodes = lodash.filter(
      lodash.uniq(
        lodash.map(dataImport, function (item) {
          if (lodash.filter(dataImport, { employee_code: item.employee_code }).length > 1) {
            return item.employee_code;
          }

          return false;
        })
      ),
      function (value) {
        return value;
      }
    );
    let duplicatedEmails = lodash.filter(
      lodash.uniq(
        lodash.map(dataImport, function (item) {
          if (lodash.filter(dataImport, { email: item.email }).length > 1) {
            return item.email;
          }

          return false;
        })
      ),
      function (value) {
        return value;
      }
    );

    const validateResultAction = await dispatch(
      getUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            conditions: [
              {
                id: 'employee_code',
                search_value: dataImport.map((obj) => obj.employee_code),
                exact_match: true,
              },
              {
                id: 'email',
                search_value: dataImport.map((obj) => obj.email),
                exact_match: true,
              },
            ],
            use_or_condition: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    if (getUsers.fulfilled.match(validateResultAction) && validateResultAction.payload.totalItems) {
      const existedEmpCodeApi = validateResultAction.payload.items.map((obj) => obj.employee_code);
      duplicatedCodes = [...duplicatedCodes, ...existedEmpCodeApi];
      const existedEmailApi = validateResultAction.payload.items.map((obj) => obj.email);
      duplicatedEmails = [...duplicatedEmails, ...existedEmailApi];
    }

    return dataImport
      .map((obj, idx) => ({ ...obj, idx }))
      .filter(
        (obj: AnyObject) =>
          duplicatedCodes.includes(obj.employee_code) ||
          duplicatedEmails.includes(obj.email) ||
          !departmentSelectCodes.includes(obj.affiliation_id) ||
          !positionRoleCodes.includes(obj.role_code) ||
          !positionSelectCodes.includes(obj.position_code)
      )
      .reduce(
        (obj, item: AnyObject) => ({
          ...obj,
          [item.idx]: {
            employee_code_error: duplicatedCodes.includes(item.employee_code)
              ? '社員番号はすでに存在します。'
              : undefined,
            email_error: duplicatedEmails.includes(item.email)
              ? 'メールアドレスはすでに存在します。'
              : undefined,
            affiliation_id_error: !departmentSelectCodes.includes(item.affiliation_id)
              ? '所属コードが存在しません。'
              : undefined,
            position_code_error: !positionSelectCodes.includes(item.position_code)
              ? '役職コードが存在しません。'
              : undefined,
            role_code_error: !positionRoleCodes.includes(item.role_code)
              ? '権限コードが存在しません。'
              : undefined,
          },
        }),
        {}
      );
  };

  const handleImportItem = async (item: AnyObject) => {
    const resultAddUserAction = await dispatch(
      addUserAdmin({
        user_code: item.email,
        email: item.email,
        username: item.name,
        passowrd: item.password,
        send_password_to_email: true,
        email_template_id: config.CONFIRM_TEMPLATES_ID,
      })
    );
    if (
      addUserAdmin.fulfilled.match(resultAddUserAction) &&
      (resultAddUserAction.payload.added || !resultAddUserAction.payload.exists)
    ) {
      const affiliation_id = dataSelectDepartment.find(
        (i) => i.affiliation_id === item.affiliation_id.split(' ')[0]
      )?.i_id;

      const positions_id = dataSelectPosition.find(
        (i) => i.code === item.position_code.split(' ')[0]
      )?.i_id;
      const roleId = dataSelectAdminAllRole.find(
        (i) => item.role_code && i.code === item.role_code.split(' ')[0]
      )?.i_id;
      const resultAction = await Promise.all([
        dispatch(
          createAdminUsers({
            item: {
              employee_code: item.employee_code,
              company_id: userInfo?.company_id,
              login_id: item.email,
              email: item.email,
              name: item.name,
              name_furigana: item.name_furigana,
              user_type: 'member',
              role_code: roleId || item.role_code,
              password: item.password,
              createdby: userInfo?.login_id,
              createdat: new Date(),
              dob: item.birthday ? dayjs(item.birthday, DATE_FORMAT, true).toDate() : undefined,
              doj: item.hire_date ? dayjs(item.hire_date, YEAR_MONTH_FORMAT).toDate() : undefined,
            },
          })
        ),
        dispatch(
          createAdminAffiliationAssignRole({
            item: {
              company_id: userInfo?.company_id,
              login_id: item.email,
              affiliation_id: affiliation_id,
              positions_code: positions_id,
              sort_order: 1,
              main_role: 'main',
            },
          })
        ),
      ]);

      if (createAdminUsers.fulfilled.match(resultAction[0])) {
        return resultAction[0]?.payload?.item_id;
      }
    }

    return null;
  };

  const onImportSuccess = async () => {
    dispatch(startLoading());
    await Promise.all([
      dispatch(
        getSelectAdminUsers({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          sort_fields: [
            {
              id: 'employee_code',
              order: 'asc',
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      fetchDataUsers(),
    ]);
    dispatch(stopLoading());
  };

  const fetchDataSelect = useCallback(() => {
    if (userInfo) {
      dispatch(
        getAdminRoleList({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (userInfo && isUserInfoChanged) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getSelectAdminDepartment({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAdminPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    fetchDataAdminUserList();
  }, [fetchDataAdminUserList]);

  useEffect(fetchDataSelect, [fetchDataSelect]);

  const { selectSortByCode, selectSortById, selectSortByRank } = useMemo(() => {
    if (!dataSelectListUser?.length) {
      return { selectSortByCode: [], selectSortById: [], selectSortByRank: [] };
    }
    const newDataSelect = [...dataSelectListUser];
    const sortByCode = newDataSelect.sort(
      (a, b) => Number(a.employee_code) - Number(b.employee_code)
    );
    const sortById = orderBy(
      uniqBy(
        newDataSelect.filter((item) => item.department_name !== undefined),
        'department_name'
      ),
      'affiliation_id',
      'asc'
    );
    const sortByRank = uniqBy(
      newDataSelect.filter((item) => item.position_code !== undefined),
      'position_code'
    ).sort((a, b) => Number(a.rank_order) - Number(b.rank_order));
    return {
      selectSortByCode: sortByCode,
      selectSortById: sortById,
      selectSortByRank: sortByRank,
    };
  }, [dataSelectListUser]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(dataListUser.length);
    }
  }, [dataListUser]);

  return (
    <UserMasterStyled collapsedMenu={collapsedMenu} isEmptyData={!dataListUser.length}>
      <Header title={headerTitle} />
      <div className="container">
        <p className="text-note">管理コンソールを使用するユーザーの作成・管理を行う画面です。</p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="employee_code"
                className="item"
                label={<span className="text-label">社員番号</span>}
              >
                <SelectField
                  name="employee_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Array.from(new Set(selectSortByCode.map((item) => item.employee_code))).map(
                    (item, i) => (
                      <Option value={item} key={i}>
                        {item}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">氏名</span>}
              >
                <SelectField
                  name="name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Array.from(new Set(selectSortByCode.map((item) => item.name))).map((item, i) => (
                    <Option value={item} key={i}>
                      {item}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="department_name"
                className="item"
                label={<span className="text-label">所属</span>}
              >
                <SelectField
                  name="department_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Array.from(new Set(selectSortById.map((item) => item.department_name))).map(
                    (item, i) => (
                      <Option value={item} key={i}>
                        {item}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="position_code"
                className="item"
                label={<span className="text-label">役職</span>}
              >
                <SelectField
                  name="position_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {selectSortByRank.map((item, i) => (
                    <Option value={item.position_code} key={i}>
                      {item.positions_name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span
                className="label-reset"
                onClick={() => {
                  formik.resetForm();
                  fetchDataAdminUserList();
                }}
              >
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-button">
          <ImportButton onClick={() => setVisiblePopupUploadCSV(true)}>インポート</ImportButton>
          <ImportButton
            icon={<PlusOutlined className="icon" />}
            onClick={() => {
              dispatch(setHeaderTitle(getHeaderTitle(routes.CreateUserMaster.path)));
              navigate(generatePath(routes.CreateUserMaster.path, { entity: 'receiving' }));
            }}
          >
            新規登録
          </ImportButton>
        </div>
        <Table
          rowKey="i_id"
          className="table"
          dataSource={dataListUser.map((item, index) => ({ ...item, index }))}
          columns={columns}
          rowSelection={{
            selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
            onChange: (_, selectedRows: Types.EmployeeUser.ResponseType[]) => {
              setSelectedRow(selectedRows);
            },
          }}
          pagination={{
            pageSize: perPage,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            total: dataListUser.length,
            position: ['topCenter'],
            showTotal: () => (
              <div className="wrap-select-record">
                <PagingNumber
                  startItem={dataListUser.length ? `${(page - 1) * perPage + 1}` : ''}
                  endItem={
                    page * perPage > dataListUser.length ? dataListUser.length : page * perPage
                  }
                  totalItem={dataListUser.length}
                />
                <PerPageSelect
                  data={dataListUser}
                  perPage={perPage}
                  setPage={setPage}
                  setPerPage={setPerpage}
                />
              </div>
            ),
          }}
        />
        <UploadCSV
          title="社内ユーザーマスタ　インポート"
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headerCSVs={HEADER_IMPORT_EMPLOYEE_CSV}
          dataSchema={importEmployeeCsvSchema}
          extValidate={handleExtValidateCsv}
          defaultUploadTimePerItem={11000}
          onSuccess={onImportSuccess}
          handleUploadPerItem={handleImportItem}
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
          title="エクスポートを実行します。"
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              エクスポートを実行する対象のユーザーを選択し、
              <br />
              再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={handleSubmitDelete}
          setVisible={setVisiblePopupConfirmDelete}
        />
        <CompletedDeleteModal
          visible={openModalDeleteComplete}
          setVisible={setOpenModalDeleteComplete}
          onSubmit={() => setOpenModalDeleteComplete(false)}
        />
      </div>
      <div className="wrap-bottom">
        <div className="flex">
          <div className="text-label">
            選択したユーザを処理：
            <Button className="btn btn-active" onClick={handleButtonExport}>
              エクスポート
            </Button>
          </div>
        </div>
      </div>
    </UserMasterStyled>
  );
};

export default UserMaster;
