import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ATTACH,
  QUESTIONS,
  SKILL_CHECK_ASSIGN_QUESTION,
  QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM,
  QUESTIONS_PARTNER,
  QUESTION_TRANS2,
} from 'configs';
import { browserLogger } from 'libs/logger';
import { services } from 'services';
import * as Types from 'types';

export const deleteQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/deleteQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTIONS.name, req);
    browserLogger.info('question/thunk/deleteQuestion', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOptionLimit = createAsyncThunk<
  Types.GetDatastoreFieldsResponseType,
  undefined,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/getOptionLimit', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.fields({ dataStoreId: QUESTIONS.name });
    browserLogger.info('question/thunk/getOptionLimit', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createQuestion = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Questions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/createQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(QUESTIONS.name, req);
    browserLogger.info('question/thunk/createQuestion', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editQuestion = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Questions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/editQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(QUESTIONS.name, req);
    browserLogger.info('question/thunk/editQuestion', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editAttachment = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/editAttachment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(ATTACH.name, req);
    browserLogger.info('question/thunk/editAttachment', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/deleteLinkQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name, req);
    browserLogger.info(
      'question/thunk/deleteLinkQuestion',
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionDetail = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/getQuestionDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(QUESTIONS.name, req);
    browserLogger.info('question/thunk/getQuestionDetail', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionDetailPartner = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/getQuestionDetailPartner', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner(QUESTIONS_PARTNER.name, req);
    browserLogger.info('question/thunk/getQuestionDetailPartner', QUESTIONS_PARTNER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLinkQuestionAssignLevel = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.QuestionAssignLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/createLinkQuestionAssignLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name, req);
    browserLogger.info(
      'question/thunk/createLinkQuestionAssignLevel',
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeActionUploadFile = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/executeActionUploadFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', QUESTIONS.name, req);
    browserLogger.info('question/thunk/executeActionUploadFile', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileInQuestion = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/getFileInQuestion', async (req, { rejectWithValue }) => {
  try {
    if (req.file_id) {
      const { data } = await services.getFile(req);

      return data;
    }

    return new ArrayBuffer(0);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFile = createAsyncThunk<
  any,
  Types.DeleteFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/deleteFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteFile(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/deleteFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.name, req);
    browserLogger.info('question/thunk/deleteFile', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFile = createAsyncThunk<
  Types.UploadFileResponseType,
  Types.UploadFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/uploadFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFile(req);

    return data;
  } catch (error) {
    return rejectWithValue({ file_id: req.uid, error });
  }
});

export const createNewFile = createAsyncThunk<
  Types.createNewFileResponseType,
  Types.createNewFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/createNewFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.createNewFile(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAttachmentTable = createAsyncThunk<
  Types.updateAttachTableResponseType,
  Types.updateAttachTableRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/updateAttachmentTable', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.updateAttachmentTable('UpdateItem', ATTACH.name, req);
    browserLogger.info('question/thunk/updateAttachmentTable', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLinkSkillCheckAssignQuestion = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.SkillCheckAssignQuestion.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/createLinkSkillCheckAssignQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(SKILL_CHECK_ASSIGN_QUESTION.name, req);
    browserLogger.info(
      'question/thunk/createLinkSkillCheckAssignQuestion',
      SKILL_CHECK_ASSIGN_QUESTION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionImplementTrans = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionTrans2.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/getQuestionImplementTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionTrans2.ResponseType>(
      QUESTION_TRANS2.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteQuestionTrans = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/deleteQuestionTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTION_TRANS2.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeBatchAction = createAsyncThunk<
  Types.ExecuteBatchActionResponse,
  Types.ExecuteBatchActionRequest<any>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/executeBatchAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeBatchAction(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
