import React, { useEffect, useRef, useState } from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import saveAs from 'file-saver';
// import { useSelector } from 'react-redux';
import ModalConfirmExport from 'components/Modal/ConfirmExportFile';
import { Checkbox, Select } from 'antd';
// import { authSelector } from 'containers/Auth/selectors';
import { DatePicker, SelectField } from 'components';
import SkillCheckInformationStyled from './styles';
import TableSkillCheckInformation, { SkillCheckInformationDataCustom } from './Table';
// import { useAppDispatch } from 'hooks';
import Chart from './Chart';
import { useAppDispatch } from 'hooks';
import { getDataSkillCheckInformation } from '../thunk';
import { ConditionsType, SkillCheckInformationData } from 'types';
import { getMonthsBetweenDates } from '../OfficialCurriculumInformation';
import {
  setListMonths,
  setValueSkillCheckInformation,
  setVisibleCorrespondingSaleSlice,
} from '../slice';
import {
  convertToFormattedDate,
  convertToFormattedDateForField,
} from '../OfficialCurriculumInformation/Table';
import SkillCheckInformationDocument from './PDF';
import { sortBy, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { reportSelector } from '../selectors';
import { pdf } from '@react-pdf/renderer';
import PDFComponent from './PDFChart';
import { exportPDFFitOnePage } from 'libs/utils/exportPDF';
import { exportCsv } from 'libs/utils/exportCsv';

interface SearchConditions {
  aggregation_method: string;
  target_month: string[];
  user_type: string;
  start_period: null;
  end_period: null;
}

interface Props {
  openConfirmExport: boolean;
  setOpenConfirmExport: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;
const { RangePicker } = DatePicker;
const SkillCheckInformation = ({ openConfirmExport, setOpenConfirmExport }: Props) => {
  const [visibleCorrespondingSale, setVisibleCorrespondingSale] = useState<boolean>(false);
  const [segmented, setSegmented] = useState<number>(0);
  const [formValue, setFormValue] = useState({
    aggregation_method: '',
    target_month: ['', ''],
    user_type: '',
    start_period: null,
    end_period: null,
  });
  const { listMonths, dataSkillCheckInformation } = useSelector(reportSelector);
  const dispatch = useAppDispatch();
  const [selectedDates, setSelectedDates] = useState<any>([]);
  const ref = useRef(null);

  const fetch = async (values: SearchConditions) => {
    const conditions: ConditionsType[] = [];
    Object.keys(values).forEach((key) => {
      if (key !== 'end_period' && key !== 'start_period') {
        let search_value: any = values[key as keyof typeof values];
        if (search_value) {
          switch (key) {
            case 'target_month':
              if (values?.start_period && values?.end_period) {
                search_value = getMonthsBetweenDates(values?.start_period, values?.end_period);
              }
              break;
            default:
              break;
          }
          conditions.push({
            id: key,
            search_value: key === 'target_month' ? search_value : [search_value],
            exact_match: true,
          });
        }
      }
    });

    const payload = {
      conditions,
      use_display_id: true,
      return_number_value: true,
      page: 1,
      per_page: 0,
    };

    if (values?.start_period && values?.end_period) {
      const array = getMonthsBetweenDates(values?.start_period, values?.end_period).map((item) => {
        return {
          date: convertToFormattedDateForField(item),
          title: convertToFormattedDate(item),
        };
      });
      dispatch(setListMonths(array));
    }

    dispatch(getDataSkillCheckInformation(payload));
  };

  const formik = useFormik({
    initialValues: {
      aggregation_method: '',
      target_month: ['', ''],
      user_type: '',
      start_period: null,
      end_period: null,
    },
    onSubmit: (values) => {
      setFormValue(values);

      const newValues = {
        ...values,
        user_type:
          values.user_type === 'ALL' || values.user_type === 'ALL ' ? '' : values.user_type,
      };

      if (values.aggregation_method) {
        fetch(newValues);
      } else {
        dispatch(
          setValueSkillCheckInformation({
            items: [],
            totalItems: 0,
          })
        );
        dispatch(setListMonths([]));
      }
    },
  });

  const handleExportCSV = async (type: string) => {
    if (segmented) {
      exportPDFFitOnePage(ref, 'レポート（スキルチェック実施情報）.pdf', 'l', true);
      setOpenConfirmExport(false);
    } else {
      const listDynamicDate = sortBy(
        uniqBy(
          listMonths.map((item, index) => ({
            title: item.title,
            dataIndex: item.date,
            key: item.date,
            width: '6%',
            render: (text: string) => {
              return <span>{text ?? 0}</span>;
            },
            onCell: () => ({
              className: 'text-right',
            }),
            onHeaderCell: () => ({
              className: 'text-center',
            }),
          })),
          'key'
        ),
        (i) => {
          return parseFloat(i.dataIndex);
        }
      );

      let header = [
        {
          label: 'ユーザー種類',
          key: 'user_type',
        },
        ...listDynamicDate.map((item) => {
          return {
            label: item.title,
            key: item.dataIndex,
          };
        }),
      ];

      if (visibleCorrespondingSale) {
        header = [
          {
            label: 'ユーザー種類',
            key: 'user_type',
          },

          {
            label: '項目名',
            key: 'show_text',
          },
          ...listDynamicDate.map((item) => {
            return {
              label: item.title,
              key: item.dataIndex,
            };
          }),
        ];
      }

      const keyOfHeader = header.map((item) => item.key);

      const groupArray = dataSkillCheckInformation.reduce(
        (acc: any, item) => {
          switch (item.user_type) {
            case '社内ユーザー': {
              return {
                ...acc,
                社内ユーザー: [
                  ...acc.社内ユーザー,
                  {
                    ...item,
                    [convertToFormattedDateForField(item.target_month)]:
                      item.number_of_skill_checks_performed,
                  },
                ],
              };
            }
            case '面接ユーザー': {
              return {
                ...acc,
                面接ユーザー: [
                  ...acc.面接ユーザー,
                  {
                    ...item,
                    [convertToFormattedDateForField(item.target_month)]:
                      item.number_of_skill_checks_performed,
                  },
                ],
              };
            }
            default: {
              return {
                ...acc,
                全ユーザー: [
                  ...acc.全ユーザー,
                  {
                    ...item,
                    [convertToFormattedDateForField(item.target_month)]:
                      item.number_of_skill_checks_performed,
                  },
                ],
              };
            }
          }
        },
        {
          社内ユーザー: [],
          全ユーザー: [],
          面接ユーザー: [],
        }
      );

      const 社内ユーザー = groupArray.社内ユーザー.reduce(
        (acc: any, item: SkillCheckInformationData) => {
          const dateField = convertToFormattedDateForField(item.target_month);
          const other = acc[`${dateField}`] ?? 0;
          const value = item.number_of_skill_checks_performed + other;
          return {
            ...item,
            ...acc,
            [dateField]: value,
          };
        },
        {}
      );

      const 面接ユーザー = groupArray.面接ユーザー.reduce(
        (acc: any, item: SkillCheckInformationData) => {
          const dateField = convertToFormattedDateForField(item.target_month);
          const other = acc[`${dateField}`] ?? 0;
          const value = item.number_of_skill_checks_performed + other;
          return {
            ...item,
            ...acc,
            [dateField]: value,
          };
        },
        {}
      );

      const 全ユーザー = groupArray.全ユーザー.reduce(
        (acc: any, item: SkillCheckInformationData) => {
          const dateField = convertToFormattedDateForField(item.target_month);
          const other = acc[`${dateField}`] ?? 0;
          const value = item.number_of_skill_checks_performed + other;
          return {
            ...item,
            ...acc,
            [dateField]: value,
          };
        },
        {}
      );

      let FirstCase: any = [];
      if (groupArray.全ユーザー.length) {
        FirstCase = [...FirstCase, 全ユーザー];
      }
      if (groupArray.社内ユーザー.length) {
        FirstCase = [...FirstCase, 社内ユーザー];
      }
      if (groupArray.面接ユーザー.length) {
        FirstCase = [...FirstCase, 面接ユーザー];
      }

      if (visibleCorrespondingSale) {
        const sortArray = sortBy(dataSkillCheckInformation, ['rev_no']);

        const 社内ユーザーSecondPhase = groupArray.社内ユーザー.reduce(
          (acc: any, item: SkillCheckInformationData) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const other = acc[`${dateField}`] ?? 0;
            const value = item.skill_check_sales + other;
            return {
              ...item,
              ...acc,
              [dateField]: value,
            };
          },
          {}
        );

        const 全ユーザーSecondPhase = groupArray.全ユーザー.reduce(
          (acc: any, item: SkillCheckInformationData) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const other = acc[`${dateField}`] ?? 0;
            const value = item.skill_check_sales + other;
            return {
              ...item,
              ...acc,
              [dateField]: value,
            };
          },
          {}
        );

        const 面接ユーザーSecondPhase = groupArray.面接ユーザー.reduce(
          (acc: any, item: SkillCheckInformationData) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const other = acc[`${dateField}`] ?? 0;
            const value = item.skill_check_sales + other;
            return {
              ...item,
              ...acc,
              [dateField]: value,
            };
          },
          {}
        );

        const second_case_internal_user = sortArray.find((i) => {
          return i.user_type === '社内ユーザー';
        });

        let second_case_internal_user_result: SkillCheckInformationDataCustom;

        let secoundCase: any = [];

        const internalUserId = second_case_internal_user ? second_case_internal_user.i_id : null;
        if (internalUserId && second_case_internal_user) {
          const children = {
            ...社内ユーザー,
            show_text: '実施回数',
            user_type: type === 'csv' ? 社内ユーザー.user_type : '',
          };

          second_case_internal_user_result = {
            ...社内ユーザーSecondPhase,
            show_text: '売上',
          };

          secoundCase = [...secoundCase, second_case_internal_user_result, children];
        }

        const second_case_user = sortArray.find((i) => {
          return i.user_type === '全ユーザー';
        });

        const userId = second_case_user ? second_case_user.i_id : null;
        let second_case_user_result: SkillCheckInformationDataCustom;
        if (userId && second_case_user) {
          const children = {
            ...全ユーザー,
            show_text: '実施回数',
            user_type: type === 'csv' ? 全ユーザー.user_type : '',
          };

          second_case_user_result = {
            ...全ユーザーSecondPhase,
            show_text: '売上',
          };

          secoundCase = [...secoundCase, second_case_user_result, children];
        }

        const second_case_interviewer_user = sortArray.find((i) => {
          return i.user_type === '面接ユーザー';
        });

        const interviewerUserId = second_case_interviewer_user
          ? second_case_interviewer_user.i_id
          : null;
        let second_case_interview_user_result: SkillCheckInformationDataCustom;

        if (interviewerUserId && second_case_interviewer_user) {
          const children = {
            ...面接ユーザー,
            show_text: '実施回数',
            user_type: type === 'csv' ? 面接ユーザー.user_type : '',
          };

          second_case_interview_user_result = {
            ...面接ユーザーSecondPhase,
            show_text: '売上',
          };

          secoundCase = [...secoundCase, second_case_interview_user_result, children];
        }

        const result = secoundCase.map((item: any) => {
          return keyOfHeader.map((key: string) => {
            return item[key] || '-';
          });
        });

        if (type === 'csv') {
          // const csvString = [
          // header.map(({ label }) => `\u200B${label}`),
          // ...result.map((item: any) => Object.values(item)),
          // ]
          //   .map((e) => e.join(','))
          //   .join('\n');
          // const bom = '\uFEFF';
          // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
          // saveAs(file, 'スキルチェック実施情報_表.csv');
          exportCsv(
            result,
            header.map((item) => {
              return {
                ...item,
                label: `${item.label}`,
              };
            }),
            'スキルチェック実施情報_表.csv'
          );

          setOpenConfirmExport(false);
          return;
        } else {
          const blob = await pdf(
            <SkillCheckInformationDocument
              formValue={formValue}
              visibleCorrespondingSale={visibleCorrespondingSale}
              header={header}
              result={result}
            />
          ).toBlob();
          saveAs(blob, 'スキルチェック実施情報.pdf');
          setOpenConfirmExport(false);
          return;
        }
      }
      const result = FirstCase.map((item: any) => {
        return keyOfHeader.map((key: string) => {
          if (type === 'csv') {
            return item[key] ? item[key] : '-';
          } else {
            return item[key] ? item[key] : '-';
          }
        });
      });

      if (type === 'csv') {
        // const csvString = [
        //   header.map(({ label }) => `\u200B${label}`),
        //   ...result.map((item: any) => Object.values(item)),
        // ]
        //   .map((e) => e.join(','))
        //   .join('\n');
        // const bom = '\uFEFF';
        // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        // saveAs(file, 'スキルチェック実施情報_表.csv');
        exportCsv(
          result,
          header.map((item) => {
            return {
              ...item,
              label: `${item.label}`,
            };
          }),
          'スキルチェック実施情報_表.csv'
        );
      } else {
        const blob = await pdf(
          <SkillCheckInformationDocument
            formValue={formValue}
            visibleCorrespondingSale={visibleCorrespondingSale}
            header={header}
            result={result}
          />
        ).toBlob();
        saveAs(blob, 'スキルチェック実施情報.pdf');
      }
    }
    setOpenConfirmExport(false);
  };

  useEffect(() => {
    return () => {
      dispatch(setVisibleCorrespondingSaleSlice(false));
    };
  }, [dispatch]);

  const onChange = (e: CheckboxChangeEvent) => {
    setVisibleCorrespondingSale(e.target.checked);
    dispatch(setVisibleCorrespondingSaleSlice(e.target.checked));
  };
  return (
    <SkillCheckInformationStyled>
      <div
        ref={ref}
        style={{
          position: 'absolute',
          width: 1512,
          right: 9999,
        }}
      >
        <PDFComponent
          formik={formik}
          segmented={segmented}
          openConfirmExport={openConfirmExport}
          selectedDates={selectedDates}
          visibleCorrespondingSale={visibleCorrespondingSale}
        />
      </div>
      <div className="container">
        <div>
          <div className="wrap-filter">
            <span className="label">集計条件</span>
            <div className="aggregation-conditions">
              <FormikProvider value={formik}>
                <Form layout="vertical">
                  <div className="form-search">
                    <div className="form-search-search form-search">
                      <Form.Item
                        name="aggregation_method"
                        className="item"
                        requiredMark
                        label={
                          <span className="text-label">
                            集計方法選択 <span className="required">*</span>
                          </span>
                        }
                      >
                        <SelectField
                          name="aggregation_method"
                          showSearch
                          allowClear
                          placeholder="指定なし"
                          filterOption={(input, option) =>
                            JSON.stringify(option?.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value={'スキルチェック実施回数【単月推移】'}>
                            スキルチェック実施回数【単月推移】
                          </Option>
                          <Option value={'スキルチェック実施回数【累計推移】'}>
                            スキルチェック実施回数【累計推移】
                          </Option>
                        </SelectField>
                      </Form.Item>
                      <Form.Item
                        name="target_month"
                        className="item"
                        label={
                          <span className="text-label">
                            集計期間 <span className="required">*</span>
                          </span>
                        }
                      >
                        <RangePicker
                          value={selectedDates}
                          allowClear
                          className="date"
                          name="target_month"
                          picker="month"
                          format="YYYY/MM"
                          placeholder={['集計開始日', '集計終了日']}
                          onChange={(dates) => {
                            formik.setFieldValue('target_month', dates);
                            if (dates) {
                              formik.setFieldValue('start_period', dates[0]?.toDate());
                              formik.setFieldValue('end_period', dates[1]?.toDate());
                              setSelectedDates(dates);
                            } else {
                              formik.setFieldValue('start_period', '');
                              formik.setFieldValue('end_period', '');
                            }
                          }}
                          getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                        />
                      </Form.Item>
                      <Form.Item
                        name="user_type"
                        className="item"
                        requiredMark
                        label={<span className="text-label">ユーザー種類選択</span>}
                      >
                        <SelectField
                          name="user_type"
                          showSearch
                          allowClear
                          placeholder="指定なし"
                          filterOption={(input, option) =>
                            JSON.stringify(option?.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="ALL"> ALL</Option>
                          <Option value="65598568baeaf8d6328c9150"> 全ユーザー</Option>
                          <Option value="65598568baeaf8d6328c9151"> 社内ユーザー</Option>
                          <Option value="65598568baeaf8d6328c9152"> 面接ユーザー</Option>
                        </SelectField>
                      </Form.Item>
                    </div>
                    <div>
                      <div className="group-btn">
                        <span
                          className="label-reset"
                          onClick={() => {
                            formik.resetForm();
                            setSelectedDates([]);
                            dispatch(
                              setValueSkillCheckInformation({
                                items: [],
                                totalItems: 0,
                              })
                            );
                          }}
                        >
                          リセット
                        </span>
                        <SubmitButton className="btn-search" loading={false}>
                          表示
                        </SubmitButton>
                      </div>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
          <div className="sub-container">
            <div className="wrap-segmented">
              <div className="left-side">
                <span className="label">レポートタイプ：</span>
                <div className="segmented">
                  <div
                    className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                    onClick={() => {
                      setSegmented(0);
                    }}
                  >
                    <TableOutlined className="icon" />
                    <span>表</span>
                  </div>
                  <div
                    className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                    onClick={() => {
                      setSegmented(1);
                    }}
                  >
                    <BarChartOutlined className="icon" />
                    <span>グラフ</span>
                  </div>
                </div>
                <div className="item-checkbox">
                  <Checkbox checked={visibleCorrespondingSale} onChange={onChange}>
                    対応売上表示
                  </Checkbox>
                </div>
              </div>
            </div>
            {segmented === 0 ? (
              <TableSkillCheckInformation visibleCorrespondingSale={visibleCorrespondingSale} />
            ) : (
              <Chart
                exportPdf={openConfirmExport}
                visibleCorrespondingSale={visibleCorrespondingSale}
              />
            )}
          </div>
        </div>
      </div>

      <ModalConfirmExport
        visible={openConfirmExport}
        setVisible={setOpenConfirmExport}
        onSubmit={handleExportCSV}
      />
    </SkillCheckInformationStyled>
  );
};
export default SkillCheckInformation;
