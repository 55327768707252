import styled from 'styled-components';

export default styled.section`
  .ant-input-show-count-suffix {
    position: absolute;
    right: -5px;
    top: 44px;
  }
  .ant-input {
    margin-left: 5px;
  }
  .guide {
    text-align: center;
    color: #424242;
  }
  .wrap-current-email {
    background: #ffffff;
    padding: 0 8px 17px;
    border: 1px solid #eeeeee;
    .label {
      font-size: 11px;
      color: #999999;
    }
    .current-email {
      text-align: center;
      font-size: 16px;
      color: #2a2a2a;
    }
  }
  .label {
    font-size: 13px;
  }
  .require {
    color: red;
    font-size: 18px;
  }
  .form {
    .ant-col.ant-form-item-label {
      background-color: white !important;
    }
    .ant-form-item {
      margin-bottom: 20px;
      .ant-input-affix-wrapper {
        margin: 0;
      }
      .ant-form-item-explain {
        min-height: 0;
      }
    }
    .input {
      background: #f5f5f5;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      margin-bottom: 24px;
      &.ant-input-affix-wrapper-focused {
        background: #fff;
        input {
          background: #fff;
          :focus {
            background: #fff;
          }
          :-webkit-autofill:focus,
          :-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #fff inset !important;
          }
        }
      }
      input {
        background: #f5f5f5;
        ::placeholder {
          color: #cccccc;
          font-size: 13px;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
        }
      }
    }
    .ant-input-affix-wrapper-status-error {
      input {
        :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #fffaf9 inset !important;
        }
      }
    }
    .checkbox {
      font-size: 12px;
      color: #888888;
      margin-top: 8px;
      margin-left: 10px;
      .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        border: 1px solid #d9d9d9;
        border-radius: 1px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        border-color: #2666bf;
      }
    }
    .ant-input-prefix {
      margin-right: 8px;
    }
    .icon,
    .anticon {
      color: #bbbbbb;
      font-size: 18px;
    }
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-weight: bold;
      border: none;
      margin-top: -10px;
      width: 360px;
      height: 48px;
      &:not([disabled]):hover {
        background: #f8b824;
      }
      &[disabled] {
        background: #eee;
        color: #bbb;
      }
    }
  }
`;
