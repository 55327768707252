import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .item-img {
    width: 100%;
    height: 75%;
    object-fit: contain;
  }
  .item-audio {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
  }
  audio {
    width: 85%;
    background-color: transparent;
  }
  audio::-webkit-media-controls-panel {
    background-color: transparent;
  }

  audio::-webkit-media-controls-enclosure {
    background-color: transparent;
  }

  audio::-webkit-media-controls-play-button,
  audio::-webkit-media-controls-volume-slider {
    background-color: transparent;
  }
`;
