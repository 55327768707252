export const HEADER_IMPORT_CURRICULUM_CSV = [
  { label: '同一カリキュラムフラグ', key: 'same_curriculum_flag', required: true },
  { label: 'カリキュラム名称', key: 'curriculum_name', required: true },
  { label: 'カリキュラム種別', key: 'curriculum_type', required: true },
  { label: '第1階層名称', key: 'level1_name' },
  { label: '第2階層名称', key: 'level2_name' },
  { label: '第3階層名称', key: 'level3_name' },
  { label: '第4階層名称', key: 'level4_name' },
  { label: '設問コード', key: 'question_code' },
  { label: '説明', key: 'curriculum_description' },
];

export const HEADER_CURRICULUM_CSV = [
  { label: '同一カリキュラムフラグ', key: 'flag' },
  { label: 'カリキュラム名称', key: 'curriculum_name' },
  { label: 'カリキュラムコード', key: 'curriculum_code' },
  { label: 'カリキュラム種別', key: 'curriculum_type' },
  { label: '公開ステータス', key: 'curriculum_status' },

  { label: '第1階層名称', key: 'level1_name' },
  { label: '第1階層コード', key: 'level1_code' },
  { label: '第2階層名称', key: 'level2_name' },
  { label: '第2階層コード', key: 'level2_code' },
  { label: '第3階層名称', key: 'level3_name' },
  { label: '第3階層コード', key: 'level3_code' },
  { label: '第4階層名称', key: 'level4_name' },
  { label: '第4階層コード', key: 'level4_code' },

  { label: '設問コード', key: 'question_code' },
  { label: '設問名称', key: 'question_name' },

  { label: '説明', key: 'description' },
];

export const HEADER_JOB_TITLE_MASTER_CSV = [
  { label: 'コード', key: 'code' },
  { label: '役職名称', key: 'name' },
];
export const HEADER_SKILL_CHECK_MASTER_CSV = [
  { label: 'company_id', key: 'company_id' },
  { label: 'createdby', key: 'createdby' },
  { label: 'description', key: 'description' },
  { label: 'end_period', key: 'end_period' },
  { label: 'grouping_code', key: 'grouping_code' },
  { label: 'implementation_settings', key: 'implementation_settings' },
  { label: 'problems4', key: 'problems4' },
  { label: 'publish', key: 'publish' },
  { label: 'question_count', key: 'question_count' },
  { label: 'question_time_limit', key: 'question_time_limit' },
  { label: 'skill_check_code', key: 'skill_check_code' },
  { label: 'skill_check_code_assign', key: 'skill_check_code_assign' },
  { label: 'skill_check_name', key: 'skill_check_name' },
  { label: 'start_period', key: 'start_period' },
  { label: 'user_type', key: 'user_type' },
];

export const HEADER_CURRICULUM_MASTER_TABLE_CSV = [
  { label: 'ID', key: 'official_curriculum_code' },
  { label: 'カリキュラム', key: 'curriculum_name' },
  { label: '公開ステータス', key: 'publish' },
  { label: 'ID', key: 'provider_id' },
  { label: 'パートナー', key: 'provider_name' },
  { label: '契約状況', key: 'contract_status' },
  { label: '利用企業数', key: 'num_of_companies' },
  { label: '利用ユーザー数', key: 'num_of_user' },
  { label: '前回公開日時', key: 'publish_start_date' },
  { label: '最終更新日時', key: 'updatedat' },
];

export const HEADER_PARTNER_MANAGEMENT_CSV = [
  { label: '契約状況', key: 'name' },
  { label: 'ID', key: 'provider_id' },
  { label: 'パートナー名', key: 'provider_name' },
  { label: '契約開始日', key: 'contract_start_date' },
  { label: '契約終了日（予定）', key: 'contract_end_date' },
  { label: 'カリキュラム制作数', key: 'produced' },
  { label: '所属', key: 'admin_department' },
  { label: '役職', key: 'admin_position' },
  { label: '管理者氏名', key: 'admin_name' },
  { label: '最終ログイン', key: 'last_login_date' },
];

export const HEADER_AFFILIATION_MASTER_CSV = [
  { label: 'affiliation_id', key: 'affiliation_id' },
  { label: 'affiliation_parent_id', key: 'affiliation_parent_id' },
  { label: 'code', key: 'code' },
  { label: 'company_id', key: 'company_id' },
  { label: 'level', key: 'level' },
  { label: 'login_id', key: 'login_id' },
  { label: 'name', key: 'name' },
  { label: 'sort_order', key: 'sort_order' },
  { label: 'user_icon_fileID', key: 'user_icon_fileID' },
  { label: 'user_name', key: 'user_name' },
  { label: 'user_sort_order', key: 'user_sort_order' },
];

export const HEADER_AUTHORITY_MASTER_CSV = [
  { label: '権限コード', key: 'code' },
  { label: '会社ID', key: 'company_id' },
  { label: '権限名', key: 'name' },
  { label: '管理者ダッシュボード権限', key: 'admin_dashboard_permission' },
  { label: '部署マスタ権限', key: 'departments_master_permission' },
  { label: 'ユーザーマスタ権限', key: 'users_master_permission' },
  { label: '管理者マスタ権限', key: 'admin_master_permission' },
  { label: 'カリキュラムマスタ権限', key: 'curricullum_master_permission' },
  { label: '分析グループマスタ権限', key: 'groups_master_permission' },
  { label: 'スキルチェックマスタ権限', key: 'skill_check_master_permission' },
  { label: 'バンドルマスタ権限', key: 'bundle_master_permission' },
  { label: 'レポート閲覧権限', key: 'report_view_permission' },
  { label: '権限マスタ権限', key: 'roles_master_permission' },
  { label: '役職マスタ権限', key: 'positions_master_permission' },
  { label: '支払いマスタ権限', key: 'payment_master_permission' },
  { label: 'タイマーマスタ権限', key: 'timer_master_permission' },
  { label: '設問マスタ権限', key: 'question_master_permission' },
  { label: 'スキルチェックツリー権限', key: 'skill_check_tree_permission' },
  { label: '面接ユーザーマスタ権限', key: 'inter_users_master_permission' },
  { label: '更新者', key: 'updatedby' },
  { label: '登録者', key: 'createdby' },
  { label: '登録日', key: 'createdat' },
  { label: '更新日', key: 'updatedat' },
  { label: '削除日', key: 'deletedat' },
];

export const HEADER_EMPLOYEE_CSV = [
  { label: '社員番号', key: 'employee_code' },
  { label: 'ユーザ名', key: 'name' },
  { label: 'ユーザ名（カナ）', key: 'kana' },
  { label: 'メールアドレス', key: 'email' },
  { label: 'パスワード', key: 'password' },
  { label: '所属ID', key: 'department_name' },
  { label: '役職コード', key: 'position_code' },
  { label: '誕生日', key: 'birthday' },
  { label: '入社日', key: 'hire_date' },
  { label: '権限コード', key: 'role_code' },
  { label: 'インポート用権限コード', key: 'i_id_role_code' },
];

export const HEADER_EMPLOYEE_CSV_V2 = [
  { label: '社員番号', key: 'employee_code' },
  { label: '氏名', key: 'name' },
  { label: '氏名（フリガナ）', key: 'kana' },
  { label: '所属コード', key: 'affiliation_code' },
  { label: '所属名称', key: 'affiliation_name' },
  { label: '役職コード', key: 'position_code' },
  { label: '役職名称', key: 'position_name' },
  { label: '年齢', key: 'age' },
  { label: '入社年月', key: 'hire_date' },
  { label: '権限コード', key: 'authority_code' },
  { label: '権限名称', key: 'authority_name' },
  { label: 'メールアドレス', key: 'email' },
  { label: '最終ログイン', key: 'last_login_date' },
];

export const HEADER_INTERVIEW_CSV = [
  {
    label: '管理番号',
    key: 'manage_code',
  },
  {
    label: '氏名',
    key: 'name',
  },
  {
    label: 'フリガナ',
    key: 'kana',
  },
  {
    label: '募集所属',
    key: 'department_name',
  },
  {
    label: '募集役職',
    key: 'position_code',
  },
  {
    label: '年齢',
    key: 'birthday',
  },
  {
    label: '職歴',
    key: 'work_history',
  },
  {
    label: '採用予定年月',
    key: 'hire_date',
  },
  {
    label: 'メールアドレス',
    key: 'email',
  },
  {
    label: 'スキルチェック',
    key: 'implementation_date',
  },
];

export const HEADER_QUESTION_MASTER_CSV = [
  { label: '設問ID', key: 'i_id' },
  { label: '設問名称', key: 'name' },
  { label: '設問内容', key: 'description' },
  { label: '設問画像・動画ID①', key: 'attach_fileID1' },
  { label: '設問画像・動画ID②', key: 'attach_fileID2' },
  { label: '設問画像・動画ID③', key: 'attach_fileID3' },
  { label: '選択肢A', key: 'problems1' },
  { label: '選択肢B', key: 'problems2' },
  { label: '選択肢C', key: 'problems3' },
  { label: '選択肢A画像・動画ID', key: 'problems1_attach_fileID' },
  { label: '選択肢B画像・動画ID', key: 'problems2_attach_fileID' },
  { label: '選択肢C画像・動画ID', key: 'problems3_attach_fileID' },
  { label: '解答', key: 'answer' },
  { label: '解説', key: 'comment' },
  { label: '解説画像・動画ID①', key: 'comment_attach_fileID1' },
  { label: '解説画像・動画ID②', key: 'comment_attach_fileID2' },
  { label: '解説画像・動画ID③', key: 'comment_attach_fileID3' },
  { label: '制限時間', key: 'time_limit' },
  { label: 'スコア（設問難易度）', key: 'score' },
  { label: '制作者', key: 'createdby' },
];

export const HEADER_COMPANY_USER_CSV = [
  { label: '契約状況', key: 'contract_status' },
  { label: '法人ID', key: 'company_id' },
  { label: '法人名', key: 'company_name' },
  { label: '請求方法', key: 'payment_method_name' },
  { label: 'ユーザー数', key: 'month_end_users' },
  { label: 'スキルチェック数', key: 'skillcheck_trans_nums' },
  { label: '所属コード', key: 'admin_department_code' },
  { label: '所属名称', key: 'admin_department' },
  { label: '役職コード', key: 'admin_position_code' },
  { label: '役職名称', key: 'admin_position' },
  { label: '管理者氏名', key: 'admin_name' },

  { label: '最終ログイン', key: 'admin_last_login_at' },
  { label: '請求書特例', key: 'invoice_exception' },
  { label: '未払いアラート', key: 'unpaid_alert_flg' },
  { label: '利用停止', key: 'suspension_flg' },
  { label: '強制退会', key: 'forced_withdrawal_flg' },
];

export const HEADER_INVOICE_CORRESPONDENCE_LIST_CSV = [
  { label: '法人ID', key: 'company_id' },
  { label: '法人名', key: 'company_name' },
  { label: 'ご担当者氏名', key: 'manager_name' },
  { label: 'メールアドレス', key: 'email' },
  { label: 'アカウント登録状況', key: 'account_registration_status' },
  { label: 'URL発行日', key: 'url_issue_date' },
  { label: 'memo', key: 'memo' },
];

export const HEADER_OFFICIAL_CURRICULUM_CSV = [
  { label: '同一カリキュラムフラグ', key: 'i_id' },
  { label: 'パートナーID', key: 'partner_code' },
  { label: 'パートナー', key: 'partner_name' },
  { label: 'カリキュラムコード', key: 'curriculum_code' },
  { label: 'カリキュラム名称', key: 'curriculum_name' },
  { label: '公開ステータス', key: 'curriculum_status' },
  { label: '第1階層コード', key: 'level1_code' },
  { label: '第1階層名称', key: 'level1_name' },
  { label: '第2階層コード', key: 'level2_code' },
  { label: '第2階層名称', key: 'level2_name' },
  { label: '第3階層コード', key: 'level3_code' },
  { label: '第3階層名称', key: 'level3_name' },
  { label: '第4階層コード', key: 'level4_code' },
  { label: '第4階層名称', key: 'level4_name' },
  { label: '設問コード', key: 'question_code' },
  { label: '設問名称', key: 'question_name' },
  { label: '説明', key: 'curriculum_description' },
];

export const HEADER_ADMIN_USER_EXPORT_CSV = [
  { label: 'i_id', key: 'i_id' },
  { label: 'employee_code', key: 'employee_code' },
  { label: 'name', key: 'name' },
  { label: 'kana', key: 'kana' },
  { label: 'email', key: 'email' },
  { label: 'password', key: 'password' },
  { label: 'department_name', key: 'department_name' },
  { label: 'position_code', key: 'position_code' },
  { label: 'birthday', key: 'birthday' },
  { label: 'hire_date', key: 'hire_date' },
  { label: 'role_code', key: 'role_code' },
];

export const HEADER_ADMIN_AFFILIATION_HIERARCHY_EXPORT_CSV = [
  { label: 'コード', key: 'affiliation_id' },
  { label: '所属名称', key: 'name' },
];

export const HEADER_ADMIN_ROLES_EXPORT_CSV = [
  { label: '権限コード', key: 'code' },
  { label: '権限名称', key: 'name' },
  { label: 'ダッシュボード', key: 'admin_dashboard_permission' },
  { label: '売上情報', key: 'sales_report_permission' },
  { label: 'ユーザー情報', key: 'user_report_permission' },
  { label: 'OFFICIALカリキュラム情報', key: 'official_curriculum_report_permission' },
  { label: 'スキルチェック実施情報', key: 'skill_check_implementation_report_permission' },
  { label: '請求データ管理', key: 'billing_management_permission' },
  { label: 'OFFICIALカリキュラム公開管理', key: 'official_curriculum_publish_permission' },
  { label: 'カリキュラムマスタ', key: 'curricullum_master_permission' },
  { label: '設問マスタ', key: 'question_master_permission' },
  { label: 'ユーザーマスタ', key: 'users_master_permission' },
  { label: '権限マスタ', key: 'roles_master_permission' },
  { label: '所属マスタ', key: 'departments_master_permission' },
  { label: '役職マスタ', key: 'positions_master_permission' },
  { label: '管理者マスタ', key: 'admin_master_permission' },
  { label: 'ストレージ管理', key: 'admin_storage_permission' },
  { label: 'パートナー管理', key: 'partner_management_permission' },
  { label: 'カリキュラム一覧・ツリー', key: 'curriculum_tree_permission' },
  { label: '法人ユーザー情報一覧', key: 'corporate_user_permission' },
  { label: '請求書対応', key: 'invoice_correspondence_permission' },
  { label: '個人ユーザー管理', key: 'individual_user_management_permission' },
];

export const HEADER_OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS_EXPORT_CSV = [
  { label: 'ID', key: 'i_id' },
  { label: 'OFFICIALカリキュラム', key: 'official_curriculum_name' },
  { label: '公開ステータス', key: 'publish_status' },
  { label: '前回公開日時', key: 'last_publish_date' },
  { label: '最終更新日時', key: 'last_modified' },
  { label: '作成者', key: 'author' },
];

export const HEADER_BILLING_DATA_ERROR = [
  { label: '役務提供年月', key: 'provided_date' },
  { label: '請求データ番号', key: 'billing_id' },
  { label: '請求データ名称', key: 'billing_data_name' },
  { label: 'オーダーID', key: 'order_id' },
  { label: '会員ID', key: 'user_id' },
  { label: 'ユーザーID', key: 'user_id' },
  { label: 'ユーザー名', key: 'user_name' },
  { label: '請求種類', key: 'billing_type' },
  { label: '決済結果', key: 'payment_method' },
  { label: '請求日', key: 'billing_date' },
  { label: 'アカウント数', key: 'num_of_accounts' },
  { label: '金額', key: 'amount' },
  { label: 'エラーコード', key: 'error_code' },
  { label: 'エラー詳細', key: 'error_title' },
  { label: 'GMO再送信', key: 'gmo_resend' },
];

export const HEADER_BILLING_DATA_ERROR_CORPORATE = [
  {
    label: '役務提供年月',
    key: 'provided_date',
  },
  {
    label: '請求データ番号',
    key: 'billing_id',
  },
  {
    label: '請求データ名称',
    key: 'billing_data_name',
  },
  {
    label: 'オーダーID',
    key: 'order_id',
  },
  {
    label: '会員ID',
    key: 'company_id',
  },
  {
    label: 'ユーザーID',
    key: 'user_id',
  },
  {
    label: 'ユーザー名',
    key: 'company_name',
  },
  {
    label: '請求種類',
    key: 'billing_type',
  },
  {
    label: '決済結果',
    key: 'error_code',
  },
  {
    label: '請求日',
    key: 'billing_date',
  },
  {
    label: 'アカウント数',
    key: 'num_of_accounts',
  },
  {
    label: '金額',
    key: 'amount',
  },
];

export const HEADER_BILLING_DATA_ERROR_CORPORATE_GMO = [
  {
    label: '役務提供年月',
    key: 'provided_date',
  },
  { label: 'ショップID', key: 'ID' },
  { label: 'オーダーID', key: 'order_id' },
  { label: '処理区分', key: 'CAPTURE' },
  { label: '商品コード', key: '商品コード' },
  { label: '利用金額', key: 'amount' },
  { label: '税送料', key: 'tax' },
  { label: '支払方法', key: '支払方法' }, // 1
  { label: '支払回数', key: '支払回数' }, // 1
  { label: 'カード種別', key: 'カード種別' },
  { label: '会員ID', key: 'company_id' },
  { label: 'カード登録連番', key: 'カード登録連番' },
  { label: '予備', key: '予備' },
  { label: '加盟店自由項目1', key: '加盟店自由項目1' },
  { label: '加盟店自由項目2', key: '加盟店自由項目2' },
  { label: '加盟店自由項目3', key: '加盟店自由項目3' },
  { label: '取引ID', key: '取引ID' },
  { label: '取引パスワード', key: '取引パスワード' },
  { label: 'トランザクションID', key: 'トランザクションID' },
  { label: '承認番号', key: '承認番号' },
  { label: '仕向先コード', key: '仕向先コード' },
  { label: 'エラーコード', key: 'error_code' },
  { label: 'エラー詳細コード', key: 'error_title' },
  { label: '処理日時', key: '処理日時' },
];

export const HEADER_BILLING_DATA_ERROR_CORPORATE_DETAIL = [
  { label: '役務提供年月', key: 'provided_date' },
  { label: '請求データ番号', key: 'billing_id' },
  { label: '請求データ名称', key: 'billing_data_name' },
  { label: 'オーダーID', key: 'order_id' },
  { label: '請求明細ID', key: 'billing_detail_id' },
  { label: 'ユーザーID', key: 'user_id' },
  { label: 'ユーザー名', key: 'user_name' },
  { label: '請求種類', key: 'billing_type' },
  { label: '会員ID', key: 'company_id' },
  { label: 'オーダー年月日', key: 'order_date' },
  { label: '決済結果', key: 'payment_method' },
  { label: '請求日', key: 'billing_date' },
  { label: 'アカウント数', key: 'num_of_accounts' },
  { label: '金額', key: 'amount' },
  { label: 'エラーコード', key: 'error_code' },
  { label: 'エラー詳細', key: 'error_title' },
  { label: 'GMO再送信', key: 'gmo_resend' },
];

export const HEADER_BILLING_DATA_DETAIL = [
  { label: '役務提供年月', key: 'provided_date' },
  { label: '請求データ番号', key: 'billing_detail_id' },
  { label: '請求データ名称', key: 'billing_data_name' },
  { label: 'オーダーID', key: 'order_id' },
  { label: '会員ID', key: 'user_id' },
  { label: 'ユーザーID', key: 'company_id' },
  { label: 'ユーザー名', key: 'user_name' },
  { label: '請求種類', key: 'billing_type' },
  { label: '決済結果', key: 'payment_result' },
  { label: '請求日', key: 'billing_date' },
  { label: 'アカウント数', key: 'num_of_accounts' },
  { label: '金額', key: 'amount' },
  { label: 'エラーコード', key: 'error_code' },
  { label: 'エラー詳細', key: 'error_title' },
];

export const HEADER_BILLING_DATA_DETAIL_CSV = [
  { label: '役務提供年月', key: 'provided_date' },
  { label: '請求データ番号', key: 'billing_detail_id' },
  { label: '請求データ名称', key: 'billing_data_name' },
  { label: 'ユーザーID', key: 'company_id' },
  { label: 'ユーザー名', key: 'company_name' },
  { label: '決済手段', key: 'payment_method' },
  { label: '請求日', key: 'billing_date' },
  { label: 'アカウント数', key: 'num_of_accounts' },
  { label: '金額', key: 'amount' },
];

export const HEADER_BILLING_DATA_CSV = [
  { label: '役務提供年月', key: 'provided_date' },
  { label: '請求データ番号', key: 'billing_id' },
  { label: '名称', key: 'billing_data_name' },
  { label: 'ユーザー種類', key: 'user_type' },
  { label: '決済手段', key: 'payment_method' },
  { label: '請求種類', key: 'billing_type' },
  { label: '請求月', key: 'billing_month' },
  { label: '請求件数', key: 'num_of_claim' },
  { label: '請求金額', key: 'num_of_billings' },
  { label: 'エラー件数', key: 'num_of_errors' },
  { label: '再送信', key: 'resend' },
];

export const HEADER_ARCHIVE_DATA_CSV = [
  { label: 'ID', key: 'id' },
  { label: 'OFFICIALカリキュラム', key: 'official_curriculum_name' },
  { label: '公開ステータス', key: 'publish' },
  { label: '前回公開日時', key: 'publish_start_date' },
  { label: '最終更新日時', key: 'updatedat' },
  { label: '作成者', key: 'createdby' },
];

export const HEADER_AGGREGATION_CHURN_RATE = [
  { label: 'i_id', key: 'i_id' },
  { label: 'target_month', key: 'target_month' },
  { label: 'number_of_members_beginning_of_month', key: 'number_of_members_beginning_of_month' },
  { label: 'number_of_members_end_of_month', key: 'number_of_members_end_of_month' },
  { label: 'churn_rate', key: 'churn_rate' },
  { label: 'number_of_monthly_enrollments', key: 'number_of_monthly_enrollments' },
  { label: 'number_of_monthly_withdrawals', key: 'number_of_monthly_withdrawals' },
  {
    label: 'active_number_of_members_beginning_of_month',
    key: 'active_number_of_members_beginning_of_month',
  },
  { label: 'active_number_of_members_end_of_month', key: 'active_number_of_members_end_of_month' },
  { label: 'active_churn_rate', key: 'active_churn_rate' },
  { label: 'active_number_of_monthly_enrollments', key: 'active_number_of_monthly_enrollments' },
  { label: 'active_number_of_monthly_withdrawals', key: 'active_number_of_monthly_withdrawals' },
  { label: 'aggregate_user_type', key: 'aggregate_user_type' },
];

export const HEADER_INDIVIDUAL_USER_MANAGEMENT = [
  { label: 'ID', key: 'id' },
  { label: '氏名', key: 'user_name' },
  { label: 'フリガナ', key: 'kana' },
  { label: 'メールアドレス', key: 'email' },
  { label: '契約状況', key: 'status' },
  { label: '初回登録日', key: 'createdat' },
  { label: '継続期間', key: 'period' },
  { label: '解約日', key: 'deletedat' },
  { label: 'OS', key: 'os' },
  { label: 'memo', key: 'comment' },
];

export const HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT = [
  { label: 'No.', key: 'index' },
  { label: 'OFFICIALカリキュラム公開日時（更新日時）', key: 'publish_start_date' },
  { label: 'バージョン', key: 'version' },
  { label: '公開種類', key: 'publish_type' },
  { label: 'リリースノート作成状況', key: 'release_note_id' },
  { label: 'リリースノートタイトル', key: 'release_note_title' },
  { label: 'リリースノート作成・更新日時', key: 'updateat' },
  { label: '作成者', key: 'createdby' },
  { label: 'リリースノート公開ステータス', key: 'release_note_publish_status' },
];

export const HEADER_RELEASE_NOTE_DETAIL = [
  { label: 'i_id', key: 'i_id' },
  { label: 'description', key: 'description' },
  { label: 'official_curriculum_code', key: 'official_curriculum_code' },
  { label: 'release_note_id', key: 'release_note_id' },
  { label: 'release_note_version', key: 'release_note_version' },
  { label: 'release_note_title', key: 'release_note_title' },
  { label: 'section_name', key: 'section_name' },
  { label: 'section_text', key: 'section_text' },
];

export const HEADER_IMPORT_QUESTION_MASTER_CSV = [
  { label: '設問名称', key: 'name', required: true },
  { label: '設問内容', key: 'description', required: true },
  { label: '選択肢A', key: 'problems1', required: true },
  { label: '選択肢B', key: 'problems2', required: true },
  { label: '選択肢C', key: 'problems3', required: true },
  { label: '解答', key: 'answer', required: true },
  { label: '解説', key: 'comment', required: true },
  { label: '制限時間', key: 'time_limit' },
  { label: 'スコア（設問難易度）', key: 'score' },
];

export const HEADER_IMPORT_EMPLOYEE_CSV = [
  { label: '社員番号', key: 'employee_code', required: true },
  { label: '氏名', key: 'name', required: true },
  { label: '氏名（フリガナ）', key: 'name_furigana', required: true },
  { label: '所属コード', key: 'affiliation_id', required: true },
  { label: '役職コード', key: 'position_code', required: true },
  { label: '生年月日', key: 'birthday' },
  { label: '入社年月', key: 'hire_date' },
  { label: '権限コード', key: 'role_code', required: true },
  { label: 'メールアドレス', key: 'email', required: true },
  { label: 'パスワード', key: 'password', required: true },
];

export const HEADER_EXPORT_FREE_TRIAL_MANAGEMENT_CSV = [
  { label: 'ステータス', key: 'company_status' },
  { label: '法人ID', key: 'id' },
  { label: '法人名', key: 'name' },
  { label: '残日数', key: 'remaining_days' },
  { label: '開始日時', key: 'trial_start_date' },
  { label: '終了日時', key: 'trial_end_date' },
  { label: '契約状況', key: 'contract_status' },
  { label: 'データ保持日数', key: 'data_retention_days' },
  { label: '所属', key: 'admin_department' },
  { label: '役職', key: 'admin_position' },
  { label: '管理者氏名', key: 'admin_name' },
];
