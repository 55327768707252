import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { MailOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';

import { authSelector } from 'containers/Auth/selectors';
import { ModalContent } from 'components/Header/styles';
import { changeEmailSchema } from 'libs/validations';
import { changeEmail } from '../Auth/thunk';
import { Input, Modal } from 'components';
import { useAppDispatch } from 'hooks';
import { config } from 'configs';
import * as Types from 'types';
import Styled from './styles';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setEmailChanged: React.Dispatch<React.SetStateAction<string>>;
  setVisiblelComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeEmail: React.FC<Props> = ({
  visible,
  setVisible,
  setEmailChanged,
  setVisiblelComplete,
}) => {
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();

  const formik = useFormik<Types.ChangeEmailFormik>({
    initialValues: {
      email: '',
      confirm_email: '',
    },
    validationSchema: changeEmailSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        changeEmail({
          email: values.email,
          registration_path: '/changed-email',
          email_templates_id: config.TEMPLATES_ID,
        })
      );
      if (changeEmail.fulfilled.match(resultAction) && !resultAction.payload.error) {
        setEmailChanged(values.email);
        setVisiblelComplete(true);
        setVisible(false);
      }
    },
  });

  const handleToggleChangeEmail = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="メールアドレス変更"
      visible={visible}
      width={720}
      onCancel={handleToggleChangeEmail}
      okButton={{
        text: '送信する',
        onClick: formik.handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => handleToggleChangeEmail(),
      }}
    >
      <ModalContent>
        <Styled>
          <FormikProvider value={formik}>
            <p className="guide">
              アカウント情報を変更します。
              <br />
              変更するメールアドレスを入力し、送信してください。
            </p>
            <div className="wrap-current-email">
              <span className="label">現在のメールアドレス：</span>
              <p className="current-email">{userInfo?.email}</p>
            </div>
            <Form className="form" layout="vertical">
              <Form.Item
                name="email"
                label={
                  <div>
                    <span className="label">新しいメールアドレス</span>
                    <span className="require">*</span>
                  </div>
                }
              >
                <Input
                  name="email"
                  showCount
                  className="input"
                  maxLength={254}
                  prefix={
                    <MailOutlined
                      style={{
                        color: '#bbbbbb',
                        fontSize: 20,
                      }}
                    />
                  }
                  placeholder="新しいメールアドレス"
                />
              </Form.Item>
              <Form.Item
                name="confirm_email"
                label={
                  <div>
                    <span className="label">新しいメールアドレス（確認）</span>
                    <span className="require">*</span>
                  </div>
                }
              >
                <Input
                  showCount
                  maxLength={254}
                  name="confirm_email"
                  className="input"
                  prefix={
                    <MailOutlined
                      style={{
                        color: '#bbbbbb',
                        fontSize: 20,
                      }}
                    />
                  }
                  placeholder="確認のため新しいメールアドレスを再度入力"
                />
              </Form.Item>
            </Form>
          </FormikProvider>
        </Styled>
      </ModalContent>
    </Modal>
  );
};

export default ChangeEmail;
