import React, { useEffect, useMemo, useRef, useState } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Image as ImageAntd, message, Upload } from 'antd';
import { includes, isEqual, remove, slice } from 'lodash';
import ReactPlayer from 'react-player';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';

import { UploadFileFieldType } from 'types/services/curriculum';
import { extractFileName } from 'libs/utils/format';
import { FileType } from 'constant/enum.constant';
import { SectionStyled } from './styles';
import { DetailAudio, Modal } from 'components';
import { BackgroundAudio, OriginLogoAttach, TrainingCurriculum } from 'assets';
import {
  AUDIO_FILE_EXTENSIONS,
  VALID_MIME_IMAGE_QUESTION,
  VALID_MIME_TYPE_AUDIO_QUESTION,
  VALID_MIME_TYPE_QUESTION,
  VALID_MIME_TYPE_VIDEO_QUESTION,
  VIDEO_FILE_EXTENSIONS,
} from 'constant';

interface Props {
  visible: {
    show: boolean;
    data: Array<UploadFile<File>>;
    dataProblem: Array<{
      content: string;
      indexProblem: number;
      file: UploadFile<File>;
      fileID: string;
    }>;
    dataName: Array<string>;
    type: UploadFileFieldType;
    fistIndex?: number;
  };
  setVisible: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      data: Array<UploadFile<File>>;
      dataProblem: Array<{
        content: string;
        indexProblem: number;
        file: UploadFile<File>;
        fileID: string;
      }>;
      dataName: Array<string>;
      type: UploadFileFieldType;
      fistIndex?: number;
    }>
  >;
  onSubmit: (
    data: Array<UploadFile<File> | undefined>,
    fieldType: UploadFileFieldType,
    dataProblem: Array<{
      content: string;
      indexProblem: number;
      file: UploadFile<File> | undefined;
      fileID: string;
    }>
  ) => void;
}

const typeVisibleArr = [
  'attach',
  'problems',
  'comment_attach',
  'problems1_attach',
  'problems2_attach',
  'problems3_attach',
];
const { Dragger } = Upload;

const UploadImageVideoQuestion: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [fileOrigin, setFileOrigin] = useState<Array<UploadFile<File>>>([]);
  const [files, setFiles] = useState<Array<UploadFile<File>>>([]);
  const [isShowImage, setIsShowImage] = useState<boolean>(false);
  const [fileName, setFileName] = useState<Array<string>>([]);
  const [index, setIndex] = useState<number>(0);
  const [filesProblem, setFilesProblem] = useState<
    Array<{
      content: string;
      indexProblem: number;
      file: UploadFile<File>;
      fileID: string;
      mimeType?: string;
    }>
  >([]);

  const vidRef = useRef<HTMLVideoElement>(null);

  const handleClose = () => {
    setVisible({ ...visible, show: false });
    vidRef.current?.pause();
  };

  const handleDelete = () => {
    const filterFilesProblem = filesProblem.filter((item, i) => item.indexProblem - 1 !== index);

    setFilesProblem(filterFilesProblem);
    setFiles([...filterFilesProblem].map((item) => item.file));
    setFileName([...filterFilesProblem].map((item) => item.fileID));
  };

  const handleSubmit = () => {
    handleClose();
    onSubmit(files, visible.type, filesProblem);
  };

  const onChange = async (info: UploadChangeParam<UploadFile<File>>) => {
    const fileNumber = !files[index] && info.fileList.length > index + 1 ? 3 - index : 1;
    const lastThreeFiles =
      info.fileList.length > fileNumber ? slice(info.fileList, -fileNumber) : info.fileList;

    let fileList: Array<UploadFile<File>> = [...files];
    const fileProblemList: Array<{
      content: string;
      indexProblem: number;
      file: UploadFile<File>;
      fileID: string;
    }> = [];
    const currentContentList = ['problems1_attach', 'problems2_attach', 'problems3_attach'];
    let currentContentIndex = 0;

    for (const element of lastThreeFiles) {
      const file = element;
      const validFileType = VALID_MIME_TYPE_QUESTION.includes(file.originFileObj?.type || '');

      const isAccept = await new Promise<boolean>((resolve) => {
        if (!file.originFileObj) return resolve(false);
        const src = URL.createObjectURL(new Blob([file.originFileObj]));

        if (VALID_MIME_IMAGE_QUESTION.includes(file.originFileObj.type)) {
          const image = new Image();
          image.src = src;
          image.onload = function () {
            resolve(true);
            URL.revokeObjectURL(src);
          };

          image.onerror = function () {
            console.error('Failed to load the image');
            resolve(true);
          };
        } else if (VALID_MIME_TYPE_AUDIO_QUESTION.includes(file.originFileObj.type)) {
          const audio = document.createElement('audio');
          audio.onloadedmetadata = () => {
            URL.revokeObjectURL(src);
            resolve(true);
          };
          audio.src = src;
          audio.load();
        } else {
          const video = document.createElement('video');
          video.onloadedmetadata = () => {
            URL.revokeObjectURL(src);
            resolve(true);
          };
          video.src = src;
          video.load();
        }
      });

      if (!validFileType || !isAccept) {
        fileList.push(file);
      } else {
        const currentContent = currentContentList[currentContentIndex];
        currentContentIndex++;

        if (!files[index] && fileList.length < 3) {
          fileList.push(file);
        } else if (fileList.length >= 3) {
          fileList[index] = file;
        } else {
          fileList[index] = file;
        }

        if (lastThreeFiles.length === 3 || lastThreeFiles.length === 2) {
          fileProblemList.push({
            content: currentContent,
            file: file,
            indexProblem: currentContentIndex,
            fileID: '',
          });
        } else {
          fileProblemList.push({
            content:
              index === 0
                ? 'problems1_attach'
                : index === 1
                ? 'problems2_attach'
                : 'problems3_attach',
            file: file,
            indexProblem: index + 1,
            fileID: '',
          });
        }
      }
    }

    fileList = slice(fileList, 0, 3);

    remove(fileList, (file) => !file);

    setFiles(fileList);

    const updatedFilesProblem = [...filesProblem, ...fileProblemList];
    const uniqueFilesProblemMap = new Map<number, (typeof updatedFilesProblem)[0]>();

    updatedFilesProblem.forEach((fileProblem) => {
      uniqueFilesProblemMap.set(fileProblem.indexProblem, fileProblem);
    });

    const uniqueFilesProblem = Array.from(uniqueFilesProblemMap.values()).sort(
      (a, b) => a.indexProblem - b.indexProblem
    );

    setFilesProblem(uniqueFilesProblem);
  };

  const file_type_video = useMemo(() => {
    const file = filesProblem.find(
      (item) => item.content === `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
    );

    const fileType = file?.file.type || file?.file.originFileObj?.type;
    const fileString = file?.file;

    return (
      (fileType && includes(VALID_MIME_TYPE_VIDEO_QUESTION, fileType)) ||
      (fileString &&
        VIDEO_FILE_EXTENSIONS.some((e) =>
          (typeof fileString === 'string' ? fileString : '').includes(e)
        )) ||
      (file?.mimeType && includes(file.mimeType as unknown as string, 'video'))
    );
  }, [filesProblem, index]);

  const file_type_svg = useMemo(
    () =>
      includes(
        filesProblem.find(
          (item) => item.content === `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
        )?.file?.type as unknown as string,
        'svg+xml'
      ),
    [filesProblem, index]
  );

  const file_type_audio = useMemo(() => {
    const file = filesProblem.find(
      (item) => item.content === `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
    );

    const fileType = file?.file.type || file?.file.originFileObj?.type;
    const fileString = file?.file;
    return (
      (fileType && includes(VALID_MIME_TYPE_AUDIO_QUESTION, fileType)) ||
      (fileString &&
        AUDIO_FILE_EXTENSIONS.some((e) =>
          (typeof fileString === 'string' ? fileString : '')?.includes(e)
        )) ||
      (file?.mimeType && includes(file?.mimeType as unknown as string, 'audio'))
    );
  }, [filesProblem, index]);

  const srcFile = useMemo(() => {
    const originFile = filesProblem
      .filter(
        (item) => item.content === `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
      )
      .find((e) => e.file.originFileObj);

    const file = filesProblem.find(
      (item) => item.content === `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
    )?.file as unknown as string;

    return includes(originFile?.file.type, 'svg+xml')
      ? URL.createObjectURL(originFile?.file.originFileObj as Blob)
      : originFile?.file.type
      ? URL.createObjectURL(new Blob([originFile?.file?.originFileObj as Blob]))
      : file;
  }, [filesProblem, index]);

  useEffect(() => {
    if (visible.show && typeVisibleArr.includes(visible.type)) {
      setFiles(visible.data);
      setFilesProblem(visible.dataProblem);
      setFileName(visible.dataName);
      // setIndex(visible.type === 'problems' ? visible.fistIndex || 0 : 0);
      setFileOrigin(visible.data);
    }
    return () => {
      setFiles([]);
      setFilesProblem([]);
      setFileName([]);
      setIsShowImage(false);
      setFileOrigin([]);
      setIndex(0);
    };
  }, [visible]);

  return (
    <Modal
      title="画像・動画アップロード"
      width={720}
      open={visible.show && typeVisibleArr.includes(visible.type)}
      onCancel={handleClose}
      okButton={{
        text: '登録',
        onClick: handleSubmit,
        disabled: !files.length || isEqual(fileOrigin, files),
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <div className="form-upload">
          <>
            <LeftOutlined
              className="icon-prev"
              onClick={() => {
                setIndex((prevState) => {
                  if (prevState === 1) {
                    return 0;
                  } else if (prevState === 2) {
                    return 1;
                  } else {
                    return prevState + 2;
                  }
                });
                vidRef.current?.pause();
              }}
            />
            <RightOutlined
              className="icon-next"
              onClick={() => {
                setIndex((prevState) => {
                  if (prevState === 0) {
                    return 1;
                  } else if (prevState === 1) {
                    return 2;
                  } else {
                    return prevState - 2;
                  }
                });
                vidRef.current?.pause();
              }}
            />
          </>
          <div className="file-upload">
            {files[0] && (
              <span className="label-question">
                {visible.type === 'problems'
                  ? `選択肢${index === 0 ? 'A' : index === 1 ? 'B' : 'C'}`
                  : `画像・動画${index + 1}`}
              </span>
            )}

            <div className="wrap-drag">
              <Dragger
                name="file"
                multiple
                beforeUpload={(file) => {
                  if (!VALID_MIME_TYPE_QUESTION.includes(file?.type || '')) {
                    message.open({
                      type: 'error',
                      content: 'このファイル形式は対応しておりません。',
                      duration: 3,
                      style: {
                        color: 'red',
                      },
                    });
                  }
                  return false;
                }}
                onChange={onChange}
                fileList={files}
              >
                {filesProblem.find(
                  (item) =>
                    item.content === `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                ) ? (
                  <div className="info-file" onClick={(e) => e.stopPropagation()}>
                    <p className="name-image">
                      {filesProblem
                        .filter(
                          (item) =>
                            item.content ===
                            `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                        )
                        .find((e) => e.file.originFileObj)
                        ? filesProblem
                            .filter(
                              (item) =>
                                item.content ===
                                `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                            )
                            .find((e) => e.file.originFileObj)?.file.name
                        : extractFileName(
                            filesProblem.find(
                              (item) =>
                                item.content ===
                                `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                            )?.fileID
                          )}
                    </p>
                    {file_type_video ? (
                      <ReactPlayer
                        key={index}
                        width={'100%'}
                        height={'330px'}
                        url={srcFile}
                        controls
                        config={{
                          file: {
                            attributes: {
                              disablePictureInPicture: true,
                              controlsList: 'nodownload noplaybackrate',
                            },
                          },
                        }}
                      />
                    ) : file_type_audio ? (
                      <DetailAudio url={srcFile} />
                    ) : file_type_svg ? (
                      <object className="svg-object" type="image/svg+xml" data={srcFile}></object>
                    ) : (
                      <ImageAntd
                        onClick={(e) => e.stopPropagation()}
                        className="image-file"
                        src={srcFile}
                        alt={
                          filesProblem.find(
                            (item) =>
                              item.content ===
                              `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                          )?.file?.name
                        }
                        preview={{
                          visible: isShowImage,
                          src: srcFile,
                          onVisibleChange: (value) => {
                            setIsShowImage(value);
                          },
                          mask: (
                            <ZoomInOutlined
                              className="zoom-image"
                              onClick={() => setIsShowImage(!isShowImage)}
                            />
                          ),
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <CloudUploadOutlined className="icon" />
                    <p className="ant-upload-text">
                      アップロードするファイルをここにドロップ
                      <br />
                      または
                    </p>
                  </>
                )}
                <div className="wrap-button-upload">
                  <button type="button" className="btn-upload">
                    {filesProblem.find(
                      (item) =>
                        item.content ===
                        `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                    )
                      ? 'ファイルを変更'
                      : 'ファイルを選択'}
                  </button>
                  {filesProblem.find(
                    (item) =>
                      item.content ===
                      `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                  ) ? (
                    <button
                      className="btn-delete"
                      type="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete();
                      }}
                    >
                      <DeleteOutlined className="icon-delete-outlined" />
                      <span className="text-delete-outlined">ファイルを削除</span>
                    </button>
                  ) : null}
                </div>
              </Dragger>
            </div>
          </div>
        </div>
        <div className="flex-image-small">
          <div>
            <>
              <div
                className={`image ${index === 0 ? 'image_active' : ''}`}
                onClick={() => setIndex(0)}
              >
                {(() => {
                  const file = filesProblem.find((item) => item.content === 'problems1_attach');
                  const fileType = file?.file?.type || file?.file?.originFileObj?.type;
                  const fileString = file?.file || '';
                  const mimeType = file?.mimeType;

                  const isVideo =
                    includes(VALID_MIME_TYPE_VIDEO_QUESTION, fileType) ||
                    VIDEO_FILE_EXTENSIONS.some((e) =>
                      (typeof fileString === 'string' ? fileString : '').includes(e)
                    ) ||
                    includes(mimeType as unknown as string, 'video');

                  const isAudio =
                    includes(VALID_MIME_TYPE_AUDIO_QUESTION, fileType) ||
                    AUDIO_FILE_EXTENSIONS.some((e) =>
                      (typeof fileString === 'string' ? fileString : '').includes(e)
                    ) ||
                    includes(mimeType as unknown as string, 'audio');

                  return isVideo ? (
                    <ReactPlayer
                      width={'100%'}
                      height={'100%'}
                      url={
                        file?.file?.originFileObj
                          ? URL.createObjectURL(new Blob([file?.file?.originFileObj as Blob]))
                          : file?.file
                          ? (file?.file as unknown as string)
                          : TrainingCurriculum
                      }
                    />
                  ) : isAudio ? (
                    <img
                      className={
                        file?.file?.originFileObj || file?.file ? 'image-question' : 'no-image'
                      }
                      src={file?.file ? BackgroundAudio : TrainingCurriculum}
                      alt=""
                    />
                  ) : includes(file?.file?.originFileObj?.type, 'svg+xml') ? (
                    <object
                      className="svg-object"
                      type="image/svg+xml"
                      data={
                        file?.file?.originFileObj
                          ? URL.createObjectURL(file?.file?.originFileObj as Blob)
                          : TrainingCurriculum
                      }
                    ></object>
                  ) : (
                    <>
                      <img
                        src={
                          file?.file?.originFileObj
                            ? URL.createObjectURL(new Blob([file?.file?.originFileObj as Blob]))
                            : file?.file
                            ? (file?.file as unknown as string)
                            : OriginLogoAttach
                        }
                        className={
                          file?.file?.originFileObj || file?.file ? 'image-question' : 'no-image'
                        }
                        alt={file?.file?.name || ''}
                      />
                    </>
                  );
                })()}
              </div>
              <p className="label">{visible.type === 'problems' ? '選択肢A' : '画像・動画１'}</p>
            </>
          </div>
          <div>
            <div
              className={`image ${index === 1 ? 'image_active' : ''}`}
              onClick={() => setIndex(1)}
            >
              {(() => {
                const file = filesProblem.find((item) => item.content === 'problems2_attach');
                const fileType = file?.file?.type || file?.file?.originFileObj?.type;
                const fileString = file?.file || '';
                const mimeType = file?.mimeType;

                const isVideo =
                  includes(VALID_MIME_TYPE_VIDEO_QUESTION, fileType) ||
                  VIDEO_FILE_EXTENSIONS.some((e) =>
                    (typeof fileString === 'string' ? fileString : '').includes(e)
                  ) ||
                  includes(mimeType as unknown as string, 'video');

                const isAudio =
                  includes(VALID_MIME_TYPE_AUDIO_QUESTION, fileType) ||
                  AUDIO_FILE_EXTENSIONS.some((e) =>
                    (typeof fileString === 'string' ? fileString : '').includes(e)
                  ) ||
                  includes(mimeType as unknown as string, 'audio');

                return isVideo ? (
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={
                      file?.file?.originFileObj
                        ? URL.createObjectURL(new Blob([file?.file?.originFileObj as Blob]))
                        : file?.file
                        ? (file?.file as unknown as string)
                        : TrainingCurriculum
                    }
                  />
                ) : isAudio ? (
                  <img
                    className={
                      file?.file?.originFileObj || file?.file ? 'image-question' : 'no-image'
                    }
                    src={file?.file ? BackgroundAudio : TrainingCurriculum}
                    alt=""
                  />
                ) : includes(file?.file?.originFileObj?.type, 'svg+xml') ? (
                  <object
                    className="svg-object"
                    type="image/svg+xml"
                    data={
                      file?.file?.originFileObj
                        ? URL.createObjectURL(file?.file?.originFileObj as Blob)
                        : TrainingCurriculum
                    }
                  ></object>
                ) : (
                  <>
                    <img
                      src={
                        file?.file?.originFileObj
                          ? URL.createObjectURL(new Blob([file?.file?.originFileObj as Blob]))
                          : file?.file
                          ? (file?.file as unknown as string)
                          : OriginLogoAttach
                      }
                      className={
                        file?.file?.originFileObj || file?.file ? 'image-question' : 'no-image'
                      }
                      alt={file?.file?.name || ''}
                    />
                  </>
                );
              })()}
            </div>
            <p className="label">{visible.type === 'problems' ? '選択肢B' : '画像・動画2'}</p>
          </div>
          <div>
            <div
              className={`image ${index === 2 ? 'image_active' : ''}`}
              onClick={() => setIndex(2)}
            >
              {(() => {
                const file = filesProblem.find((item) => item.content === 'problems3_attach');
                const fileType = file?.file?.type || file?.file?.originFileObj?.type;
                const fileString = file?.file || '';
                const mimeType = file?.mimeType;

                const isVideo =
                  includes(VALID_MIME_TYPE_VIDEO_QUESTION, fileType) ||
                  VIDEO_FILE_EXTENSIONS.some((e) =>
                    (typeof fileString === 'string' ? fileString : '').includes(e)
                  ) ||
                  includes(mimeType as unknown as string, 'video');

                const isAudio =
                  includes(VALID_MIME_TYPE_AUDIO_QUESTION, fileType) ||
                  AUDIO_FILE_EXTENSIONS.some((e) =>
                    (typeof fileString === 'string' ? fileString : '').includes(e)
                  ) ||
                  includes(mimeType as unknown as string, 'audio');

                return isVideo ? (
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={
                      file?.file?.originFileObj
                        ? URL.createObjectURL(new Blob([file?.file?.originFileObj as Blob]))
                        : file?.file
                        ? (file?.file as unknown as string)
                        : TrainingCurriculum
                    }
                  />
                ) : isAudio ? (
                  <img
                    className={
                      file?.file?.originFileObj || file?.file ? 'image-question' : 'no-image'
                    }
                    src={file?.file ? BackgroundAudio : TrainingCurriculum}
                    alt=""
                  />
                ) : includes(file?.file?.originFileObj?.type, 'svg+xml') ? (
                  <object
                    className="svg-object"
                    type="image/svg+xml"
                    data={
                      file?.file?.originFileObj
                        ? URL.createObjectURL(file?.file?.originFileObj as Blob)
                        : TrainingCurriculum
                    }
                  ></object>
                ) : (
                  <>
                    <img
                      src={
                        file?.file?.originFileObj
                          ? URL.createObjectURL(new Blob([file?.file?.originFileObj as Blob]))
                          : file?.file
                          ? (file?.file as unknown as string)
                          : OriginLogoAttach
                      }
                      className={
                        file?.file?.originFileObj || file?.file ? 'image-question' : 'no-image'
                      }
                      alt={file?.file?.name || ''}
                    />
                  </>
                );
              })()}
            </div>
            <p className="label">{visible.type === 'problems' ? '選択肢C' : '画像・動画3'}</p>
          </div>
        </div>

        <p className="text-content">
          ※ファイルはまとめて３つまでアップロード可能です。
          <br />
          ※画像は16:9でjpegまたはpngファイルがアップロードできます。
          <br />
          ※動画は16:9でmp4ファイルがアップロードできます。
          <br />
        </p>
      </SectionStyled>
    </Modal>
  );
};

export default UploadImageVideoQuestion;
