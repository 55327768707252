import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import { Table, Select, Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { pdf } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { unionBy } from 'lodash';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  SearchOutlined,
  RightOutlined,
  FormOutlined,
  MailOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import { HEADER_INVOICE_CORRESPONDENCE_LIST_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { PagingNumber, PerPageSelect, SelectField } from 'components';
import InvoiceCorrespondenceStyled, { PopoverStyled } from './styles';
import { settingSelector } from 'containers/AppSettings/selectors';
import CompletedDeleteModal from './Modal/CompletedDeleteInvoice';
import ModalCreateEditInfoInvoice from './Modal/EditInfoInvoice';
import InvoiceCorrespondenceDocument from './PDFExportDocument';
import ConfirmDeleteModal from './Modal/ConfirmDeleteInvoice';
import { invoiceCorrespondenceSelector } from './selector';
import ResendRegistration from './Modal/ResendRegistration';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { exportCsv } from 'libs/utils/exportCsv';
import HeaderDashboard from 'components/Header';
import { routes } from 'navigations/routes';
import { useAppDispatch, usePermission } from 'hooks';
import { config, DISPLAY_ID_KEY } from 'configs';
import * as Types from 'types';
import {
  createInvoiceCorrespondence,
  executeSendURL,
  getCompany,
  getDataSelect,
  getInvoiceCorrespondence,
  inviteUser,
  postUnauthorizedCall,
  removeInvoiceCorrespondence,
} from './thunk';
import { SELECT_RECORD } from 'constant/select.constants';
import { browserLogger } from 'libs/logger';

const initialValuesFomik = {
  account_registration_status: '',
  company_name: '',
  manager_name: '',
  email: '',
};

const { Option } = Select;

const InvoiceCorrespondence: React.FC = () => {
  const [visibleCompleteEditInvoice, setVisibleCompleteEditInvoice] = useState(false);
  const [selectRecord, setSelectRecord] = useState<Types.InvoiceCorrespondenceType>();
  const [visibleEditInvoiceControl, setVisibleEditInvoiceControl] = useState(false);
  const [visibleModalCompleteDelete, setModalCompleteDelete] = useState(false);
  const [selected, setSelected] = useState<Types.InvoiceCorrespondenceType>();
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleEditInvoice, setVisibleEditInvoice] = useState(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleResend, setVisibleResend] = useState<boolean>(false);
  const [visibleExportFile, setVisibleExportFile] = useState(false);
  const [idSelected, setIdSelected] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(100);
  const [content, setContent] = useState<string>();
  const [width, setWidth] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [formSearchSubmit, setFormSearchSubmit] = useState(initialValuesFomik);

  const { permissionNumber } = usePermission();

  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const { invoiceCorrespondence, dataSelect } = useSelector(invoiceCorrespondenceSelector);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.InvoiceCorrespondenceFormSearchFormik>({
    initialValues: initialValuesFomik,
    onSubmit: (values) => {
      setFormSearchSubmit(values);
      const conditions: Array<Types.ConditionsType> = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [
              values[key as keyof typeof values] === 'other'
                ? '1'
                : values[key as keyof typeof values],
            ],
            ...(values[key as keyof typeof values] !== 'other'
              ? { exact_match: true }
              : { not_match: true }),
          });
        }
      });
      fetchInvoiceCorrespondence(conditions);
      setPage(1);
    },
    onReset: () => {
      setFormSearchSubmit(initialValuesFomik);
      fetchInvoiceCorrespondence([]);
      setPage(1);
    },
  });

  const handleDeleteInvoiceCorrespondence = async (id: string) => {
    if (!id) return;
    await dispatch(
      removeInvoiceCorrespondence({
        id,
      })
    );
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const listCsv = invoiceCorrespondence.map((i) => ({
        ...i,
        account_registration_status: !i.account_registration_status ? '未登録' : '登録済み',
        url_issue_date: formatDateCsv(i.url_issue_date),
      }));

      exportCsv(listCsv, HEADER_INVOICE_CORRESPONDENCE_LIST_CSV, '請求書対応管理.csv');
    } else {
      const { account_registration_status, company_name, manager_name, email } = formSearchSubmit;
      const blob = await pdf(
        <InvoiceCorrespondenceDocument
          dataSource={invoiceCorrespondence}
          account_registration_status={account_registration_status}
          company_name={company_name}
          manager_name={manager_name}
          email={email}
        />
      ).toBlob();
      saveAs(blob, '請求書対応管理.pdf');
    }
    setVisibleExportFile(false);
  };

  const formatDateCsv = (isoString: string) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  const columnsSecond: ColumnsType<Types.InvoiceCorrespondenceType> = [
    {
      title: '法人名',
      dataIndex: 'company_name',
      key: 'company_name',
      className: 'name',
      width: '15%',
    },
    {
      title: 'ご担当者氏名',
      dataIndex: 'manager_name',
      key: 'manager_name',
      width: '10%',
      align: 'left',
    },
    {
      title: <span style={{ marginRight: '50px' }}>メールアドレス</span>,
      dataIndex: 'email',
      key: 'email',
      width: '15%',
      render: (_: string, item: Types.InvoiceCorrespondenceType) => (
        <div
          className="wrap-mail-address"
          onClick={() => {
            window.location.href = `mailto:${item.email}`;
          }}
        >
          <span className="text-mail-address">{item.email}</span>
        </div>
      ),
    },
    {
      title: (
        <span>
          アカウント
          <br />
          登録状況
        </span>
      ),
      dataIndex: 'account_registration_status',
      key: 'account_registration_status',
      width: '7%',
      align: 'center',
      render: (_: string, item: Types.InvoiceCorrespondenceType) => (
        <div className="wrap-contract-status">
          <span
            className={
              Number(item.account_registration_status) === 1 ? 'text-status' : 'text-status-change'
            }
          >
            {Number(item.account_registration_status) === 1 ? '登録済' : '未登録'}
          </span>
        </div>
      ),
    },
    {
      title: <span>URL発行日</span>,
      dataIndex: 'url_issue_date',
      key: 'url_issue_date',
      className: 'issue-date',
      width: '13%',
      align: 'left',
      render: (text: string) => (
        <span className="text-status">{dayjs(text).format('YYYY/MM/DD HH:mm')}</span>
      ),
    },
    {
      title: 'memo',
      dataIndex: 'memo',
      className: 'memo',
      key: 'memo',
      width: '15%',
      render: (text: string) => (
        <div className="wrap-contract-status">
          {text && (
            <div className="wrap-icon-memo">
              <div className="wrap-icon-file">
                <Popover
                  content={
                    <PopoverStyled>
                      <span>{text}</span>
                    </PopoverStyled>
                  }
                  overlayInnerStyle={{
                    maxWidth: 600,
                    overflow: 'hidden',
                    whiteSpace: 'normal',
                    border: 2,
                  }}
                  trigger="click"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                  autoAdjustOverflow={true}
                >
                  <FileTextOutlined className="icon" />
                </Popover>
              </div>
              <span className="text-memo">{text}</span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
      width: '5%',
      align: 'center',
      render: (_: string, record) => (
        <div
          className={permissionNumber === 2 ? 'wrap-icon' : 'wrap-icon-disabled'}
          onClick={() => {
            if (permissionNumber !== 2) return;
            setVisibleEditInvoiceControl(true);
            setContent('更新が完了しました');
            setSelected(record);
          }}
        >
          <FormOutlined className="icon" />
        </div>
      ),
    },
    {
      title: (
        <span>
          URL
          <br />
          再送
        </span>
      ),
      align: 'center',
      dataIndex: 'URLs_resend',
      key: 'URLs_resend',
      width: '5%',
      render: (_: string, record) => (
        <div
          className={permissionNumber === 2 ? 'wrap-icon' : 'wrap-icon-disabled'}
          onClick={() => {
            if (permissionNumber !== 2) return;
            setVisibleResend(true);
            setIdSelected(record?.item_ref?.email?.i_id ?? '');
            setSelectRecord(record);
          }}
        >
          <MailOutlined className="icon" />
        </div>
      ),
    },
    {
      title: '削除',
      dataIndex: 'delete',
      key: 'delete',
      width: '5%',
      align: 'center',
      render: (_: string, record) => (
        <div
          className={permissionNumber === 2 ? 'wrap-icon' : 'wrap-icon-disabled'}
          onClick={() => {
            if (permissionNumber !== 2) return;
            setVisibleModalDelete(!visibleModalDelete);
            setIdSelected(record.item_ref?.email.i_id ?? '');
          }}
        >
          <DeleteOutlined className="icon" />
        </div>
      ),
    },
  ];

  const fetchInvoiceCorrespondence = useCallback(
    async (conditions?: Array<Types.ConditionsType>) => {
      dispatch(startLoading());

      await dispatch(
        getInvoiceCorrespondence({
          conditions,
          page: 1,
          per_page: 0,
          omit_total_items: false,
          include_item_ref: true,
          include_lookups: true,
          use_display_id: true,
        })
      );
      await dispatch(
        getDataSelect({
          conditions: [],
          page: 1,
          per_page: 0,
        })
      );

      dispatch(stopLoading());
    },
    [dispatch]
  );

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const handleResend = async () => {
    if (!selectRecord?.email || !idSelected) return;

    dispatch(startLoading());

    const getCompanyResult = await dispatch(
      getCompany({
        conditions: [
          {
            id: 'admin_email',
            search_value: [selectRecord?.email],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    if (getCompany.fulfilled.match(getCompanyResult) && getCompanyResult.payload.items.length) {
      await dispatch(
        executeSendURL({
          users: [
            {
              email: selectRecord?.email,
              user_code: selectRecord?.email,
              exclusive_w_id: DISPLAY_ID_KEY.exclusive_w_id.name,
            },
          ],
          domain: 'rsweb.hexabase.com',
          invitation_path: 'confirm_email',
          email_templates_id: config.CONFIRM_TEMPLATES_ID,
        })
      );
      browserLogger.info(
        'page/InvoiceCorrespondence/index  getCompanyResult',
        DISPLAY_ID_KEY.exclusive_w_id.name,
        getCompanyResult
      );
    } else {
      throw new Error('Company not found');
    }

    await dispatch(stopLoading());
    setVisibleComplete(true);
  };

  useEffect(() => {
    if (!userInfo) return;

    dispatch(startLoading());

    dispatch(
      getDataSelect({
        conditions: [],
        page: 1,
        per_page: 0,
      })
    );

    dispatch(stopLoading());
  }, [dispatch, userInfo]);

  useEffect(() => {
    fetchInvoiceCorrespondence([]);
  }, [fetchInvoiceCorrespondence]);

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.getBoundingClientRect().width);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerPage(invoiceCorrespondence.length);
    }
  }, [invoiceCorrespondence]);

  return (
    <>
      <HeaderDashboard title={headerTitle} />
      <InvoiceCorrespondenceStyled isEmpty={!invoiceCorrespondence.length} tableWidth={width}>
        <div className="wrap-nav">
          <p className="text-note">
            法人ユーザーで請求方法を請求書送付ご希望の方への専用アカウント登録要URL発行と
            <br />
            発行後の登録状況の管理画面
          </p>
          <div className="wrap-button">
            <button onClick={() => navigate(routes.CorporateUserInformationList.path)}>
              法人ユーザー管理 <RightOutlined className="icon-right-outline" />
            </button>
          </div>
        </div>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical" colon={false}>
            <div className="form-search">
              <Form.Item
                name="account_registration_status"
                className="item"
                label={<span className="text-label">アカウント登録状況</span>}
              >
                <SelectField
                  data-testid="ic-registration-status"
                  name="account_registration_status"
                  showSearch
                  allowClear
                  placeholder="指定なし"
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="1">登録済</Option>
                  <Option value="other">未登録</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="company_name"
                className="item"
                label={<span className="text-label">法人名</span>}
              >
                <SelectField
                  data-testid="ic-company-name"
                  name="company_name"
                  showSearch
                  allowClear
                  placeholder="指定なし"
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(dataSelect, 'company_name')?.map(({ company_name }) => (
                    <Option value={company_name}>{company_name}</Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="manager_name"
                className="item"
                label={<span className="text-label">ご担当者氏名</span>}
              >
                <SelectField
                  data-testid="ic-manager-name"
                  name="manager_name"
                  showSearch
                  allowClear
                  placeholder="指定なし"
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(dataSelect, 'manager_name')?.map(({ manager_name }) => (
                    <Option value={manager_name}>{manager_name}</Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                data-testid="ic-email"
                name="email"
                className="item"
                label={<span className="text-label">メールアドレス</span>}
              >
                <SelectField
                  name="email"
                  showSearch
                  allowClear
                  placeholder="指定なし"
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(dataSelect, 'email')?.map(({ email }) => (
                    <Option value={email}>{email}</Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              {/*<span className="label-reset" onClick={handleResetForm}>*/}
              <span className="label-reset" onClick={formik.handleReset}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-body">
          <div className="wrap-group-button">
            <button
              className="btn-control-number"
              onClick={() => setVisibleExportFile(!visibleExportFile)}
            >
              <span className="text">エクスポート</span>
            </button>
            <button
              className={`btn-control-file ${permissionNumber !== 2 ? 'disabled' : ''}`}
              disabled={permissionNumber !== 2}
              onClick={() => {
                setVisibleEditInvoice(!visibleEditInvoice);
                setContent('URLの送信が完了しました');
              }}
            >
              <span className="text">＋ 請求書対応用URL発行</span>
            </button>
          </div>
          <div className="wrap-table">
            <Table
              tableLayout="fixed"
              ref={ref}
              className="table"
              rowClassName="border-hight-light"
              dataSource={invoiceCorrespondence}
              columns={columnsSecond}
              pagination={{
                pageSize: perPage,
                current: page,
                onChange: setPage,
                showSizeChanger: false,
                position: ['topCenter', 'bottomCenter'],
                showTotal: () => (
                  <div className="wrap-text-count">
                    <PagingNumber
                      startItem={invoiceCorrespondence.length ? `${(page - 1) * perPage + 1}` : ''}
                      endItem={
                        page * perPage > invoiceCorrespondence.length
                          ? invoiceCorrespondence.length
                          : page * perPage
                      }
                      totalItem={invoiceCorrespondence.length}
                    />
                    <PerPageSelect
                      data={invoiceCorrespondence}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </div>
                ),
              }}
              rowKey={(record) => JSON.stringify(record)}
            />
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visibleExportFile}
          setVisible={setVisibleExportFile}
          onSubmit={handleExportCSV}
          title="エクスポートを実行します。"
        />
        <ModalCreateEditInfoInvoice
          type="create"
          visible={visibleEditInvoice}
          setVisible={setVisibleEditInvoice}
          setVisibleComplete={setVisibleCompleteEditInvoice}
          fetchInvoiceCorrespondence={fetchInvoiceCorrespondence}
        />
        <ModalCreateEditInfoInvoice
          type="edit"
          visible={visibleEditInvoiceControl}
          setVisible={setVisibleEditInvoiceControl}
          setVisibleComplete={setVisibleCompleteEditInvoice}
          idSelected={selected?.company_id}
          fetchInvoiceCorrespondence={fetchInvoiceCorrespondence}
        />
        <ConfirmDeleteModal
          visible={visibleModalDelete}
          setVisible={setVisibleModalDelete}
          onSubmit={() => {
            handleDeleteInvoiceCorrespondence(idSelected);
            setModalCompleteDelete(true);
          }}
        />
        <CompletedModal
          title={content || ''}
          setVisible={setVisibleCompleteEditInvoice}
          visible={visibleCompleteEditInvoice}
          onSubmit={() => {
            setVisibleEditInvoiceControl(false);
            setVisibleEditInvoice(false);
            fetchInvoiceCorrespondence();
            formik.resetForm();
          }}
        />
        <CompletedDeleteModal
          visible={visibleModalCompleteDelete}
          setVisible={setModalCompleteDelete}
          onSubmit={() => {
            setModalCompleteDelete(false);
            fetchInvoiceCorrespondence();
            formik.resetForm();
          }}
        />
        <ResendRegistration
          visible={visibleResend}
          setVisible={setVisibleResend}
          onSubmit={handleResend}
        />
        <CompletedModal
          title="再送信が完了しました"
          visible={visibleComplete}
          setVisible={setVisibleComplete}
        />
      </InvoiceCorrespondenceStyled>
    </>
  );
};

export default InvoiceCorrespondence;
