import { Image, Text, View } from '@react-pdf/renderer';
import styles from './styles';
import { NoImageCurriculum, SubtractImg } from 'assets';
import Label from 'components/ReactPDF/Label';
import { IImageThumbnail } from 'types/config';

interface IProps {
  label?: string;
  required?: boolean;
  subLabel?: string;
  thumbnails?: IImageThumbnail[];
}

const AttachPreview = (props: IProps) => {
  const { label, required, subLabel = '設定状況：', thumbnails } = props;
  return (
    <View style={styles.attachmentContainer} wrap={false}>
      <Label label={label} required={required} />
      <View style={styles.valueContainer}>
        <Text style={styles.subLabel}>{subLabel}</Text>
        {thumbnails &&
          thumbnails.length &&
          thumbnails.map((item: IImageThumbnail) => (
            <View style={styles.imageContainer}>
              <Image style={styles.image} src={item.src || NoImageCurriculum} />
              {item.label && <Text style={styles.imageLabel}>{item.label}</Text>}
              {item.isVideo && <Image style={styles.playIcon} src={SubtractImg} />}
            </View>
          ))}
      </View>
    </View>
  );
};

export default AttachPreview;
