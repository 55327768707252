import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Popover, Table, Select, Row, Divider } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { find, unionBy } from 'lodash';
import saveAs from 'file-saver';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  FileTextOutlined,
  FormOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { filterCreator, filterCurriculum, filterQuestion, filterScore } from './slice';
import { getOptionLimit, createQuestion, editQuestion } from 'containers/CreateEditQuestion/thunk';
import { convertAttachFileId, handleCheckExistQuestion } from 'libs/utils/question';
import PDFQuestionMasterTable from 'pages/QuestionMaster/PDFQuestionMasterTable';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { uploadQuestionCsvSchema } from 'libs/validations/questtion';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { CreateQuestion, ModalConfirmDelete, UploadManyImageVideo } from './Modal';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import ActionErrorModal from 'components/Modal/ActionError';
import { deleteFileInR2 } from 'services/r2Service';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import QuestionMasterPDF from './PDF/QuestionMasterPDF';
import { questionMasterSelector } from './selectors';
import { Header, PagingNumber, SelectField } from 'components';
import { exportCsv } from 'libs/utils/exportCsv';
import ImportButton from 'components/Buttons';
import UploadCSV from 'components/UploadCSV';
import QuestionMasterStyled from './styles';
import { useAppDispatch, usePermission } from 'hooks';
import { AnyObject } from 'types';
import { COMPANIES } from 'configs';
import * as Types from 'types';
import {
  HEADER_IMPORT_QUESTION_MASTER_CSV,
  HEADER_QUESTION_MASTER_CSV,
} from 'constant/header.export.constant';
import {
  getSelectCurriculumCreator,
  updateTimeLimitCompany,
  getSelectCurriculum,
  getQuestionDetail,
  getSelectQuestion,
  getSelectTypes,
  getCompany,
  getDataTableQuestion,
  deleteQuestionByConditions,
  getDataAllQuestion,
} from './thunk';
import PerPageSelect from 'components/PerPageSelect';
import { SELECT_RECORD } from 'constant/select.constants';
import QuestionDetail from './Modal/QuestionDetail';
import { browserLogger } from 'libs/logger';

const { Option } = Select;

const DEFAULT_FILTER = {
  curriculum_code: '',
  required_curriculum: '',
  question_name: '',
  creator: '',
  score: '',
  curriculum_name: '',
};

const QuestionMaster: React.FC = () => {
  const [visiblePopupUploadImageVideo, setVisiblePopupUploadImageVideo] = useState<boolean>(false);
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.TreeTraining[]>([]);
  const [visibleDeletePopupError, setVisibleDeletePopupError] = useState<boolean>(false);
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [openNotDelete, setOpenNotDelete] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [statusTime, setStatusTime] = useState<boolean>(false);
  const [hideBoxSearch, setHideBoxSearch] = useState<boolean>(true);
  const [changeQuestion, setchangeQuestion] = useState<boolean>(false);
  const [indexTime, setIndexTime] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [openModalCreateQuestion, setOpenModalCreateQuestion] = useState<{
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });
  const [modalQuestionDetail, setModalQuestionDetail] = useState<{
    visible: boolean;
    original_question_code?: string;
  }>({
    visible: false,
  });

  const ref = useRef(null);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    curriculumCreatorSelect,
    curriculumNameSelect,
    curriculumTypeSelect,
    dataSelectCreator,
    defaultTimeLimit,
    questionSelect,
    dataQuestion,
    scoreSelect,
    timeLimit,
  } = useSelector(questionMasterSelector);
  const { permissionNumber } = usePermission();

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.QuestionFormSearchFormik>({
    initialValues: {
      ...DEFAULT_FILTER,
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      getQuestionCondition(values);
      const resultAction = await dispatch(
        getDataTableQuestion({
          conditions: [
            {
              id: 'provider_id',
              search_value: [userInfo?.company_id],
            },
          ],

          page: 1,
          per_page: 0,
          include_lookups: true,
        })
      );
      if (getDataTableQuestion.fulfilled.match(resultAction)) {
        if (formik.values.curriculum_name) {
          dispatch(filterCurriculum(formik.values.curriculum_name));
        }
        if (formik.values.creator) {
          dispatch(filterCreator(formik.values.creator));
        }
        if (formik.values.question_name) {
          dispatch(filterQuestion(formik.values.question_name));
        }
        if (formik.values.score) {
          dispatch(filterScore(formik.values.score));
        }
      }
      dispatch(stopLoading());
      setPage(1);
    },
    onReset: () => {
      setPage(1);
    },
  });

  const getQuestionCondition = (values: Types.QuestionFormSearchFormik) => {
    const conditions: Types.ConditionsType[] = [];
    Object.keys(values).forEach((key) => {
      const value = values[key as keyof typeof values];
      if (value) {
        conditions.push({
          id: key,
          search_value: [value],
          exact_match: true,
        });
      }
    });
    return conditions;
  };

  const columns: ColumnsType<Types.TreeTraining> = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
      render: (_: string, item: Types.TreeTraining) => <span>{Number(item.index) + 1}</span>,
    },
    {
      title: '設問コード',
      dataIndex: 'question_code',
      key: 'question_code',
      width: '8%',
    },
    {
      title: '設問名',
      dataIndex: 'question_name',
      key: 'question_name',
      width: '15%',
      ellipsis: true,
    },
    {
      title: 'バージョン',
      dataIndex: 'version_number',
      key: 'version_number',
      width: '100px',
      align: 'center',
    },
    {
      title: '元設問詳細',
      dataIndex: 'original_question_code',
      key: 'original_question_code',
      align: 'center',
      width: '100px',
      render: (original_question_code) => (
        <FileTextOutlined
          className="icon"
          onClick={() =>
            setModalQuestionDetail({
              visible: true,
              original_question_code,
            })
          }
        />
      ),
    },
    {
      title: '設問内容抜粋',
      dataIndex: 'question_description',
      key: 'question_description',
      width: '35%',
      ellipsis: true,
      align: 'left',
    },
    {
      title: '製作者',
      dataIndex: 'creator',
      key: 'creator',
      width: '20%',
      ellipsis: true,
      align: 'left',
    },
    {
      title: 'スコア',
      dataIndex: 'score',
      key: 'score',
      width: '7.5%',
    },
    {
      title: '詳細・編集',
      dataIndex: 'operation',
      width: '10%',
      render: (_: string, item: Types.TreeTraining) => (
        <FormOutlined
          className="icon"
          onClick={() =>
            setOpenModalCreateQuestion({
              visible: true,
              type: 'edit',
              question_id: item.question_code_i_id,
            })
          }
        />
      ),
    },
  ];
  const fixedColumn = useMemo(() => {
    return columns.map((col) => ({ ...col, onCell: undefined }));
  }, [columns]);

  const handleSubmitSelectTimeLimit = async (time: number) => {
    dispatch(startLoading());
    const id = find(userInfo?.item_links?.links, ({ d_id }) => d_id === COMPANIES.name)?.items[0]
      ?.i_id;

    const actionResult = await dispatch(
      updateTimeLimitCompany({
        id: id ?? '',
        data: {
          item: {
            time_limit: time,
          },
          is_force_update: true,
        },
      })
    );
    browserLogger.info(
      'page/QuestionMaster/index  handleSubmitSelectTimeLimit',
      COMPANIES.name,
      actionResult
    );

    if (updateTimeLimitCompany.fulfilled.match(actionResult)) {
      setStatusTime(true);
      fetchCompany();
    }
    setTimeout(() => {
      setStatusTime(false);
    }, 1000);
    dispatch(stopLoading());
  };

  const fetchData = async (conditions?: Types.ConditionsType[]) => {
    if (userInfo) {
      const result = await dispatch(
        getDataTableQuestion({
          conditions: [
            {
              id: 'provider_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
        })
      );
      if (getDataTableQuestion.fulfilled.match(result)) {
        if (formik.values.curriculum_name) {
          dispatch(filterCurriculum(formik.values.curriculum_name));
        }
        if (formik.values.creator) {
          dispatch(filterCreator(formik.values.creator));
        }
        if (formik.values.question_name) {
          dispatch(filterQuestion(formik.values.question_name));
        }
        if (formik.values.score) {
          dispatch(filterScore(formik.values.score));
        }
      }
    }
  };

  const fetchQuestion = useCallback(
    async (conditions?: Types.ConditionsType[]) => {
      if (userInfo) {
        dispatch(startLoading());
        await fetchData(conditions);
        dispatch(stopLoading());
      }
    },
    [dispatch, userInfo]
  );

  const fetchCompany = useCallback(async () => {
    if (!userInfo) return;
    dispatch(startLoading());

    await dispatch(
      getCompany({
        conditions: [
          {
            id: 'id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    dispatch(stopLoading());
  }, [dispatch, userInfo]);

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleButtonDelete = () => {
    if (!selectedRow.length) {
      setVisibleDeletePopupError(true);
    } else {
      handleCheckExistQuestion({
        selectedQuestion: selectedRow.map((e) => ({
          i_id: e.i_id,
          name: e.question_name,
          code: e.question_code,
        })),
        dispatch,
        setOpenNotDelete,
        setOpenDeleteQuestion: setVisiblePopupConfirmDelete,
      });
    }
  };

  const prepareConditions = (
    id: 'original_question_code' | 'code',
    items: Types.Questions.ResponseType[] | Types.TreeTraining[]
  ) => {
    const searchValue = items
      .map((item) => item[id])
      .filter(Boolean)
      .join('|');
    return searchValue ? [{ id, search_value: [searchValue] }] : [];
  };

  const handleSubmitDelete = async () => {
    dispatch(startLoading());
    const resultAction = await Promise.all([
      dispatch(
        getQuestionDetail({
          page: 1,
          per_page: 0,
          conditions: prepareConditions('original_question_code', selectedRow),
        })
      ),
      dispatch(
        getQuestionDetail({
          page: 1,
          per_page: 0,
          conditions: prepareConditions('code', selectedRow),
        })
      ),
    ]);
    if (
      getQuestionDetail.fulfilled.match(resultAction[0]) &&
      getQuestionDetail.fulfilled.match(resultAction[1])
    ) {
      const uniqueQuestions = unionBy(
        [...resultAction[0].payload.items, ...resultAction[1].payload.items],
        'code'
      );

      const questionsWithOriginalCode = uniqueQuestions.filter(
        (item) => item.original_question_code
      );
      const questionsWithoutOriginalCode = uniqueQuestions.filter(
        (item) => !item.original_question_code
      );

      const uniqueQuestionsWithOriginalCode = unionBy(
        questionsWithOriginalCode,
        'original_question_code'
      );

      if (uniqueQuestionsWithOriginalCode.length > 0) {
        await dispatch(
          deleteQuestionByConditions({
            conditions: prepareConditions(
              'original_question_code',
              uniqueQuestionsWithOriginalCode
            ),
          })
        );
      }

      if (questionsWithoutOriginalCode.length > 0) {
        await dispatch(
          deleteQuestionByConditions({
            conditions: prepareConditions('code', questionsWithoutOriginalCode),
          })
        );
      }

      await Promise.all(
        uniqueQuestions.map(async (item) => {
          const listFileID: string[] = [
            item.attach_fileID1,
            item.attach_fileID2,
            item.attach_fileID3,
            item.comment_attach_fileID1,
            item.comment_attach_fileID2,
            item.comment_attach_fileID3,
            item.problems1_attach_fileID,
            item.problems2_attach_fileID,
            item.problems3_attach_fileID,
          ];

          await Promise.all(
            listFileID.map((file) => {
              if (file) {
                return deleteFileInR2(file);
              }
              return null;
            })
          );
        })
      );
      setSelectedRow([]);
      setVisiblePopupConfirmDelete(false);
      await fetchQuestion();
      await dispatch(
        getDataAllQuestion({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      await dispatch(stopLoading());
      setDeleteSuccess(true);
      return;
    }
    dispatch(stopLoading());
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const listCsv =
        selectedRow.length > 0
          ? selectedRow.map((item, index) => ({
              i_id: item.question_code,
              name: item.question_name,
              description: item.question_description,
              attach_fileID1: convertAttachFileId(
                item.comment_attach_fileID1,
                item.question_code,
                index + 1,
                'attach1'
              ),
              attach_fileID2: convertAttachFileId(
                item.comment_attach_fileID2,
                item.question_code,
                index + 1,
                'attach2'
              ),
              attach_fileID3: convertAttachFileId(
                item.comment_attach_fileID3,
                item.question_code,
                index + 1,
                'attach3'
              ),
              problems1: item.problems1,
              problems2: item.problems2,
              problems3: item.problems3,
              problems1_attach_fileID: convertAttachFileId(
                item.problems1_attach_fileID,
                item.question_code,
                index + 1,
                'problems1_attach'
              ),
              problems2_attach_fileID: convertAttachFileId(
                item.problems2_attach_fileID,
                item.question_code,
                index + 1,
                'problems2_attach'
              ),
              problems3_attach_fileID: convertAttachFileId(
                item.problems3_attach_fileID,
                item.question_code,
                index + 1,
                'problems3_attach'
              ),
              answer: item.answer ? item.answer : item.question_answer,
              comment: item.comment ? item.comment : item.question_comment,
              comment_attach_fileID1: convertAttachFileId(
                item.comment_attach_fileID1,
                item.question_code,
                index + 1,
                'comment_attach1'
              ),
              comment_attach_fileID2: convertAttachFileId(
                item.comment_attach_fileID2,
                item.question_code,
                index + 1,
                'comment_attach2'
              ),
              comment_attach_fileID3: convertAttachFileId(
                item.comment_attach_fileID3,
                item.question_code,
                index + 1,
                'comment_attach3'
              ),
              time_limit: item.question_time_limit
                ? item.question_time_limit.replace('秒', '')
                : item.time_limit
                ? item.time_limit.replace('秒', '')
                : '',
              score: item.score + '',
              createdby: item.creator,
            }))
          : [];
      exportCsv(listCsv, HEADER_QUESTION_MASTER_CSV, '設問マスタ.csv');
    } else {
      const filters = formik.values;
      const timeLimitText = timeLimit[indexTime]?.value;
      const curriculumName = filters.curriculum_name || 'ALL';
      const requiredCurriculum = filters.required_curriculum
        ? ['カリキュラム', '必修カリキュラム'][Number(`${filters.required_curriculum}`)]
        : '';
      const questionName = filters.question_name || '';
      const blob = await pdf(
        <PDFQuestionMasterTable
          dataQuestion={selectedRow}
          statusTime={statusTime}
          timeLimit={timeLimitText}
          total={dataTable.length}
          curriculum_name={curriculumName}
          required_curriculum={requiredCurriculum}
          createdby={filters.creator}
          question_name={questionName}
          score={filters.score}
          page={page}
          limit={perPage}
        />
      ).toBlob();
      saveAs(blob, '設問マスタ.pdf');
    }
    dispatch(stopLoading());
    setVisiblePopupConfirmExportFile(false);
  };

  const handleCreate = () => setOpenModalCreateQuestion({ visible: true, type: 'create' });

  const handleImportData = (dataImport: AnyObject[]) => {
    return dataImport.map((record) => ({
      name: record.name,
      description: record.description,
      problems1: record.problems1,
      problems2: record.problems2,
      problems3: record.problems3,
      answer: record.answer,
      comment: record.comment,
      time_limit:
        timeLimit.find(
          (v: Types.TimeLimitType) =>
            v.value === record.time_limit || v.value === `${record.time_limit}秒`
        )?.option_id || '',
      creator: userInfo?.name,
      company_id: userInfo?.company_id,
      score: Number(record.score),
      createdat: new Date(),
      createdby: userInfo?.login_id,
    }));
  };

  const handleSubmitImportCsv = async (record: AnyObject) => {
    const resultAction = await dispatch(
      createQuestion({
        item: {
          ...record,
          name: record.name,
          description: record.description,
          problems1: record.problems1,
          problems2: record.problems2,
          problems3: record.problems3,
          answer: record.answer,
          comment: record.comment,
          time_limit: record.time_limit,
          creator: userInfo?.name,
          company_id: userInfo?.company_id,
          score: Number(record.score),
          createdat: new Date(),
          createdby: userInfo?.login_id,
          version_number: Number(1),
        },
        return_item_result: true,
        access_key_updates: {
          roles_to_publish: ['ADMIN', 'MEMBER'],
        },
      })
    );
    if (createQuestion.fulfilled.match(resultAction)) {
      const createdItem = resultAction.payload.item;
      await dispatch(
        editQuestion({
          id: resultAction.payload.item_id,
          data: {
            item: {
              original_question_code: createdItem.code,
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );

      return resultAction.payload.item_id;
    }
    return null;
  };

  const onImportSuccess = async () => {
    await fetchQuestion(getQuestionCondition(formik.values));
  };

  const handleUploadCsvImageVideo = () => setVisiblePopupUploadImageVideo(true);
  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const components = useMemo(() => {
    return (
      <div ref={ref} className="component">
        <QuestionMasterPDF
          curriculumCreatorSelect={curriculumCreatorSelect}
          curriculumNameSelect={curriculumNameSelect}
          curriculumTypeSelect={curriculumTypeSelect}
          dataQuestion={selectedRow}
          indexTime={indexTime}
          questionSelect={questionSelect}
          scoreSelect={scoreSelect}
          statusTime={statusTime}
          timeLimit={timeLimit}
          page={page}
          perPage={perPage}
        />
      </div>
    );
  }, [
    curriculumCreatorSelect,
    curriculumNameSelect,
    curriculumTypeSelect,
    selectedRow,
    indexTime,
    questionSelect,
    scoreSelect,
    statusTime,
    timeLimit,
    page,
    perPage,
  ]);

  const dataTable = useMemo(() => {
    const result = unionBy(dataQuestion, 'question_code');
    return result
      .map((item, index) => ({
        ...item,
        index: index,
      }))
      .sort((a, b) => Number(b.question_code) - Number(a.question_code));
  }, [dataQuestion]);

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getSelectCurriculum({
            conditions: [
              {
                id: 'provider_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectTypes({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectCurriculumCreator({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'createdby',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(getOptionLimit()),
      ]);
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    setIndexTime(defaultTimeLimit);
  }, [defaultTimeLimit]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  useEffect(() => {
    fetchQuestion();
  }, [fetchQuestion]);
  useEffect(() => {
    formik.submitForm();
  }, [changeQuestion]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerPage(dataTable.length);
    }
  }, [dataTable]);

  return (
    <QuestionMasterStyled collapsedMenu={collapsedMenu} isEmptyData={!dataTable?.length}>
      {components}
      <Header title={headerTitle} />
      <div className="container">
        {hideBoxSearch && (
          <>
            <div className="header-container">
              <p className="title">
                設問の作成・編集、作成した設問の一覧を確認できます。
                <br />
                設問に設定するデフォルトの制限時間も設定可能です。
              </p>
              {!statusTime ? (
                <div className="select-time-limit">
                  <div className="label">
                    <span>制限時間の初期値</span>
                    <Popover
                      trigger={['click']}
                      placement="bottomRight"
                      title={
                        <p style={{ color: '#2A2A2A', fontSize: 16 }}>設問制限時間の初期値設定</p>
                      }
                      content={
                        <p style={{ color: '#424242', fontSize: 13, padding: '0 12px' }}>
                          設問制限時間の初期値設定とは、設問ごとに設定する制限時間の初期値（デフォルト値）を設定することです。
                          <br />
                          設問新規作成時に、設定した制限時間が初期表示されます。
                          <br />
                          あくまでも初期値ですので、設問ごとに制限時間は変更可能です。
                          <br />
                        </p>
                      }
                      color="#ffffff"
                    >
                      <QuestionCircleOutlined className="question-mark-icon" />
                    </Popover>
                    ：
                  </div>
                  <Select
                    className="select-limit"
                    showSearch
                    value={indexTime}
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children)
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(e) => setIndexTime(e)}
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  >
                    {timeLimit.map((e, index) => (
                      <Option key={index} value={index}>
                        {e.value}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    className="btn-select-submit"
                    onClick={() => handleSubmitSelectTimeLimit(indexTime)}
                  >
                    設定
                  </Button>
                </div>
              ) : (
                <div className="wrap-notify-successful">
                  <CheckCircleOutlined className="icon-successful" />
                  設定した時間を初期値として登録しました
                </div>
              )}
            </div>
            <div className="border-line" />
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    name="curriculum_name"
                    className="curriculum_name"
                    label={<span className="text-label">カリキュラム名</span>}
                  >
                    <SelectField
                      placeholder="指定なし"
                      name="curriculum_name"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          ?.toLowerCase()
                          ?.indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {curriculumNameSelect?.map(({ official_curriculum_name }, index) => (
                        <Option key={index} value={official_curriculum_name}>
                          {official_curriculum_name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="creator"
                    className="createdby"
                    label={<span className="text-label">製作者</span>}
                  >
                    <SelectField
                      placeholder="指定なし"
                      name="creator"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          ?.toLowerCase()
                          ?.indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataSelectCreator
                        .filter((item) => !!item.creator)
                        ?.map((item, index) => (
                          <Option key={index} value={item.creator}>
                            {item.creator}
                          </Option>
                        ))}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="question_name"
                    className="question_name"
                    label={<span className="text-label">設問名</span>}
                  >
                    <SelectField
                      placeholder="指定なし"
                      name="question_name"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          ?.toLowerCase()
                          ?.indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {Array.from(new Set(questionSelect?.map((i) => i.name))).map(
                        (name, index) => (
                          <Option key={index} value={name}>
                            {name}
                          </Option>
                        )
                      )}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="score"
                    className="score"
                    label={<span className="text-label">スコア（設問難易度）</span>}
                  >
                    <SelectField
                      placeholder="指定なし"
                      name="score"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          ?.toLowerCase()
                          ?.indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {[1, 2, 3].map((e, index) => (
                        <Option key={index} value={e.toString()}>
                          {e}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <SubmitButton className="btn-search" loading={false}>
                    <SearchOutlined className="icon-search" />
                    検索
                  </SubmitButton>
                  <span
                    className="label-reset"
                    onClick={() => {
                      formik.resetForm();
                      formik.handleSubmit();
                    }}
                  >
                    リセット
                  </span>
                </div>
              </Form>
            </FormikProvider>
          </>
        )}
        <Row gutter={16} justify="end">
          <Button
            className="btn-hide-search-box"
            size="small"
            onClick={() => setHideBoxSearch(!hideBoxSearch)}
            icon={
              !hideBoxSearch ? (
                <CaretDownOutlined style={{ fontSize: '17px', color: '#00A3A5' }} />
              ) : (
                <CaretUpOutlined style={{ fontSize: '17px', color: '#00A3A5' }} />
              )
            }
          />
        </Row>
        {hideBoxSearch && <Divider style={{ marginTop: '7px', marginBottom: '8px' }} />}

        <div className="wrap-button">
          <Button
            className={`btn btn-outline ${permissionNumber !== 2 ? 'disabled' : ''}`}
            disabled={permissionNumber !== 2}
            onClick={handleUploadCsvImageVideo}
          >
            画像・動画インポート
          </Button>
          <ImportButton onClick={() => setVisiblePopupUploadCSV(true)}>設問インポート</ImportButton>
          <Button
            disabled={permissionNumber !== 2}
            className={`btn btn-active customizeIcon ${permissionNumber !== 2 ? 'disabled' : ''}`}
            icon={
              <PlusOutlined className={`icon ${permissionNumber !== 2 ? 'iconDisabled' : ''}`} />
            }
            onClick={handleCreate}
          >
            設問新規登録
          </Button>
        </div>
        <Table
          rowKey="i_id"
          className="table"
          dataSource={dataTable.map((item, index) => ({ ...item, index }))}
          columns={fixedColumn}
          rowSelection={{
            onChange: (_, selectedRows: Types.TreeTraining[]) => setSelectedRow(selectedRows),
            preserveSelectedRowKeys: false,
            selectedRowKeys: selectedRow.map((e) => e.i_id),
            getCheckboxProps: () => ({ disabled: permissionNumber !== 2 }),
          }}
          scroll={{
            y: hideBoxSearch ? 'calc(100vh - 560px)' : 'calc(100vh - 360px)',
          }}
          pagination={{
            pageSize: perPage,
            total: dataTable.length,
            current: page,
            showLessItems: true,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <div className="text-count">
                <PagingNumber
                  startItem={dataTable.length ? `${(page - 1) * perPage + 1}` : ''}
                  endItem={page * perPage > dataTable.length ? dataTable.length : page * perPage}
                  totalItem={dataTable.length}
                />
                <PerPageSelect
                  data={dataTable}
                  perPage={perPage}
                  setPage={setPage}
                  setPerPage={setPerPage}
                />
              </div>
            ),
          }}
        />

        <UploadCSV
          title="設問マスタインポート"
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          handleUploadPerItem={handleSubmitImportCsv}
          headerCSVs={HEADER_IMPORT_QUESTION_MASTER_CSV}
          dataSchema={uploadQuestionCsvSchema}
          handleDataImport={handleImportData}
          onSuccess={onImportSuccess}
          defaultUploadTimePerItem={2000}
        />
        <UploadManyImageVideo
          visible={visiblePopupUploadImageVideo}
          setVisible={setVisiblePopupUploadImageVideo}
          fetchData={fetchQuestion}
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
          customTitle="出力形式を選択して、OKボタンをクリックしてください。"
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="設問が選択されていません"
          description={
            <p
              style={{
                marginTop: 32,
                marginBottom: 0,
              }}
            >
              設問を選択し、再度実行してください。
            </p>
          }
        />
        <ActionErrorModal
          visible={visibleDeletePopupError}
          setVisible={setVisibleDeletePopupError}
          subTitle="設問が選択されていません"
          description={
            <p
              style={{
                marginTop: 32,
                marginBottom: 0,
              }}
            >
              設問を選択し、再度実行してください。
            </p>
          }
        />
        <ConfirmDeleteModal
          title="削除確認"
          subTitle="データの削除を実行します。"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          setVisible={setVisiblePopupConfirmDelete}
          visible={visiblePopupConfirmDelete}
          onSubmit={handleSubmitDelete}
        />
        <ModalConfirmDelete
          title="削除確認"
          subTitle="設問の削除は実行できません。"
          description="選択した設問はカリキュラムに設定されているため、削除できません。"
          setVisible={setOpenNotDelete}
          visible={openNotDelete}
        />
        <CreateQuestion
          page={page}
          setVisibleSuccess={setVisibleSuccess}
          openModalCreateEditQuestion={openModalCreateQuestion}
          setOpenModalCreateEditQuestion={setOpenModalCreateQuestion}
          setchangeQuestion={setchangeQuestion}
          changeQuestion={changeQuestion}
          fetchData={() => {
            Promise.all([
              dispatch(
                getDataTableQuestion({
                  conditions: [
                    {
                      id: 'provider_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  page: 1,
                  per_page: 0,
                  include_lookups: true,
                })
              ),
              dispatch(
                getSelectQuestion({
                  conditions: [
                    {
                      id: 'company_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  sort_fields: [
                    {
                      id: 'code',
                      order: 'asc',
                    },
                  ],
                  page: 1,
                  per_page: 0,
                })
              ),
            ]);
          }}
        />
        <DeleteCompleted
          visible={deleteSuccess}
          setVisible={setDeleteSuccess}
          onSubmit={() => setDeleteSuccess(false)}
        />
        <CompletedModal
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          title={
            openModalCreateQuestion.type === 'create' ? '登録が完了しました' : '更新が完了しました'
          }
          onSubmit={() => {
            setVisibleSuccess(!visibleSuccess);
          }}
        />
        <QuestionDetail visible={modalQuestionDetail} setVisible={setModalQuestionDetail} />
      </div>
      <div className="wrap-bottom">
        <div className="flex">
          <div className="text-label">
            選択した設問を処理：
            <Button
              disabled={permissionNumber !== 2}
              className={`btn btn-active ${permissionNumber !== 2 ? 'disabled' : ''}`}
              icon={<DeleteOutlined className="icon" />}
              onClick={handleButtonDelete}
            >
              設問削除
            </Button>
            <Button className="btn btn-active" onClick={handleButtonExport}>
              エクスポート
            </Button>
          </div>
        </div>
      </div>
    </QuestionMasterStyled>
  );
};

export default QuestionMaster;
