import React, { useEffect, useMemo, useState } from 'react';
import { Column } from '@ant-design/plots';
import { Select } from 'antd';

import Styled from './styles';
import { SALE_TYPE, SALE_TYPE_USER_FEE_API_KEY, USER_TYPE } from 'constant/report';
import * as Types from 'types';
import { AnyObject } from 'types';
import dayjs from 'dayjs';
import { formatComma } from 'libs/utils/format';
import { uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { reportSaleInformationSelector } from 'pages/Report/SaleInformation/selectors';
import { setChartSaleType, setChartUserType } from 'pages/Report/SaleInformation/slice';
import { useAppDispatch } from 'hooks';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';

type Props = {
  data: Array<Types.SaleReportInformation.SaleReportInformation>;
  exportPdf?: boolean;
};

const { Option } = Select;

const GraphExpansion: React.FC<Props> = (props) => {
  const { data, exportPdf } = props;
  const { dateRanger, chartUserType } = useSelector(reportSaleInformationSelector);

  const [userType, setUserType] = useState('ALL');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (exportPdf) {
      setUserType(chartUserType || 'ALL');
    }
  }, [exportPdf, chartUserType]);

  useEffect(() => {
    if (exportPdf) {
      dispatch(setChartUserType(''));
      dispatch(setChartSaleType(''));
    }
  }, [dispatch, exportPdf]);

  const dataChart = useMemo(() => {
    let dates =
      dateRanger && dateRanger.length ? dateRanger : uniq(data.map((obj) => obj.target_month));
    if (exportPdf) {
      dates = dates.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    let results: AnyObject[] = [];
    dates.forEach((date) => {
      let dataDates = data.filter((obj) => obj.target_month === date);
      if (Object.keys(USER_TYPE).includes(userType)) {
        dataDates = dataDates.filter(
          (obj) => obj.user_type === USER_TYPE[userType as keyof typeof USER_TYPE]
        );
      }
      results = [
        ...results,
        {
          name: SALE_TYPE.USER_FEE,
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          // value: formatComma(
          //   dataDates
          //     .filter((obj) => obj.sales_type === SALE_TYPE_USER_FEE_API_KEY)
          //     .reduce((res, item) => res + (item.monthly_sales || 0), 0)
          // ),
          value: dataDates
            .filter((obj) => obj.sales_type === SALE_TYPE_USER_FEE_API_KEY)
            .reduce((res, item) => res + (item.monthly_sales || 0), 0),
        },
        {
          name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          // value: formatComma(
          //   dataDates
          //     .filter((obj) => obj.sales_type === SALE_TYPE.SKILL_CHECK_USAGE_FEE)
          //     .reduce((res, item) => res + (item.monthly_sales || 0), 0)
          // ),
          value: dataDates
            .filter((obj) => obj.sales_type === SALE_TYPE.SKILL_CHECK_USAGE_FEE)
            .reduce((res, item) => res + (item.monthly_sales || 0), 0),
        },
        {
          name: SALE_TYPE.STORAGE_USAGE_FEE,
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          // value: formatComma(
          //   dataDates
          //     .filter((obj) => obj.sales_type === SALE_TYPE.STORAGE_USAGE_FEE)
          //     .reduce((res, item) => res + (item.monthly_sales || 0), 0)
          // ),
          value: dataDates
            .filter((obj) => obj.sales_type === SALE_TYPE.STORAGE_USAGE_FEE)
            .reduce((res, item) => res + (item.monthly_sales || 0), 0),
        },
        {
          name: '合計売上',
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          // value: formatComma(
          //   dataDates
          //     .filter((obj) => obj.sales_type === SALE_TYPE.ALL)
          //     .reduce((res, item) => res + (item.monthly_sales || 0), 0)
          // ),
          value: dataDates
            .filter((obj) => obj.sales_type === SALE_TYPE.ALL)
            .reduce((res, item) => res + (item.monthly_sales || 0), 0),
        },
      ];
    });
    return results;
  }, [data, userType]);

  const onSelectUserType = (value: string) => {
    setUserType(value);
    dispatch(setChartUserType(value));
  };

  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">売上種類別売上推移</span>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>ユーザー種類選択：</label>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    value={userType}
                    onChange={onSelectUserType}
                  >
                    {Object.keys(USER_TYPE).map((type: any) => (
                      <Option key={type} value={type}>
                        {type === 'ALL' ? 'ALL' : USER_TYPE[type as keyof typeof USER_TYPE]}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue" />
                    <span className="legend-name">ユーザー利用料</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">スキルチェック利用料</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter sky-blue" />
                    <span className="legend-name">ストレージ利用料</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter green" />
                    <span className="legend-name">合計売上</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart">
              <span className="unit">単位：千円 123213</span>
              <Column
                {...config}
                data={dataChart}
                legend={false}
                yAxis={{
                  grid: {
                    line: {
                      style: {
                        lineDash: [2, 2],
                        stroke: '#ddd',
                      },
                    },
                  },
                  label: {
                    formatter: (text: string) => formatComma(text),
                  },
                }}
                scrollbar={{
                  type: 'horizontal',
                  width: 300,
                  categorySize: 120,
                }}
                xAxis={{
                  tickLine: null,
                  label: { autoRotate: false },
                }}
                tooltip={{
                  formatter: (datum) => ({
                    name: datum.name,
                    value: formatComma(datum.value),
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default GraphExpansion;

const config = {
  isGroup: true,
  xField: 'month',
  yField: 'value',
  seriesField: 'name',
  maxColumnWidth: 15,
  minColumnWidth: 15,
  dodgePadding: 4,
  color: ['#08A3A5', '#F6AC00', '#6EB8EE', '#C3DC7B'],
};
