import React from 'react';

import { BackgroundAudio } from 'assets';
import { Wrapper } from './styles';

interface Props {
  url?: string;
}

const DetailAudio: React.FC<Props> = ({ url }) => {
  return (
    <Wrapper className="wrap-audio">
      <img className="item-img" src={BackgroundAudio} />
      <div className="item-audio">
        <audio className="audio" controls controlsList="nodownload noplaybackrate" src={url} />
      </div>
    </Wrapper>
  );
};

export default DetailAudio;
