import { PathCommand } from '@antv/g-base/lib';
import { AnyObject } from 'types';
import { DISPLAY_ID_KEY } from 'configs';

export const LIST_TITLE_HEADER_INFORMATION_REGISTER = [
  'AJ-Step-1',
  'AJ-Step-2',
  'AJ-Step-3',
  'AJ-Step-4',
  'AJ-Step-5',
];

export const CHECK_BOX_MODAL_CONFIRM_WITHDRAWAL = [
  '利用料金が高いため',
  '機能が不十分なため',
  '動作が重い',
  '別のサービスを利用するため',
  'その他',
];

export const CREDIT_CARD_MONTH = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const REGEX_HALF_WIDTH_KATAKANA = /^[ｧ-ﾝﾞﾟ]+$/;
export const REGEX_FULL_WIDTH_ALL =
  /^[\u1100-\u115F\u2E80-\uA4CF\uAC00-\uD7AF\uF900-\uFAFF\uFE10-\uFE19\uFE30-\uFE6F\uFF00-\uFF60\uFFE0-\uFFE6\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]+$/;
export const REGEX_HALF_WIDTH_NUMBER = /^[0-9]+$/;
export const REGEX_HALF_WIDTH_NUMBER_AND_HYPHEN = /^[0-9]+(-[0-9]+)*$/;
// export const REGEX_HALF_WIDTH_NUMBER_AND_TEXT_AND_HYPHEN = /^([ぁ-んァ-ン一-龥a-zA-Z0-9]+-?)+[ぁ-んァ-ン一-龥a-zA-Z0-9]+$/;
export const REGEX_KANJI = /^[一-龥]+$/;
export const REGEX_KANA = /^[ぁ-んァ-ン]+$/;
export const REGEX_KANA_KANJI = /^[ぁ-んァ-ン一-龥]/;
export const REGEX_KATAKANA = /^([ァ-ンー\s])+$/;

export const LIST_LABEL = ['カリキュラム', '第１階層', '第２階層', '第３階層', '第４階層', '設問'];

export const LIST_TAB_BAR = ['通常モード', '移動＆コピーモード'];

export const MENU_WIDTH = 196;

export const MENU_COLLAPSED_WIDTH = 60;

export const HEADER_HEIGHT = 56;

export const ERROR_DETAIL_CODE = [
  { code: 'G02', message: '残高不足' },
  { code: 'G03', message: '限度額オーバー' },
  { code: 'G04', message: '残高不足' },
  { code: 'G05', message: '限度額オーバー' },
  { code: 'G12', message: '取扱不可' },
  { code: 'G22', message: '取扱不可' },
  { code: 'G30', message: '保留判定' },
  { code: 'G42', message: '暗証番号エラー' },
  { code: 'G44', message: 'セキュリティコード誤り' },
  { code: 'G45', message: 'セキュリティコード入力無' },
  { code: 'G54', message: '取扱不可' },
  { code: 'G55', message: '限度額オーバー' },
  { code: 'G56', message: 'カード取り込み' },
  { code: 'G60', message: '事故カード' },
  { code: 'G61', message: '無効カード' },
  { code: 'G65', message: '会員番号エラー' },
  { code: 'G67', message: '商品コードエラー' },
  { code: 'G68', message: '金額エラー' },
  { code: 'G69', message: '税送料エラー' },
  { code: 'G70', message: 'ボーナス回数エラー' },
  { code: 'G71', message: 'ボーナス月エラー' },
  { code: 'G72', message: 'ボーナス額エラー' },
  { code: 'G73', message: '支払開始月エラー' },
  { code: 'G74', message: '分割回数エラー' },
  { code: 'G75', message: '分割金額エラー' },
  { code: 'G76', message: '初回金額エラー' },
  { code: 'G77', message: '業務区分エラー' },
  { code: 'G78', message: '支払区分エラー' },
  { code: 'G79', message: '照会区分エラー' },
  { code: 'G80', message: '取消区分エラー' },
  { code: 'G81', message: '取消取扱区分エラー' },
  { code: 'G83', message: '有効期限エラー' },
  { code: 'G95', message: 'オンライン終了' },
  { code: 'G96', message: '取扱不可' },
  { code: 'G97', message: '取扱不可' },
  { code: 'G98', message: '取扱不可' },
  { code: 'G99', message: '取扱不可' },
  { code: 'C01', message: '被仕向会社コードエラー' },
  { code: 'C03', message: '最大電文長エラー' },
  { code: 'C12', message: '被仕向センタービジー' },
  { code: 'C13', message: '被仕向センター障害中' },
  { code: 'C14', message: '被仕向センター終了' },
  { code: 'C15', message: 'ＣＡＦＩＳセンター終了' },
  { code: 'C50', message: 'ＣＡＦＩＳタイムアウト' },
  { code: 'C51', message: '経路重複' },
  { code: 'C53', message: '仕向会社コードエラー' },
  { code: 'C54', message: '電文種別エラー' },
  { code: 'C55', message: '経路範囲外' },
  { code: 'C56', message: '電文対応エラー' },
  { code: 'C57', message: '当該会社サービスなし' },
  { code: 'C58', message: 'ＮＵＭＥＲＩＣチェックエラー' },
  { code: 'C60', message: '受信不可状態' },
  { code: 'C70', message: 'ＣＡＦＩＳファイルなし' },
  { code: 'C71', message: 'ＣＡＦＩＳ属性エラー' },
  { code: 'C72', message: 'ＣＡＦＩＳ範囲エラー' },
  { code: 'C73', message: 'ＣＡＦＩＳ桁オーバー' },
  { code: 'C74', message: 'ＣＡＦＩＳファイルアクセス禁止中' },
  { code: 'C75', message: 'ＣＡＦＩＳファイル障害中' },
  { code: 'C76', message: '加盟店情報変更不可' },
  { code: 'C77', message: '必須項目ノンセット' },
  { code: 'C78', message: '会社テーブルエラー' },
];

export const DEFAULT_TABLE_COL_WIDTH = 160;

export const REGEX_HALF_WIDTH_LATIN = /^(?=\s*$)|^[a-zA-Z0-9!"#$%&'()*+,-.\\:/;<=>?@[\]^_`{|}~]+$/;
export const REGEX_KATAKANA_FULL_WIDTH = /^([ァ-ン]|ー)+$/;

export const BLANK_NAME = '（空白）';

export const MAX_REQUESTS_PER_TIMES = 10;

export const DATE_FORMAT = 'YYYY/MM/DD';
export const YEAR_MONTH_FORMAT = 'YYYY/MM';
export const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm';

export const EXPORT_TARGET_MONTH_COUNT = 12;

export const QUESTION_ATTACH_MAPPING: AnyObject = {
  attach1: '設問1',
  attach2: '設問2',
  attach3: '設問3',
  comment_attach1: '解説1',
  comment_attach2: '解説2',
  comment_attach3: '解説3',
  problems1_attach: '選択A',
  problems2_attach: '選択B',
  problems3_attach: '選択C',
};

export const ALL_COMPANY_TYPE_ID = DISPLAY_ID_KEY.ALL_COMPANY_TYPE_ID.name;

export const COORDINATELINEPATH: Array<PathCommand> = [
  ['M', 0, 5],
  ['H', 16],
  ['V', 7],
  ['H', 0],
  ['Z'],
  ['M', 12, 6],
  ['C', 12, 8.20914, 10.2091, 10, 8, 10],
  ['C', 5.79086, 10, 4, 8.20914, 4, 6],
  ['C', 4, 3.79086, 5.79086, 2, 8, 2],
  ['C', 10.2091, 2, 12, 3.79086, 12, 6],
  ['Z'],
];

export const EXTENSIONS: Array<{ label: string; value: string; extension: string[] }> = [
  {
    label: '画像',
    value: 'image',
    extension: [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/tiff',
      'image/webp',
      'image/svg+xml',
      'image/x-icon',
      'image/heif',
      'image/apng',
      'image/heic',
    ],
  },
  {
    label: '動画',
    value: 'video',
    extension: [
      'video/mov',
      'video/mp4',
      'video/avi',
      'video/mpeg',
      'video/ogg',
      'video/webm',
      'video/flv',
      'video/3gpp',
      'video/x-msvideo',
      'video/quicktime',
      'video/x-mov',
      'video/x-mpeg',
      'video/3gpp2',
      'video/x-flv',
      'video/x-matroska',
      'application/vnd.apple.mpegurl',
    ],
  },
  {
    label: 'Excel',
    value: 'excel',
    extension: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ],
  },
  {
    label: 'Word',
    value: 'word',
    extension: [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'application/doc',
      'application/application/ms-doc',
    ],
  },
  {
    label: 'PDF',
    value: 'pdf',
    extension: ['application/pdf'],
  },
  {
    label: 'PowerPoint',
    value: 'power-point',
    extension: [
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint',
    ],
  },
];

export const FILE_LOCATION: Array<string> = [
  'カリキュラムマスタ',
  '設問マスタ',
  'スキルチェックマスタ',
  'マニュアル',
  'ナレッジ',
  'プロフィール',
];

export const COMPANY_TYPES_OBJECT = {
  rstandard: {
    id: process.env.REACT_APP_RSTANDARD,
    type: 'RSTANDARD',
    name: 'rstandard',
  },
  partner: {
    id: process.env.REACT_APP_PARTNER,
    type: 'パートナー企業',
    name: 'partner',
  },
  all_company: {
    id: process.env.REACT_APP_ALL_COMPANY,
    type: '全企業',
    name: 'all_company',
  },
};

export const COMPANY_TYPES = Object.values(COMPANY_TYPES_OBJECT);

export const VALID_PARTNER_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/webp',
];

export const VALID_MIME_IMAGE_QUESTION = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/svg+xml',
  'image/webp',
  'image/x-icon',
  'image/vnd.microsoft.icon',
  'image/bmp',
];

export const IMAGE_EXTENSIONS = ['.jpeg', '.jpg', '.png', '.gif', '.svg', '.webp', '.ico', '.bmp'];

export const AUDIO_FILE_EXTENSIONS = ['.mp3', '.aac', '.ogg', '.webm', '.wav', '.WAV'];

export const VIDEO_FILE_EXTENSIONS = ['.mp4', '.webm', '.ogv'];

export const VALID_MIME_TYPE_AUDIO_QUESTION = [
  'audio/mpeg',
  'audio/aac',
  'audio/ogg',
  'audio/webm',
  'audio/wav',
  'audio/vnd.dlna.adts',
];

export const VALID_MIME_TYPE_VIDEO_QUESTION = ['video/mp4', 'video/webm', 'video/ogg'];

export const VALID_MIME_TYPE_QUESTION = [
  ...VALID_MIME_IMAGE_QUESTION,
  ...VALID_MIME_TYPE_AUDIO_QUESTION,
  ...VALID_MIME_TYPE_VIDEO_QUESTION,
];
