import * as Types from 'types';
import { AnyObject } from 'types';
import { toObject } from 'libs/index';
import { SALE_TYPE, USER_TYPE } from 'constant/report';
import { formatComma } from 'libs/utils/format';
import { CalculatedReport } from 'types/datastores/user_report_information';

const formatReportData = (data: number | undefined, isFormat: boolean) =>
  !isFormat ? data : data ? formatComma(data || 0) || '-' : '-';

const USER_FEE_API_KEY = '月額利用料';

export const groupSaleReportData = (
  data: Array<Types.SaleReportInformation.SaleReportInformation>,
  dates: string[],
  isFormat = false
) => {
  return Object.values(USER_TYPE).reduce((res: AnyObject, userType) => {
    const records = data.filter(
      (obj) => obj.user_type === userType && obj.sales_type === SALE_TYPE.ALL
    );
    const recordObject = toObject(records, 'target_month');
    const dateData = dates.reduce((temp: AnyObject, item) => {
      temp[item] = formatReportData(recordObject[item]?.monthly_sales, isFormat);
      return temp;
    }, {});
    res[userType] = {
      [SALE_TYPE.ALL]: dateData,
    };
    if (userType === USER_TYPE.CORPORATE || userType === USER_TYPE.PERSONAL) {
      // USER_FEE
      const userFee = data.filter(
        (obj) => obj.user_type === userType && obj.sales_type === USER_FEE_API_KEY
      );
      const userFeeObject = toObject(userFee, 'target_month');
      const userFeeDateData = dates.reduce((temp: AnyObject, item) => {
        temp[item] = formatReportData(userFeeObject[item]?.monthly_sales, isFormat);
        return temp;
      }, {});
      const numberOfUserData = dates.reduce((temp: AnyObject, item) => {
        temp[item] = formatReportData(userFeeObject[item]?.max_users, isFormat);
        return temp;
      }, {});
      res[userType][SALE_TYPE.USER_FEE] = {
        data: userFeeDateData,
        child: numberOfUserData,
      };
      // SKILL_CHECK_USAGE_FEE
      const skillCheckFee = data.filter(
        (obj) => obj.user_type === userType && obj.sales_type === SALE_TYPE.SKILL_CHECK_USAGE_FEE
      );
      const skillCheckFeeObject = toObject(skillCheckFee, 'target_month');
      const skillCheckDateData = dates.reduce((temp: AnyObject, item) => {
        temp[item] = formatReportData(skillCheckFeeObject[item]?.monthly_sales, isFormat);
        return temp;
      }, {});
      const numberOfSkillCheckData = dates.reduce((temp: AnyObject, item) => {
        temp[item] = formatReportData(
          skillCheckFeeObject[item]?.inter_skill_check_count &&
            skillCheckFeeObject[item]?.emp_skill_check_count
            ? skillCheckFeeObject[item]?.inter_skill_check_count +
                skillCheckFeeObject[item]?.emp_skill_check_count
            : skillCheckFeeObject[item]?.emp_skill_check_count ||
                skillCheckFeeObject[item]?.inter_skill_check_count ||
                0,
          isFormat
        );
        return temp;
      }, {});
      res[userType][SALE_TYPE.SKILL_CHECK_USAGE_FEE] = {
        data: skillCheckDateData,
        child: numberOfSkillCheckData,
      };

      if (userType === USER_TYPE.CORPORATE) {
        // STORAGE_USAGE_FEE
        const storageFee = data.filter(
          (obj) => obj.user_type === userType && obj.sales_type === SALE_TYPE.STORAGE_USAGE_FEE
        );
        const storageFeeObject = toObject(storageFee, 'target_month');
        const storageData = dates.reduce((temp: AnyObject, item) => {
          temp[item] = formatReportData(storageFeeObject[item]?.monthly_sales, isFormat);
          return temp;
        }, {});
        const numberOfStorageData = dates.reduce((temp: AnyObject, item) => {
          temp[item] = formatReportData(storageFeeObject[item]?.usage_storage, isFormat);
          return temp;
        }, {});
        res[userType][SALE_TYPE.STORAGE_USAGE_FEE] = {
          data: storageData,
          child: numberOfStorageData,
        };
      }
    }
    return res;
  }, {});
};

const getSkillCheckCount = (
  item: Types.SaleReportInformation.SaleReportInformationBreakdownDetail
) =>
  item.inter_skill_check_count && item.emp_skill_check_count
    ? item.inter_skill_check_count + item.emp_skill_check_count
    : item.emp_skill_check_count || item.inter_skill_check_count || 0;

const getChildValueBySaleType = (
  salesType: string,
  item: Types.SaleReportInformation.SaleReportInformationBreakdownDetail
) => {
  if (!item) {
    return 0;
  }
  switch (salesType) {
    case SALE_TYPE.USER_FEE:
      return Number(`${item.max_users || 0}`);
    case SALE_TYPE.SKILL_CHECK_USAGE_FEE:
      return Number(`${getSkillCheckCount(item) || 0}`);
    case SALE_TYPE.STORAGE_USAGE_FEE:
      return Number(`${item.usage_storage || 0}`);
    default:
      return 0;
  }
};

export const groupSaleReportBreakdownData = (
  data: Array<Types.SaleReportInformation.SaleReportInformationBreakdown>,
  dataDetails: Array<Types.SaleReportInformation.SaleReportInformationBreakdownDetail>,
  dates: string[],
  isFormat = false
) => {
  const defaultDateData = dates.reduce((temp: AnyObject, item) => {
    temp[item] = formatReportData(0, isFormat);
    return temp;
  }, {});
  const detailObject = dataDetails.reduce(
    (
      result: Record<string, Types.SaleReportInformation.SaleReportInformationBreakdownDetail>,
      item
    ) => {
      return {
        ...result,
        [`${item.company_id}_${item.target_month}`]: item,
      };
    },
    {}
  );

  return Object.values(USER_TYPE).reduce((res: AnyObject, userType) => {
    const records = data.filter((obj) => obj.user_type === userType);
    if (userType === USER_TYPE.ALL) {
      const allData = records.filter((obj) => obj.sales_type === SALE_TYPE.ALL);
      const recordObject = toObject(allData, 'target_month');
      const dateData = dates.reduce((temp: AnyObject, item) => {
        temp[item] = formatReportData(recordObject[item]?.monthly_sales_breakdown, isFormat);
        return temp;
      }, {});
      res[userType] = {
        [SALE_TYPE.ALL]: dateData,
      };
    }
    if (userType === USER_TYPE.CORPORATE) {
      const totalSaleType = {
        [SALE_TYPE.ALL]: {
          data: { ...defaultDateData },
          child: { ...defaultDateData },
        },
        [SALE_TYPE.USER_FEE]: {
          data: { ...defaultDateData },
          child: { ...defaultDateData },
        },
        [SALE_TYPE.SKILL_CHECK_USAGE_FEE]: {
          data: { ...defaultDateData },
          child: { ...defaultDateData },
        },
        [SALE_TYPE.STORAGE_USAGE_FEE]: {
          data: { ...defaultDateData },
          child: { ...defaultDateData },
        },
      };
      const companyObject = records.reduce((rest: AnyObject, company) => {
        const dataDetail = detailObject[`${company.company_id}_${company.target_month}`];
        const childValue = getChildValueBySaleType(company.sales_type, dataDetail);
        if (
          totalSaleType[company.sales_type] &&
          totalSaleType[company.sales_type].data &&
          totalSaleType[company.sales_type].data[company.target_month] !== undefined
        ) {
          totalSaleType[company.sales_type].data[company.target_month] =
            totalSaleType[company.sales_type].data[company.target_month] +
            (company.monthly_sales_breakdown || 0);
        }
        if (
          dataDetail &&
          company.sales_type !== SALE_TYPE.ALL &&
          totalSaleType[company.sales_type] &&
          totalSaleType[company.sales_type].child &&
          totalSaleType[company.sales_type].child[company.target_month] !== undefined
        ) {
          totalSaleType[company.sales_type].child[company.target_month] =
            totalSaleType[company.sales_type].child[company.target_month] + childValue;
        }
        if (rest[company.company_id]) {
          if (rest[company.company_id][company.sales_type]) {
            const currentDateData = rest[company.company_id][company.sales_type].data;
            currentDateData[company.target_month] = company.monthly_sales_breakdown || 0;
            rest[company.company_id][company.sales_type].data = formatReportData(
              currentDateData,
              isFormat
            );

            const currentDateDataChild = rest[company.company_id][company.sales_type].child;
            currentDateDataChild[company.target_month] = childValue;
            rest[company.company_id][company.sales_type].child = formatReportData(
              currentDateDataChild,
              isFormat
            );
          } else {
            const tempDateData = { ...defaultDateData };
            tempDateData[company.target_month] = formatReportData(
              company.monthly_sales_breakdown || 0,
              isFormat
            );

            const tempDateDataChild = { ...defaultDateData };
            tempDateDataChild[company.target_month] = formatReportData(childValue, isFormat);

            rest[company.company_id][company.sales_type] = {
              data: tempDateData,
              child: tempDateDataChild,
            };
          }
        } else {
          const tempDateData = { ...defaultDateData };
          tempDateData[company.target_month] = company.monthly_sales_breakdown || 0;
          const childDateData = { ...defaultDateData };
          childDateData[company.target_month] = childValue;
          rest[company.company_id] = {
            code: company.company_id,
            name: company.company_name,
            [SALE_TYPE.ALL]: {
              data: { ...defaultDateData },
              child: { ...defaultDateData },
            },
            [SALE_TYPE.USER_FEE]: {
              data: { ...defaultDateData },
              child: { ...defaultDateData },
            },
            [SALE_TYPE.SKILL_CHECK_USAGE_FEE]: {
              data: { ...defaultDateData },
              child: { ...defaultDateData },
            },
            [SALE_TYPE.STORAGE_USAGE_FEE]: {
              data: { ...defaultDateData },
              child: { ...defaultDateData },
            },
            [company.sales_type]: {
              data: tempDateData,
              child: childDateData,
            },
          };
        }
        return rest;
      }, {});
      res[userType] = {
        total: totalSaleType,
        items: companyObject,
      };
    }

    if (userType === USER_TYPE.PERSONAL) {
      const totalSaleType = {
        [SALE_TYPE.ALL]: {
          data: { ...defaultDateData },
          child: { ...defaultDateData },
        },
        [SALE_TYPE.USER_FEE]: {
          data: { ...defaultDateData },
          child: { ...defaultDateData },
        },
        [SALE_TYPE.SKILL_CHECK_USAGE_FEE]: {
          data: { ...defaultDateData },
          child: { ...defaultDateData },
        },
      };
      const companyObject = records.reduce((rest: AnyObject, company) => {
        const dataDetail = detailObject[`${company.user_id}_${company.target_month}`];
        const childValue = getChildValueBySaleType(company.sales_type, dataDetail);
        if (
          totalSaleType[company.sales_type] &&
          totalSaleType[company.sales_type].data &&
          totalSaleType[company.sales_type].data[company.target_month] !== undefined
        ) {
          totalSaleType[company.sales_type].data[company.target_month] =
            totalSaleType[company.sales_type].data[company.target_month] +
            (company.monthly_sales_breakdown || 0);
        }
        if (
          dataDetail &&
          company.sales_type !== SALE_TYPE.ALL &&
          totalSaleType[company.sales_type] &&
          totalSaleType[company.sales_type].child &&
          totalSaleType[company.sales_type].child[company.target_month] !== undefined
        ) {
          totalSaleType[company.sales_type].child[company.target_month] =
            totalSaleType[company.sales_type].child[company.target_month] + childValue;
        }
        if (rest[company.user_id]) {
          if (rest[company.user_id][company.sales_type]) {
            const currentDateData = rest[company.user_id][company.sales_type].data;
            currentDateData[company.target_month] = formatReportData(
              company.monthly_sales_breakdown || 0,
              isFormat
            );
            rest[company.user_id][company.sales_type].data = currentDateData;

            const currentDateDataChild = rest[company.user_id][company.sales_type].child;
            currentDateDataChild[company.target_month] = formatReportData(childValue, isFormat);
            rest[company.user_id][company.sales_type].child = currentDateDataChild;
          } else {
            const tempDateData = { ...defaultDateData };
            tempDateData[company.target_month] = formatReportData(
              company.monthly_sales_breakdown || 0,
              isFormat
            );

            const tempDateDataChild = { ...defaultDateData };
            tempDateDataChild[company.target_month] = childValue;

            rest[company.user_id][company.sales_type] = {
              data: tempDateData,
              child: tempDateDataChild,
            };
          }
        } else {
          const tempDateData = { ...defaultDateData };
          tempDateData[company.target_month] = formatReportData(
            company.monthly_sales_breakdown || 0,
            isFormat
          );
          const childDateData = { ...defaultDateData };
          childDateData[company.target_month] = formatReportData(childValue, isFormat);
          rest[company.user_id] = {
            code: company.user_id,
            name: company.user_name,
            [SALE_TYPE.ALL]: {
              data: { ...defaultDateData },
              child: { ...defaultDateData },
            },
            [SALE_TYPE.USER_FEE]: {
              data: { ...defaultDateData },
              child: { ...defaultDateData },
            },
            [SALE_TYPE.SKILL_CHECK_USAGE_FEE]: {
              data: { ...defaultDateData },
              child: { ...defaultDateData },
            },
            [company.sales_type]: {
              data: tempDateData,
              child: childDateData,
            },
          };
        }
        return rest;
      }, {});
      res[userType] = {
        total: totalSaleType,
        items: companyObject,
      };
    }

    return res;
  }, {});
};

export const formatObjectData = (obj?: AnyObject) => {
  if (!obj) {
    return obj;
  }
  const res: AnyObject = {};
  for (const key in obj) {
    res[key] = formatReportData(obj[key], true);
  }

  return res;
};

export const getUserReportCompanyData = (
  businessType: string,
  visibleExpansionOfSale: boolean,
  calculatedData?: CalculatedReport
) => {
  let listCsv: AnyObject[] = [];
  if (visibleExpansionOfSale) {
    listCsv = [
      ...listCsv,
      {
        business_type: businessType,
        registration_information: '新規登録企業数',
        ...formatObjectData((calculatedData || {}).emp_user_added),
      },
      {
        business_type: businessType,
        registration_information: '退会企業数',
        ...formatObjectData((calculatedData || {}).emp_user_deleted),
      },
    ];
  }
  listCsv = [
    ...listCsv,
    {
      business_type: businessType,
      registration_information: '月末登録ユーザー数',
      ...formatObjectData((calculatedData || {}).month_end_users),
    },
  ];
  return listCsv;
};

export const getUserReportCompanyDataDetail = (
  businessType: string,
  visibleExpansionOfSale: boolean,
  filterCompanyName?: string,
  calculatedData?: CalculatedReport
) => {
  let listCsv: AnyObject[] = [
    {
      business_type: businessType,
      company_name: '-',
      company_id: '-',
      registration_information: '-',
      ...formatObjectData((calculatedData || {}).month_end_users),
    },
  ];
  if (calculatedData?.children) {
    Object.keys(calculatedData.children).forEach((companyId) => {
      const company = calculatedData.children
        ? calculatedData.children[companyId]
        : ({} as CalculatedReport);
      if (!filterCompanyName || (company.name || '').includes(filterCompanyName)) {
        if (visibleExpansionOfSale) {
          listCsv = [
            ...listCsv,
            {
              business_type: businessType,
              company_name: company.name,
              company_id: companyId,
              registration_information: '新規登録企業数',
              ...formatObjectData((company || {}).emp_user_added),
            },
            {
              business_type: businessType,
              company_name: company.name,
              company_id: companyId,
              registration_information: '退会企業数',
              ...formatObjectData((company || {}).emp_user_deleted),
            },
          ];
        }
        listCsv = [
          ...listCsv,
          {
            business_type: businessType,
            company_name: company.name,
            company_id: companyId,
            registration_information: '月末登録ユーザー数',
            ...formatObjectData((company || {}).emp_user_deleted),
          },
        ];
      }
    });
  }

  return listCsv;
};
