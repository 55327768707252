import React, { useEffect, useMemo, useRef, useState } from 'react';
import { UploadChangeParam, UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import saveAs from 'file-saver';
import { Progress, Upload } from 'antd';
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  FileOutlined,
} from '@ant-design/icons';

import { SectionStyled } from './styles';
import { Modal } from 'components';
import { AnyObject, HeaderCSVs } from 'types/config';
import ImportedDataTable from 'components/ImportedDataTable';
import { ArraySchema, ObjectSchema, ValidationError } from 'yup';
import { parse, ParseResult } from 'papaparse';
import { extractYupErrorPath, toObject } from 'libs';
import lodash, { sum } from 'lodash';
import { formatRemainTime } from 'libs/utils/format';
import { isValidDate } from 'libs/utils/exportCsv';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  onSubmit?: (data: AnyObject[]) => Promise<any>;
  headerCSVs: HeaderCSVs;
  dataSchema: ArraySchema<ObjectSchema<AnyObject>>;
  fileNameTemplate?: string;
  extValidate?: (dataImport: AnyObject[]) => Promise<AnyObject | undefined> | AnyObject | undefined;
  defaultUploadTimePerItem?: number;
  handleUploadPerItem?: (data: AnyObject, extraData?: AnyObject) => Promise<any>;
  onSuccess?: (itemIdImported: string[]) => void;
  afterImportItems?: (itemIdsImported: string[]) => Promise<any>;
  handleDataImport?: (dataImport: AnyObject[]) => AnyObject[];
  maxItemImport?: number;
  messageMaxItemImport?: string;
}

const { Dragger } = Upload;

const convertCsvData = (data: Array<string[]>, headerCSVs: HeaderCSVs) => {
  const headerCsvObj = toObject(headerCSVs, 'label');
  const headers = data[0];
  return data
    .map((item) =>
      Object.fromEntries(
        headers.map((title, i) => {
          const value = item[i] ? `${item[i]}`.replace('\u200B', '') : item[i];
          // eslint-disable-next-line quotes
          const checkFistString = value ? (value.charAt(0) === "'" ? value.slice(1) : value) : '';
          return [
            headerCsvObj[title]?.key || title,
            (isValidDate(checkFistString) ? checkFistString : value) || undefined,
          ];
        })
      )
    )
    .splice(1);
};

const UploadCSV: React.FC<Props> = (props) => {
  const {
    visible,
    setVisible,
    title,
    onSubmit,
    headerCSVs,
    dataSchema,
    fileNameTemplate = title,
    extValidate,
    handleUploadPerItem,
    onSuccess,
    afterImportItems,
    defaultUploadTimePerItem,
    handleDataImport,
    maxItemImport,
    messageMaxItemImport,
  } = props;

  const [isSuccessfully, setIsSuccessfully] = useState<boolean>(false);
  const [isStart, setIsStart] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [dataImported, setDataImported] = useState<AnyObject[]>();
  const [headersImported, setHeadersImported] = useState<string[]>();
  const [yupErrors, setYupErrors] = useState<ValidationError>();
  const [extErrors, setExtErrors] = useState<AnyObject>();
  const [extWarnings, setExtWarnings] = useState<AnyObject>();
  const [loading, setLoading] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);
  const [itemIdImported, setItemIdImported] = useState<string[]>([]);
  const [timeTakenPerItem, setTimeTakenPerItem] = useState(defaultUploadTimePerItem || 0);
  const [totalImported, setTotalImported] = useState(0);
  const onClose = () => {
    setVisible(false);
  };

  const isCancel = useRef(false);

  const handleDelete = () => {
    setFile(undefined);
    setDataImported(undefined);
    setHeadersImported(undefined);
    setYupErrors(undefined);
    setIsSuccessfully(false);
    setTotalImported(0);
    setIsStart(false);
  };

  useEffect(() => {
    if (visible) {
      handleDelete();
    }
  }, [visible]);

  const errors = useMemo(() => {
    let errorObj: AnyObject = extErrors || {};
    if (yupErrors) {
      yupErrors.inner.forEach((error: any) => {
        const errorPath = extractYupErrorPath(error.path);
        errorObj = {
          ...errorObj,
          [errorPath.index]: {
            ...(errorObj[errorPath.index] || {}),
            [`${errorPath.key}_error`]: error.message,
          },
        };
      });
    }

    if (dataImported && (maxItemImport || maxItemImport === 0)) {
      const currentImportErrorIndexes = Object.keys(errorObj).map((obj) => parseInt(obj));
      const validIndexes = dataImported
        .map((_, indx) => indx)
        .filter((index) => !currentImportErrorIndexes.includes(index));
      const errorMaxIndexes = validIndexes.slice(maxItemImport, validIndexes.length);
      if (errorMaxIndexes.length) {
        errorMaxIndexes.forEach((idx) => {
          errorObj = {
            ...errorObj,
            [idx]: {
              ...(errorObj[idx] || {}),
              maxItemImport_error: messageMaxItemImport || 'Error max',
            },
          };
        });
      }
    }

    return errorObj;
  }, [yupErrors, extErrors, dataImported]);

  const dataSubmit = useMemo(() => {
    const indexErrors = Object.keys(errors || {}).map((idx) => Number(`${idx}`));
    const data = (dataImported || []).filter((_, index) => !indexErrors.includes(index));
    return handleDataImport ? handleDataImport(data) : data;
  }, [errors, dataImported]);

  const estimateTime = useMemo(
    () => formatRemainTime(dataSubmit.length * timeTakenPerItem),
    [dataSubmit, timeTakenPerItem]
  );

  const handleSubmit = async () => {
    try {
      setImporting(true);
      setIsStart(true);
      setTotalImported(0);
      isCancel.current = false;

      if (onSubmit) {
        await onSubmit(dataSubmit);
      }
      if (handleUploadPerItem) {
        const iIdAdded = [];
        let res = {};
        const allTimeImport = [];
        for (let i = 0; i < dataSubmit.length; i++) {
          const start = new Date();
          const id = await handleUploadPerItem(dataSubmit[i], res);
          res = id;
          iIdAdded.push(id);
          const timeImported = new Date().getTime() - start.getTime();
          allTimeImport.push(timeImported);
          setTimeTakenPerItem(sum(allTimeImport) / (allTimeImport.length || 1));
          setTotalImported(i + 1);
          if (isCancel.current) {
            isCancel.current = false;
            break;
          }
        }
        setItemIdImported(iIdAdded);
        afterImportItems && afterImportItems(iIdAdded);
      }
      setIsSuccessfully(true);
    } catch (e) {
      console.log(e);
    } finally {
      setImporting(false);
    }
  };

  const okProps = useMemo(() => {
    const diffHeaders = lodash.difference(
      headerCSVs.map((obj) => obj.label),
      headersImported || []
    );
    if (!file || diffHeaders.length) {
      return {
        text: 'インポート',
        disabled: true,
      };
    }
    const total = dataImported?.length || 0;
    const totalErrors = errors ? Object.keys(errors).length : 0;
    if (total === totalErrors) {
      return {
        text: 'インポート',
        disabled: true,
      };
    }
    if (!totalErrors && total) {
      return {
        text: 'インポート',
        disabled: false,
      };
    }
    return {
      text: `${total}件のうち${total - totalErrors}件を インポート`,
      disabled: false,
    };
  }, [errors, dataImported, file, headersImported, headerCSVs]);

  const handleExportCSV = () => {
    const csvString = [headerCSVs.map(({ label }) => label)].map((e) => e.join(',')).join('\n');
    const bom = '\uFEFF';
    const fileExport = new Blob([bom, csvString], { type: 'application/octet-stream' });
    saveAs(fileExport, `テンプレートの${fileNameTemplate}.csv`);
  };

  const onFileChange = (info: UploadChangeParam<UploadFileAntd<File>>) => {
    setLoading(true);
    const inputFile = info.file as unknown as File;
    setFile(inputFile);
    parse(inputFile, {
      complete: async ({ data }: ParseResult<string[]>) => {
        const resultsDataCSV = convertCsvData(data, headerCSVs);
        setDataImported(resultsDataCSV);
        setHeadersImported(data[0]);
        try {
          await dataSchema.validate(resultsDataCSV, {
            abortEarly: false,
          });
        } catch (err) {
          if (err instanceof ValidationError) {
            setYupErrors(err);
          }
        } finally {
          setLoading(false);
        }
      },
      skipEmptyLines: true,
    });
  };

  const handleExtErrors = async () => {
    if (!dataImported || !dataImported.length || !extValidate || !visible) {
      return;
    }
    setExtErrors(undefined);
    setExtWarnings(undefined);
    setValidating(true);
    const errs = await extValidate(dataImported);
    if (errs && errs.hasOwnProperty('errors')) {
      setExtErrors(errs.errors);
      setExtWarnings(errs.warnings);
    } else {
      setExtErrors(errs);
    }
    setValidating(false);
  };

  useEffect(() => {
    handleExtErrors();
  }, [dataImported]);

  const onCloseModalSuccess = () => {
    setVisible(false);
    setIsStart(false);
    onSuccess && onSuccess(itemIdImported);
  };

  const onCancel = () => {
    setIsStart(false);
    isCancel.current = true;
    onCloseModalSuccess();
  };

  return (
    <>
      <Modal
        open={isStart}
        zIndex={10000}
        okButton={
          isSuccessfully
            ? {
                text: <span style={{ color: '#F6AC00' }}>OK</span>,
                style: {
                  background: 'white',
                  border: '1px solid #F6AC00',
                },
                onClick: onCloseModalSuccess,
              }
            : undefined
        }
        cancelButton={
          !isSuccessfully
            ? {
                text: 'キャンセル',
                onClick: onCancel,
              }
            : undefined
        }
      >
        <SectionStyled>
          <p className="sub-title">
            {isSuccessfully ? (
              <div className="text-successful">
                <CheckCircleOutlined className="icon-success" />
                インポート完了
              </div>
            ) : (
              'インポート実行中'
            )}
          </p>
          {!isSuccessfully && (
            <div>
              <Progress
                type="line"
                strokeColor="#F6AC00"
                percent={(totalImported / (dataSubmit.length || 1)) * 100}
                showInfo={false}
                strokeWidth={20}
                status={'active'}
              />
              <div className="remain-time">
                残り時間 ：
                {formatRemainTime((dataSubmit.length - totalImported) * timeTakenPerItem)}
              </div>
            </div>
          )}
        </SectionStyled>
      </Modal>
      <Modal
        title={title}
        wrapClassName="modal-full-width"
        width="100vw"
        open={visible}
        okButton={{
          onClick: handleSubmit,
          loading: importing,
          ...okProps,
        }}
        cancelButton={{
          text: 'キャンセル',
          onClick: onClose,
        }}
        onCancel={onClose}
        bodyStyle={{
          backgroundColor: '#f9f8f8',
          padding: '8px 16px',
        }}
        headerStyle={{
          justifyContent: 'left',
          fontSize: '18px',
          color: '#2A2A2A',
        }}
        afterClose={handleDelete}
        destroyOnClose
      >
        <SectionStyled>
          <div className="form-upload">
            <p className="text-download-template" onClick={handleExportCSV}>
              <FileOutlined className="icon" />
              テンプレートをダウンロード
            </p>
            <div className="form-upload-border">
              <div className="file-upload">
                <Dragger
                  style={{ flex: 1 }}
                  accept=".csv"
                  beforeUpload={() => false}
                  onChange={onFileChange}
                >
                  {file ? (
                    <div className="info-file">
                      <p className="name-file">{file.name} </p>
                    </div>
                  ) : (
                    <>
                      <CloudUploadOutlined className="icon" />
                      <p className="ant-upload-text">インポートするCSVファイルをここにドロップ</p>
                    </>
                  )}
                  <div className="wrap-button-upload">
                    <button type="button" className="btn-upload">
                      ファイルを選択
                    </button>
                    {file && (
                      <button
                        className="btn-delete"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete();
                        }}
                      >
                        <DeleteOutlined className="icon-delete-outlined" />
                        <span className="text-delete-outlined">ファイルを削除</span>
                      </button>
                    )}
                  </div>
                </Dragger>
              </div>
            </div>
            <ImportedDataTable
              headerCSVs={headerCSVs}
              attached={!!file}
              dataImported={dataImported}
              headersImported={headersImported}
              errors={errors}
              warnings={extWarnings}
              loading={loading || validating}
              title={title}
              estimateTime={estimateTime}
            />
          </div>
        </SectionStyled>
      </Modal>
    </>
  );
};

export default UploadCSV;
