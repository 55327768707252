import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import { DownIconPdf } from 'assets/svg/svgPDF';
import dayjs from 'dayjs';
import IconCheckSquareThin from 'assets/svg/svgPDF/IconCheckSquareThin';
import IconCheckSquareBold from 'assets/svg/svgPDF/IconCheckSquareBold';
import SelectBox from 'components/ReactPDF/SelectBox';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface CooperateUserPDFType {
  deletedat: string;
  admin_department: string;
  admin_name: string;
  admin_position: string;
  company_id: string;
  company_name: string;
  month_end_users: number;
  skillcheck_trans_nums: number;
  admin_last_login_at: string;
  forced_withdrawal_flg: number;
  suspension_flg: number;
  unpaid_alert_flg: number;
  payment_method_name: string;
  payment_method_cd?: number;
}

interface Props {
  dataSource: CooperateUserPDFType[];
  contract_status?: string;
  company_id?: string;
  company_name?: string;
  payment_method_cd?: string;
  formValue: {
    contract_status: string;
    company_id: string;
    company_name: string;
    payment_method_name: string;
  };
}

const FIRST_PAGE_ROW_COUNT = 12;
const PAGE_ROW_COUNT = 17;

const CooperateUserTable = ({ dataSource }: { dataSource: CooperateUserPDFType[] }) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: '契約状況',
      width: 70,
      key: 'contractStatus',
    },
    {
      title: ['法人ID', '法人名'],
      width: 170,
      key: 'company_id_company_name',
    },
    {
      title: '請求方法',
      width: 80,
      key: 'payment_method',
    },
    {
      title: ['ユーザー数', 'スキルチェック数'],
      width: 150,
      key: 'payment-method',
    },
    {
      title: ['所属 / 役職', '管理者氏名'],
      width: 140,
      key: 'position-name',
    },
    {
      title: '最終ログイン',
      width: 80,
      key: 'admin_last_login_at',
    },
    {
      title: ['請求書', '特例'],
      width: 60,
      key: 'invoice_exception',
    },
    {
      title: ['未払い', 'アラート'],
      width: 50,
      key: 'unpaid_alert_flg',
    },
    {
      title: '利用停止',
      width: 50,
      key: 'suspension_flg',
    },
    {
      title: ['強制', '退会'],
      width: 50,
      key: 'forcedWithdrawal',
    },
  ];

  const isStringArray = (val: any) => {
    if (Array.isArray(val)) {
      return true;
    } else {
      return false;
    }
  };
  const formatDateData = (val: string) => {
    if (val) {
      return dayjs(val).format('YYYY/MM/DD');
    }
    return '-';
  };

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow, { height: '26.728px' }]}>
        {columns.map((column, index) =>
          isStringArray(column.title) ? (
            <View
              key={index}
              style={[
                styles.wrapCell,
                { width: column.width, display: 'flex', flexDirection: 'column' },
              ]}
            >
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[0]}</Text>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[1]}</Text>
            </View>
          ) : (
            <View key={index} style={[styles.wrapCell, { width: column.width }]}>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
            </View>
          )
        )}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View
            style={[
              styles.wrapCell,
              { width: columns[0].width, display: 'flex', flexDirection: 'row' },
            ]}
          >
            <View
              style={[
                styles.verticalBar,
                { backgroundColor: row.deletedat ? '#c4c4c4' : '#08a3a5' },
              ]}
            ></View>
            <Text style={[styles.tableCell, { marginLeft: '6px' }]}>
              {row.deletedat ? '退会' : '契約中'}
            </Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              { width: columns[1].width, display: 'flex', flexDirection: 'column' },
            ]}
          >
            <Text style={styles.tableCell}>{row.company_id}</Text>
            <Text style={styles.tableCell}>{row.company_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={[styles.tableCell]}>{row.payment_method_name || '-'}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={[styles.tableCell, { marginLeft: '50px' }]}>{row.month_end_users}</Text>
            <Text style={[styles.tableCell, { marginLeft: '50px' }]}>
              {row.skillcheck_trans_nums}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={[styles.tableCell, { color: '#777777' }]}>
              {row.admin_department || '-'} / {row.admin_position || '-'}
            </Text>
            <Text style={styles.tableCell}>{row.admin_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width }]}>
            <Text style={styles.tableCell}>{formatDateData(row.admin_last_login_at)}</Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              { width: columns[6].width, display: 'flex', flexDirection: 'column' },
            ]}
          >
            {row.month_end_users > 500 ? (
              <IconCheckSquareThin isInvoice={true} style={{ transform: 'scale(0.6)' }} />
            ) : row.payment_method_cd === 2 ? (
              <IconCheckSquareBold isInvoice={true} style={{ transform: 'scale(0.6)' }} />
            ) : (
              <Text style={[styles.tableCell, { marginLeft: '6px' }]}>-</Text>
            )}
          </View>
          <View style={[styles.wrapCell, { width: columns[7].width }]}>
            {row.unpaid_alert_flg === 0 ? (
              <IconCheckSquareThin style={{ transform: 'scale(0.6)' }} />
            ) : row.unpaid_alert_flg === 1 ? (
              <IconCheckSquareBold style={{ transform: 'scale(0.6)' }} />
            ) : (
              <Text style={[styles.tableCell, { marginLeft: '6px' }]}>-</Text>
            )}
          </View>
          <View style={[styles.wrapCell, { width: columns[8].width, textAlign: 'right' }]}>
            {row.suspension_flg === 0 ? (
              <IconCheckSquareThin style={{ transform: 'scale(0.6)' }} />
            ) : row.suspension_flg === 1 ? (
              <IconCheckSquareBold style={{ transform: 'scale(0.6)' }} />
            ) : (
              <Text style={[styles.tableCell, { marginLeft: '6px' }]}>-</Text>
            )}
          </View>
          <View style={[styles.wrapCell, { width: columns[9].width }]}>
            {row.forced_withdrawal_flg === 0 ? (
              <IconCheckSquareThin style={{ transform: 'scale(0.6)' }} />
            ) : row.forced_withdrawal_flg === 1 ? (
              <IconCheckSquareBold style={{ transform: 'scale(0.6)' }} />
            ) : (
              <Text style={[styles.tableCell, { marginLeft: '6px' }]}>-</Text>
            )}
          </View>
        </View>
      ))}
    </View>
  );
};

const CooperateUserManagementDocument = (props: Props) => {
  const { dataSource, contract_status, company_id, company_name, payment_method_cd, formValue } =
    props;
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>法人ユーザー管理</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                法人ユーザーの登録情報の確認・管理を行う画面です。
              </Text>
              <Text style={styles.textSubscription}>
                請求書対応の場合のURL発行もこの画面から行います
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            {/* <View style={styles.searchItem}>
              <Text style={styles.label}>契約状況</Text>
              <View style={[styles.selectBox, { display: 'flex', flexDirection: 'row' }]}>
                <Text
                  style={[
                    styles.selectText,
                    { color: formValue.contract_status ? '#000' : '#CCCCCC' },
                  ]}
                >
                  {formValue.contract_status ? formValue.contract_status : '指定なし'}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View> */}
            <SelectBox
              selectBoxStyle={styles.searchItem}
              label="契約状況"
              value={
                formValue.contract_status
                  ? formValue.contract_status === 'ALL'
                    ? 'ALL'
                    : formValue.contract_status === '1'
                    ? '退会'
                    : '契約中'
                  : '指定なし'
              }
            />
            {/* <View style={styles.searchItem}>
              <Text style={styles.label}>法人ID</Text>
              <View style={[styles.selectBox, { display: 'flex', flexDirection: 'row' }]}>
                <Text
                  style={[styles.selectText, { color: formValue.company_id ? '#000' : '#CCCCCC' }]}
                >
                  {formValue.company_id ? formValue.company_id : '指定なし'}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View> */}
            <SelectBox
              selectBoxStyle={styles.searchItem}
              label="法人ID"
              value={formValue.company_id ? formValue.company_id : '指定なし'}
            />
            {/* <View style={styles.searchItem}>
              <Text style={styles.label}>法人名</Text>
              <View style={[styles.selectBox, { display: 'flex', flexDirection: 'row' }]}>
                <Text
                  style={[
                    styles.selectText,
                    { color: formValue.company_name ? '#000' : '#CCCCCC' },
                  ]}
                >
                  {formValue.company_name ? formValue.company_name : '指定なし'}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View> */}
            <SelectBox
              selectBoxStyle={styles.searchItem}
              label="法人名"
              value={formValue.company_name ? formValue.company_name || '（空白）' : '指定なし'}
            />
            {/* <View style={styles.searchItem}>
              <Text style={styles.label}>請求方法</Text>
              <View style={[styles.selectBox, { display: 'flex', flexDirection: 'row' }]}>
                <Text
                  style={[
                    styles.selectText,
                    { color: formValue.payment_method_name ? '#000' : '#CCCCCC' },
                  ]}
                >
                  {formValue.payment_method_name ? formValue.payment_method_name : '指定なし'}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View> */}
            <SelectBox
              selectBoxStyle={styles.searchItem}
              label="請求方法"
              value={formValue.payment_method_name ? formValue.payment_method_name : '指定なし'}
            />
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <CooperateUserTable dataSource={dataSource.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataSource.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataSource.slice(FIRST_PAGE_ROW_COUNT, dataSource.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <CooperateUserTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default CooperateUserManagementDocument;
