export const USER_TYPE = {
  ALL: '全ユーザー',
  CORPORATE: '法人ユーザー',
  PERSONAL: '個人ユーザー',
};

export const SALE_TYPE = {
  ALL: 'ALL',
  USER_FEE: 'ユーザー利用料',
  SKILL_CHECK_USAGE_FEE: 'スキルチェック利用料',
  STORAGE_USAGE_FEE: 'ストレージ利用料',
};

export const SALE_TYPE_USER_FEE_API_KEY = '月額利用料';

export const USER_INFORMATION_COMPANY_TYPE = {
  corporateUsers: '法人ユーザー',
  personalUser: '個人ユーザー',
  partners: 'パートナーユーザー',
};

export const SALE_INFORMATION_REPORT_TYPE = {
  MONTHLY_TREND: {
    key: 'MONTHLY_TREND',
    name: '単月推移',
  },
  CUMULATIVE_CHANGE: {
    key: 'CUMULATIVE_CHANGE',
    name: '累計推移',
  },
  MONTHLY_TRENDS_BY_USER: {
    key: 'MONTHLY_TRENDS_BY_USER',
    name: 'ユーザー別単月推移',
  },
  CUMULATIVE_TRENDS_BY_USER: {
    key: 'CUMULATIVE_TRENDS_BY_USER',
    name: 'ユーザー別累計推移',
  },
};
