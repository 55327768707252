import React, { useCallback, useEffect, useState } from 'react';

import { DeleteOutlined, EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Spin, Switch } from 'antd';
import { map } from 'lodash';
import dayjs from 'dayjs';

import { memoizedGetChildrenItemIDFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import {
  removeNodeLevel4Selected,
  selectNodeLevel4,
  setFilterByCurriculum,
  setFilterByStatus,
} from 'pages/Curriculum/slice';
import { deleteFileInR2, getPresignedUrlByFileID } from 'services/r2Service';
import { deleteLinkQuestion } from 'containers/CreateEditQuestion/thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import CopyCurriculmModal from 'pages/Curriculum/Modal/CopyCurriculum';
import CreateQuestion from 'pages/QuestionMaster/Modal/CreateQuestion';
import { extractFileName, getFileFromUrl, getFileUrlFromUrl } from 'libs/utils/format';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { Button, DivCustom, DivCustomCanDrag } from './styles';
import { convertFileResponse } from 'libs/utils/question';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { UploadFile } from 'antd/lib/upload/interface';
import { useAppDispatch, usePermission } from 'hooks';
import { ErrorBoundary } from 'components';
import { COMPANY_TYPES } from 'constant';
import * as Types from 'types';
import {
  CopyWhite,
  IconRequired,
  MoveWhite,
  StopPublic,
  TickComplete,
  Unpublished,
  WhiteEditing,
  IconLogo,
} from 'assets';
import {
  EditLevelCurriculum,
  CreateEditCurriculum,
  CreateLevelCurriculum,
  PopupCurriculumConfirmPublish,
} from 'pages/Curriculum/Modal';
import {
  getDataOfficialCurriculumAllCompanyAggregation,
  deleteQuestionAssignLevel4OfficialByCondition,
  getDataOfficialCurriculumUserAggregation,
  updateOfficialCurriculumUserAggregation,
  deleteUserAssignCurriculumByCondition,
  deletePublishedReleaseNoteSection,
  deleteCurriculumPublishedHistory,
  deleteKnowledgeToByCondition,
  deleteCurriculumByCondition,
  deletePublishedReleaseNote,
  deleteKnowledgeByCondition,
  deleteQuestionByCondition,
  deleteReleaseNoteSection,
  deleteLevel1sByCondition,
  deleteLevel2sByCondition,
  deleteLevel3sByCondition,
  deleteLevel4sByCondition,
  getAttachCurriculumFile,
  getCurriculumUserUsing,
  getDataCurriculumTrans,
  deleteLevelCurriculum,
  setPublishCurriculum,
  getDataReleaseNote,
  deleteReleaseNote,
  getDataCurriculum,
  deleteFileAttach,
  deleteCurriculum,
  updateCurriculum,
} from 'pages/Curriculum/thunk';

type Props = {
  tabActive: number;
  maxSortOrder?: number;
  visible: boolean;
  node: Types.TreeItem<Types.CurriculumItemType>;
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectModeDrag: (n: Types.TreeItem<Types.CurriculumItemType>, m: 'move' | 'copy') => void;
  onDrop?: (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  onDropNode?: (currentItem: Types.TreeItem<Types.CurriculumItemType>) => void;
  setDeleteSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  rootNode: Types.TreeItem<Types.CurriculumItemType>;
};

type ImageProps = {
  fileURL: string | null | undefined;
};

const TooltipRowContent = ({
  node,
  onDrop,
  tabActive,
  setVisible,
  maxSortOrder,
  itemMoveCopySelected,
  handleSelectModeDrag,
  visible,
  onDropNode,
  rootNode,
  setDeleteSuccess,
}: Props) => {
  const [openModalEditLevelCurriculum, setOpenModalEditLevelCurriculum] = useState<boolean>(false);
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [openModalEditCurriculum, setOpenModalEditCurriculum] = useState<boolean>(false);
  const [openDeleteQuestion, setOpenDeleteQuestion] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [openCopy, setOpenCopy] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile<File>>();
  const [fileUrl, setFileUrl] = useState<string | null>();

  const [openModalCreateLevelCurriculum, setOpenModalCreateLevelCurriculum] =
    useState<boolean>(false);
  const [openPopupCurriculumConfirmPublish, setOpenPopupCurriculumConfirmPublish] =
    useState<boolean>(false);
  const [openModalCreateEditQuestion, setOpenModalCreateEditQuestion] = useState<{
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
  }>({ visible: false, type: 'create' });
  const [loadingImage, setLoadingImage] = useState<boolean>(false);

  const { nodeLevel4Selected, conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const { permissionNumber } = usePermission();

  const dispatch = useAppDispatch();

  const ImageComponent: React.FC<ImageProps> = ({ fileURL }) => {
    if (!fileURL) {
      return (
        <div className="no-image">
          <img src={IconLogo} className="icon-logo" alt="manual-file" />
          <div className="text">no image</div>
        </div>
      );
    }

    return (
      <div className="item-image">
        <img src={fileURL} className="image" alt="file" />
      </div>
    );
  };

  const handlePublishItem = useCallback(async () => {
    if (node.node) {
      await dispatch(
        setPublishCurriculum({
          id: node.node.i_id!,
          data: {
            item: {
              publish: node.node.publish ? 0 : 1,
            },
            is_force_update: true,
            return_item_result: true,
          },
        })
      );
      dispatch(removeNodeLevel4Selected());
      setVisible(false);
      setOpenPopupCurriculumConfirmPublish(false);
      await dispatch(
        getDataCurriculum({
          conditions: [
            {
              id: 'provider_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [node.node, dispatch, setVisible, userInfo?.company_id]);

  const handleGetFileCurriculum = useCallback(async () => {
    if (!node?.node) return;

    const fileQuestion: UploadFile<File> = { uid: '', name: '' };
    if (node?.node?.fileID && visible) {
      setLoadingImage(true);
      const fileName = extractFileName(node?.node?.fileID);
      const nodeFileUrl = (await getPresignedUrlByFileID(node?.node?.fileID)) as string;
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
      const fileUrl = await getFileUrlFromUrl(nodeFileUrl);

      Object.assign(
        fileQuestion,
        convertFileResponse({
          file: fileFromUrl,
          fileID: node?.node?.fileID,
          fileName: fileName,
          url: nodeFileUrl,
        })
      );
      setFiles(fileQuestion);
      setFileUrl(fileUrl);
      setLoadingImage(false);
    }
  }, [node?.node, visible]);

  const handleSelectNodeLevel4 = useCallback(
    (checked: boolean) => {
      if (checked && node.node) {
        dispatch(selectNodeLevel4({ ...node.node, maxSortOrder }));
      } else {
        dispatch(removeNodeLevel4Selected());
      }
      setVisible(false);
    },
    [node, setVisible, dispatch, maxSortOrder]
  );

  const handleUpdateOfficialCurriculumUserAggregation = async (
    companyId: string,
    curriculumCode: string,
    officialCurriculumCode: string
  ) => {
    const result = await Promise.all([
      dispatch(
        getDataCurriculumTrans({
          conditions: [
            {
              id: 'company_id',
              search_value: [companyId],
              exact_match: true,
            },
            {
              id: 'curricullum_code',
              search_value: [curriculumCode],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
        })
      ),
      dispatch(
        getDataOfficialCurriculumUserAggregation({
          conditions: [
            {
              id: 'target_month',
              search_value: [dayjs().format('YYYYMM')],
              exact_match: true,
            },
            {
              id: 'curriculum_code',
              search_value: [officialCurriculumCode],
              exact_match: true,
            },
            {
              id: 'company_type',
              search_value: [
                COMPANY_TYPES.filter((t) => t.name !== 'all_company')
                  .map((i) => i.id)
                  .join('|'),
              ],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    if (
      getDataCurriculumTrans.fulfilled.match(result[0]) &&
      getDataOfficialCurriculumUserAggregation.fulfilled.match(result[1])
    ) {
      const totalCurriculumTrans = result[0].payload.totalItems || 0;
      const curriculumUserAggregation = result[1].payload.items[0] || undefined;

      if (totalCurriculumTrans && curriculumUserAggregation) {
        await dispatch(
          updateOfficialCurriculumUserAggregation({
            id: curriculumUserAggregation.i_id,
            data: {
              item: {
                count_of_stops:
                  Number(curriculumUserAggregation.count_of_stops || 0) + totalCurriculumTrans,
                updated_at: new Date(),
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
      }
    }
  };

  const handleUpdateOfficialCurriculumAllCompany = async (
    companyId: string,
    curriculumCode: string,
    officialCurriculumCode: string
  ) => {
    const result = await Promise.all([
      dispatch(
        getDataCurriculumTrans({
          conditions: [
            {
              id: 'company_id',
              search_value: [companyId],
              exact_match: true,
            },
            {
              id: 'curricullum_code',
              search_value: [curriculumCode],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
        })
      ),
      dispatch(
        getDataOfficialCurriculumAllCompanyAggregation({
          conditions: [
            {
              id: 'target_month',
              search_value: [dayjs().format('YYYYMM')],
              exact_match: true,
            },
            {
              id: 'curriculum_code',
              search_value: [officialCurriculumCode],
              exact_match: true,
            },
            {
              id: 'company_type',
              search_value: [COMPANY_TYPES.find((t) => t.name === 'all_company')?.id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    if (
      getDataCurriculumTrans.fulfilled.match(result[0]) &&
      getDataOfficialCurriculumAllCompanyAggregation.fulfilled.match(result[1])
    ) {
      const totalCurriculumTrans = result[0].payload.totalItems || 0;
      const curriculumAllCompany = result[1].payload.items[0] || undefined;

      if (totalCurriculumTrans && curriculumAllCompany) {
        await dispatch(
          updateOfficialCurriculumUserAggregation({
            id: curriculumAllCompany.i_id,
            data: {
              item: {
                count_of_stops:
                  Number(curriculumAllCompany.count_of_stops || 0) + totalCurriculumTrans,
                updated_at: new Date(),
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
      }
    }
  };

  const handleUpdateCountOfStopCurriculum = async (officialCurriculumCode: string) => {
    const resultCurriculumUsingByUser = await dispatch(
      getCurriculumUserUsing({
        conditions: [
          {
            id: 'official_curriculum_code',
            search_value: [officialCurriculumCode],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    if (
      getCurriculumUserUsing.fulfilled.match(resultCurriculumUsingByUser) &&
      resultCurriculumUsingByUser.payload.items.length > 0
    ) {
      resultCurriculumUsingByUser.payload.items.forEach(async (curriculumUser) => {
        await handleUpdateOfficialCurriculumUserAggregation(
          curriculumUser.company_id,
          curriculumUser.code,
          officialCurriculumCode
        );
        await handleUpdateOfficialCurriculumAllCompany(
          curriculumUser.company_id,
          curriculumUser.code,
          officialCurriculumCode
        );
      });
    }
  };

  const handleDeleteCurriculum = useCallback(async () => {
    if (node) {
      dispatch(startLoading());

      setOpenDeleteQuestion(false);
      const listChildItemID: Types.FlatChildrenItemID<Types.CurriculumItemType>[] =
        memoizedGetChildrenItemIDFromTree({
          treeData: node.node!,
        });

      const resultActions = await Promise.all([
        ...listChildItemID.map((item) => {
          if (item.columnIndex === 0) {
            return dispatch(
              deleteCurriculum({
                id: item.i_id!,
              })
            );
          } else if (item.columnIndex < 5) {
            return dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
              })
            );
          } else {
            return dispatch(
              deleteLinkQuestion({
                id: item.question_assign_level_i_id!,
              })
            );
          }
        }),
      ]);

      const curriculumDeleteLength = resultActions.filter(
        (r) => r && deleteCurriculum.fulfilled.match(r)
      ).length;

      if (curriculumDeleteLength && node.node?.code) {
        const resultGetReleaseNote = await dispatch(
          getDataReleaseNote({
            conditions: [
              {
                id: 'curriculum_code',
                search_value: [node.node?.code],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'release_version',
                order: 'desc',
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
            omit_total_items: false,
            omit_fields_data: true,
            use_display_id: true,
          })
        );

        if (node.node.fileID) {
          await Promise.all([
            deleteFileInR2(node.node.fileID),

            dispatch(
              deleteFileAttach({
                conditions: [
                  {
                    id: 'fileID',
                    search_value: [node.node.fileID],
                  },
                ],
                use_display_id: true,
              })
            ),
          ]);
        }

        const reqDeleteByCondition = {
          conditions: [
            {
              id: 'official_curriculum_code',
              search_value: [node.node?.code],
            },
          ],
          use_display_id: true,
        };

        const resultFileAttach = await dispatch(
          getAttachCurriculumFile({
            page: 1,
            per_page: 0,
            ...reqDeleteByCondition,
          })
        );

        const fileIDs = getAttachCurriculumFile.fulfilled.match(resultFileAttach)
          ? resultFileAttach.payload.items.map((itemAttach) => itemAttach.fileID)
          : [];

        await Promise.all([
          dispatch(
            deleteReleaseNote({
              conditions: [
                {
                  id: 'curriculum_code',
                  search_value: [node.node?.code],
                  exact_match: true,
                },
              ],
            })
          ),

          dispatch(
            deletePublishedReleaseNote({
              conditions: [
                {
                  id: 'curriculum_code',
                  search_value: [node.node?.code],
                  exact_match: true,
                },
              ],
            })
          ),

          dispatch(
            deleteCurriculumPublishedHistory({
              conditions: [
                {
                  id: 'official_curriculum_code',
                  search_value: [node.node?.code],
                  exact_match: true,
                },
              ],
            })
          ),

          dispatch(deleteCurriculumByCondition(reqDeleteByCondition)),
          dispatch(deleteLevel1sByCondition(reqDeleteByCondition)),
          dispatch(deleteLevel2sByCondition(reqDeleteByCondition)),
          dispatch(deleteLevel3sByCondition(reqDeleteByCondition)),
          dispatch(deleteLevel4sByCondition(reqDeleteByCondition)),
          dispatch(deleteQuestionByCondition(reqDeleteByCondition)),
          dispatch(deleteFileAttach(reqDeleteByCondition)),
          dispatch(deleteQuestionAssignLevel4OfficialByCondition(reqDeleteByCondition)),
          dispatch(deleteUserAssignCurriculumByCondition(reqDeleteByCondition)),
          dispatch(deleteKnowledgeByCondition(reqDeleteByCondition)),
          dispatch(deleteKnowledgeToByCondition(reqDeleteByCondition)),
          ...fileIDs.map((fileID: string) => deleteFileInR2(fileID)),
        ]);

        await handleUpdateCountOfStopCurriculum(node.node?.code);

        if (getDataReleaseNote.fulfilled.match(resultGetReleaseNote)) {
          const releaseNoteIds: string[] = map(
            resultGetReleaseNote.payload.items,
            'release_note_id'
          );

          if (releaseNoteIds.length) {
            await Promise.all([
              dispatch(
                deletePublishedReleaseNoteSection({
                  conditions: [
                    {
                      id: 'release_note_id',
                      search_value: [...releaseNoteIds],
                      exact_match: true,
                    },
                  ],
                })
              ),

              dispatch(
                deleteReleaseNoteSection({
                  conditions: [
                    {
                      id: 'release_note_id',
                      search_value: [...releaseNoteIds],
                      exact_match: true,
                    },
                  ],
                })
              ),
            ]);
          }
        }
      }

      const questions_delete_length = resultActions.filter(
        (r) => r && deleteLinkQuestion.fulfilled.match(r)
      ).length;
      if (questions_delete_length) {
        const curriculum = listChildItemID.find((curr) => curr.curriculum_id);
        if (
          (curriculum || rootNode.node?.i_id) &&
          !listChildItemID.some((item) => item.columnIndex === 0)
        ) {
          await dispatch(
            updateCurriculum({
              id: curriculum?.curriculum_id! || rootNode.node?.i_id!,
              data: {
                item: {
                  probs_count:
                    curriculum?.problems_count && curriculum?.problems_count > 0
                      ? curriculum?.problems_count - questions_delete_length
                      : 0,
                  updatedat: new Date(),
                  updated_at: new Date().toDateString(),
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          );
        }
      }
      setVisible(false);
      setOpenModalConfirmDeleteItem(false);
      await Promise.all([
        dispatch(
          getDataCurriculum({
            conditions: [
              {
                id: 'provider_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(
        setFilterByStatus({
          status: conditions?.status,
        })
      );
      dispatch(
        setFilterByCurriculum({
          name: conditions?.name,
        })
      );
      dispatch(stopLoading());
      setDeleteSuccess(true);
    }
  }, [
    node,
    dispatch,
    setVisible,
    userInfo?.company_id,
    conditions?.status,
    conditions?.name,
    setDeleteSuccess,
  ]);

  useEffect(() => {
    if (!visible) return;
    handleGetFileCurriculum();
  }, [handleGetFileCurriculum, visible]);

  if (!tabActive) {
    return (
      <DivCustom columnIndex={node.columnIndex!}>
        {node.columnIndex === 0 && (
          <div className="rowWrapper bgGrey">
            <div className="rowContentHead">
              <div className="spanHead">
                {node.node?.publish === 1 ? (
                  <>
                    <img src={Unpublished} className="icon" alt="publish-icon" />
                    <span className="spanText">未公開</span>
                  </>
                ) : node.node?.publish === 2 ? (
                  <>
                    <img src={TickComplete} className="icon" alt="edit-icon" />
                    <span className="spanText">公開中</span>
                  </>
                ) : node.node?.publish === 3 ? (
                  <>
                    <img src={StopPublic} className="icon" alt="edit-icon" />
                    <span className="spanText">公開停止中</span>
                  </>
                ) : (
                  <>
                    <img src={WhiteEditing} className="icon" alt="edit-icon" />
                    <span className="spanText">編集中</span>
                  </>
                )}
              </div>
              {!!node.node?.required_curriculum && (
                <div className="spanHead">
                  <img src={IconRequired} className="icon" alt="required-icon" />
                  <span className="spanText">必修カリキュラム</span>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="rowWrapper image">
          <div
            style={{
              position: 'relative',
            }}
          >
            {node?.node?.columnIndex === 0 ? (
              <>
                <ImageComponent fileURL={fileUrl} />
              </>
            ) : null}
            {loadingImage && node.node?.fileID && (
              <div
                className="item-image"
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                }}
              >
                <Spin
                  style={{
                    position: 'relative',
                    alignSelf: 'center',
                  }}
                />
              </div>
            )}
          </div>
          {node.columnIndex === 0 ? null : <p>{node.node?.name || '（空白）'}</p>}
          {node.node?.publish !== 2 && (
            <div className="rowContent action-bottom">
              {node.columnIndex! < 4 && (
                <Button
                  disabled={permissionNumber !== 2}
                  className={`create-level-button ${permissionNumber !== 2 ? 'disabled' : ''}`}
                  onClick={() => setOpenModalCreateLevelCurriculum(true)}
                >
                  +第{node.columnIndex! + 1}階層追加
                </Button>
              )}
              <div className="icons">
                <EditOutlined
                  className={`icon ${permissionNumber !== 2 ? 'icon-disable' : ''}`}
                  onClick={() => {
                    if (permissionNumber !== 2) return;
                    if (node.columnIndex === 0) {
                      setOpenModalEditCurriculum(true);
                      handleGetFileCurriculum();
                    } else if (node.columnIndex === 5) {
                      setOpenModalCreateEditQuestion({
                        question_id: node.node?.i_id,
                        visible: true,
                        type: 'edit',
                      });
                    } else {
                      setOpenModalEditLevelCurriculum(true);
                    }
                  }}
                />
                {node.columnIndex! < 5 ? (
                  <DeleteOutlined
                    className={`icon ${permissionNumber !== 2 ? 'icon-disable' : ''}`}
                    onClick={() => permissionNumber === 2 && setOpenModalConfirmDeleteItem(true)}
                  />
                ) : (
                  <MinusOutlined
                    className={`icon ${permissionNumber !== 2 ? 'icon-disable' : ''}`}
                    onClick={() => {
                      permissionNumber === 2 && setOpenDeleteQuestion(true);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {node.columnIndex !== 0
          ? node.columnIndex === 4 &&
            node.node?.publish !== 2 && (
              <div className="rowWrapper blRed">
                <div className="rowContent">
                  <span>設問追加先に設定</span>
                  <Switch
                    disabled={permissionNumber !== 2}
                    size="small"
                    checked={
                      nodeLevel4Selected &&
                      node.node &&
                      nodeLevel4Selected?.i_id === node.node?.i_id
                    }
                    onChange={handleSelectNodeLevel4}
                  />
                </div>
              </div>
            )
          : null}
        <CreateLevelCurriculum
          node={node}
          maxSortOrder={maxSortOrder}
          visible={openModalCreateLevelCurriculum}
          setVisible={setOpenModalCreateLevelCurriculum}
          setVisibleTooltip={setVisible}
          rootNode={rootNode}
        />
        <EditLevelCurriculum
          node={node}
          visible={openModalEditLevelCurriculum}
          setVisible={setOpenModalEditLevelCurriculum}
          rootNode={rootNode}
        />
        <ErrorBoundary>
          <CreateQuestion
            page={1}
            setVisibleSuccess={setVisibleSuccess}
            openModalCreateEditQuestion={openModalCreateEditQuestion}
            setOpenModalCreateEditQuestion={setOpenModalCreateEditQuestion}
            fetchData={() => {
              dispatch(
                getDataCurriculum({
                  conditions: [
                    {
                      id: 'provider_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  page: 1,
                  per_page: 0,
                })
              );
            }}
          />
        </ErrorBoundary>
        <CompletedModal
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          title="更新が完了しました"
          onSubmit={() => {
            setVisibleSuccess(!visibleSuccess);
          }}
        />
        <PopupCurriculumConfirmPublish
          onSubmit={handlePublishItem}
          nodeLocked={!!node.node?.publish}
          visible={openPopupCurriculumConfirmPublish}
          setVisible={setOpenPopupCurriculumConfirmPublish}
        />
        <CreateEditCurriculum
          type="edit"
          textSubmit="更新"
          id={node.node?.i_id}
          setVisibleSuccess={setVisibleSuccess}
          title="カリキュラム編集"
          name={node.node?.name}
          visible={openModalEditCurriculum}
          description={node.node?.description}
          setVisible={setOpenModalEditCurriculum}
          subTitle="カリキュラム名・説明の編集が可能です。編集後に更新ボタンをクリックしてください。"
          fileCurriculum={files}
        />
        <ConfirmDeleteModal
          title="削除確認"
          subTitle="データの削除を実行します。"
          onSubmit={handleDeleteCurriculum}
          visible={openModalConfirmDeleteItem}
          setVisible={setOpenModalConfirmDeleteItem}
          description={
            <>
              削除を実行すると、紐付いている階層の全データも同時に削除されます。
              <br />
              <span style={{ color: 'red' }}>※ただし、設問は削除されません</span>
            </>
          }
        />
        <ConfirmDeleteModal
          title="設問解除"
          subTitle="指定した設問のカリキュラム設定を解除します。"
          // description="削除を実行すると、復元できませんのでご注意ください。"
          setVisible={setOpenDeleteQuestion}
          visible={openDeleteQuestion}
          onSubmit={handleDeleteCurriculum}
          itemDeleted
        />
      </DivCustom>
    );
  }

  if (
    node.columnIndex === 0 &&
    (!itemMoveCopySelected || itemMoveCopySelected.node.columnIndex! - 1 !== node.columnIndex)
  ) {
    return (
      <DivCustom columnIndex={0}>
        <div className="rowWrapper bgGrey">
          <div className="rowContentHead">
            <div className="spanHead">
              {node.node?.publish === 1 ? (
                <>
                  <img src={Unpublished} className="icon" alt="publish-icon" />
                  <span className="spanText">未公開</span>
                </>
              ) : node.node?.publish === 2 ? (
                <>
                  <img src={TickComplete} className="icon" alt="edit-icon" />
                  <span className="spanText">公開中</span>
                </>
              ) : node.node?.publish === 3 ? (
                <>
                  <img src={StopPublic} className="icon" alt="edit-icon" />
                  <span className="spanText">公開停止中</span>
                </>
              ) : (
                <>
                  <img src={WhiteEditing} className="icon" alt="edit-icon" />
                  <span className="spanText">編集中</span>
                </>
              )}
            </div>

            {!!node.node?.required_curriculum && (
              <div className="spanHead">
                <img src={IconRequired} className="icon" alt="required-icon" />
                <span className="spanText">必修カリキュラム</span>
              </div>
            )}
          </div>
        </div>
        <div className="rowWrapper">
          {files && node?.node?.fileID ? (
            <div className="item-image">
              <img
                src={URL.createObjectURL(new Blob([files.originFileObj as Blob]))}
                className="image"
                alt={files.name}
              />
            </div>
          ) : null}
          <div className="rowContent">
            {permissionNumber !== 1 && (
              <div className="action-bottom">
                <Button
                  className="button-copy"
                  onClick={() => {
                    if (node && onDropNode) {
                      setOpenCopy(true);
                    }
                  }}
                >
                  <PlusOutlined /> &nbsp; コピー追加
                </Button>
              </div>
            )}
          </div>
        </div>
        <CopyCurriculmModal
          open={openCopy}
          setOpen={setOpenCopy}
          node={node}
          onSubmit={() => {
            if (node && onDropNode) {
              onDropNode(node);
            }

            setVisible(false);
          }}
        />
      </DivCustom>
    );
  }

  if (node.parentNode || itemMoveCopySelected) {
    return itemMoveCopySelected &&
      itemMoveCopySelected.node.columnIndex! - 1 === node.columnIndex ? (
      <DivCustomCanDrag isPublish={node.node?.publish}>
        <div className="wrap-content">
          <p>
            「{itemMoveCopySelected.node.node?.name || '（空白）'}
            」を選択した階層の下に
            <br />
            ペーストします。
          </p>
        </div>
        <div className="wrap-button-drop">
          <button
            className="button ok button-ok"
            onClick={() => {
              if (node.node && onDrop && node.node?.publish !== 2) {
                setVisible(false);
                onDrop(itemMoveCopySelected.type, itemMoveCopySelected.node, node.node);
              }
            }}
          >
            OK
          </button>
          <button className="button cancel" onClick={() => setVisible(false)}>
            キャンセル
          </button>
        </div>
      </DivCustomCanDrag>
    ) : (
      <DivCustomCanDrag>
        <div className="wrap-content">
          <p>アクションを選択してください</p>
        </div>
        <div className="wrap-button">
          <button
            disabled={node.node!.publish === 2 || permissionNumber !== 2}
            className={node.node?.publish === 2 || permissionNumber !== 2 ? 'disabled' : 'button'}
            onClick={() => handleSelectModeDrag(node, 'move')}
          >
            <img src={MoveWhite} alt="move-icon" />
            移動
          </button>
          <button
            disabled={permissionNumber !== 2}
            className={permissionNumber !== 2 ? 'disabled' : 'button'}
            onClick={() => handleSelectModeDrag(node, 'copy')}
          >
            <img src={CopyWhite} alt="copy-icon" />
            コピー
          </button>
        </div>
      </DivCustomCanDrag>
    );
  }

  return null;
};

export default TooltipRowContent;
