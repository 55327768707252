import styled from 'styled-components';

type Props = {
  hasImage: boolean;
};
export const SectionStyled = styled.div<Props>`
  @media (max-width: 1440px) {
    .form {
      .form-input {
        .input {
          height: 40px !important;
        }
      }
    }
  }
  .sub-title {
    font-size: 13px;
    text-align: center;
    color: #424242;
    margin-bottom: 30px;
    font-weight: 400;
  }
  .form {
    .form-input {
      .ant-input-suffix {
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 99;
      }
    }
    .require {
      color: #f0330a;
    }
    .text-label {
      font-size: 13px;
      font-weight: 400;
    }
    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .form-upload {
      position: relative;
      border: 3px solid #ffffff;
      .icon-prev,
      .icon-next {
        color: #c4c4c4;
        font-size: 25px;
        cursor: pointer;
      }
      .icon-prev {
        position: absolute;
        left: -30px;
        top: calc(465px / 2);
        z-index: 9;
      }
      .icon-next {
        position: absolute;
        right: -30px;
        top: calc(465px / 2);
        z-index: 9;
      }
    }
    .file-upload {
      display: flex;
      flex-direction: column;
      min-height: 260px;
      border: 2px dashed #d9d9d9;
      background-color: #efefef;
      justify-content: center;
      padding: 20px 0;
      .info-file {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px 0;
        position: relative;
        .svg-object {
          width: 100%;
          max-height: 338px;
        }
        .name-image {
          font-size: 13px;
          text-align: center;
          margin-bottom: 10px;
          color: #424242;
        }
        .image-file {
          height: 338px;
        }
        .ant-image-mask {
          .zoom-image {
            font-size: 18px;
            color: #c4c4c4;
            position: absolute;
            right: 20px;
            bottom: 20px;
            z-index: 99;
            cursor: pointer;
          }
        }
      }
    }
    .ant-upload-list {
      display: none;
    }
    .note {
      color: #777777;
      font-size: 11px;
    }
  }
  .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 20px;
    .icon-delete-outlined {
      color: #00a3a5;
      font-size: 15px;
      margin-right: 5px;
    }
    .text-delete-outlined {
      font-size: 12px;
      text-decoration: underline;
      color: #00989a;
    }
  }
  .btn-upload {
    background: #ffffff;
    border: 1px solid #00a3a5;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00a3a5;
    padding: 6px 36px;
    cursor: pointer;
  }
  .sub-title {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .label-image {
    font-size: 13px;
    color: #222222;
    padding-top: 25px;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sub-title {
    font-size: 13px;
    text-align: center;
    color: #424242;
    margin-bottom: 30px;
    font-weight: 400;
  }
  .ant-upload.ant-upload-drag {
    background: none !important;
    border: none !important;
  }

  .wrap-button-upload {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
`;
