import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { useFormik } from 'formik';
import { includes } from 'lodash';

import {
  handleGetFileQuestionDetailPartner,
  handleGetQuestionDetailPartner,
} from 'libs/utils/question';
import { questionMasterSelector } from 'pages/QuestionMaster/selectors';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import { NoImageCurriculum } from 'assets';
import * as Types from 'types';

type Props = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  code: string | undefined;
  visible: boolean;
};

const CurriculumDetail: React.FC<Props> = ({ visible, setVisible, code }) => {
  const dispatch = useAppDispatch();

  const { timeLimit } = useSelector(questionMasterSelector);

  const renderMediaItem = (url: string, index: number) => {
    const isVideo = includes(url, 'mp4');
    return isVideo ? (
      <div className="image" key={index}>
        <ReactPlayer
          width={'100%'}
          height={'100%'}
          url={url}
          controls
          config={{
            file: {
              attributes: {
                disablePictureInPicture: true,
                controlsList: 'nodownload noplaybackrate',
              },
            },
          }}
        />
      </div>
    ) : (
      <div className="image" key={index}>
        <img src={url || NoImageCurriculum} className="image-question" alt={`attach${index}`} />
      </div>
    );
  };

  const formik = useFormik<Types.CreateEditQuestionFormik>({
    initialValues: {
      name: '',
      description: '',
      version_number: Number(1),
      original_question_code: '',
      problems1: '',
      problems2: '',
      problems3: '',
      answer: '',
      time_limit: '',
      comment: '',
      score: 1,
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
      attachFileID: [],
      commentAttachFileID: [],
      problems1AttachFileID: [],
      problems2AttachFileID: [],
      problems3AttachFileID: [],
      commentFileID1: [],
      commentFileID2: [],
      commentFileID3: [],
      attachFileID1: [],
      attachFileID2: [],
      attachFileID3: [],
      comment_attach1: [],
      comment_attach2: [],
      comment_attach3: [],
      attach1: [],
      attach2: [],
      attach3: [],
    },
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (values) => {},
  });

  const closeModal = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (code) {
      handleGetQuestionDetailPartner({
        dispatch,
        handleCancel,
        type: 'edit',
        i_id: code,
        formik,
      });
      handleGetFileQuestionDetailPartner({
        dispatch,
        handleCancel,
        type: 'edit',
        i_id: code,
        formik,
      });
    }

    return () => {
      formik.setValues({
        name: '',
        description: '',
        problems1: '',
        problems2: '',
        problems3: '',
        answer: '',
        time_limit: '',
        comment: '',
        score: Number(0),
        attach: [],
        comment_attach: [],
        problems1_attach: [],
        problems2_attach: [],
        problems3_attach: [],
        attachFileID: [],
        commentAttachFileID: [],
        problems1AttachFileID: [],
        problems2AttachFileID: [],
        problems3AttachFileID: [],
        commentFileID1: [],
        commentFileID2: [],
        commentFileID3: [],
        attachFileID1: [],
        attachFileID2: [],
        attachFileID3: [],
        comment_attach1: [],
        comment_attach2: [],
        comment_attach3: [],
        attach1: [],
        attach2: [],
        attach3: [],
        version_number: Number(1),
        original_question_code: '',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, code]);

  return (
    <Modal
      open={visible}
      title="設問詳細"
      cancelButton={{
        text: '閉じる',
        onClick: () => closeModal(),
      }}
      width={860}
      onCancel={() => closeModal()}
      footerStyle={{
        background: '#f9f8f8',
      }}
      bodyStyle={{
        background: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <div className="indexing">
          <div className="index-number">
            <span>1</span>
          </div>
          <span className="title">設問内容</span>
        </div>
        <div className="item">
          <span className="text-label">設問名</span>
          <div className="wrap-value">
            <span className="value">{formik.values.name}</span>
          </div>
        </div>
        <div className="item">
          <span className="text-label">設問内容</span>
          <div className="wrap-value description">
            <span className="value">{formik.values.description}</span>
          </div>
        </div>
        <div className="item">
          <div className="wrap-value row question-image">
            {[1, 2, 3].map((index) => {
              const attachKey: 'attach1' | 'attach2' | 'attach3' = `attach${index as 1 | 2 | 3}`;
              return (
                <div className="image-item" key={index}>
                  <p className="title">{`解説画像・動画 ${
                    index === 3 ? '③' : index === 2 ? '②' : '①'
                  }`}</p>
                  {renderMediaItem(formik.values[attachKey][0] as unknown as string, index)}
                </div>
              );
            })}
          </div>
        </div>

        <div className="indexing">
          <div className="index-number">
            <span>2</span>
          </div>
          <span className="title">解答選択肢</span>
        </div>
        {[1, 2, 3].map((index) => {
          const problemKey: 'problems3' | 'problems2' | 'problems1' = `problems${
            index as 1 | 2 | 3
          }`;
          const attachKey:
            | 'problems3_attach'
            | 'problems2_attach'
            | 'problems1_attach' = `problems${index as 1 | 2 | 3}_attach`;
          return (
            <div className="item" key={index}>
              <span className="text-label">
                選択肢{index === 3 ? 'C' : index === 2 ? 'B' : 'A'}
              </span>
              <div className="wrap-value row answer">
                <span className="value">{formik.values[problemKey]}</span>
                {renderMediaItem(formik.values[attachKey][0] as unknown as string, index)}
              </div>
            </div>
          );
        })}
        <div className="item">
          <span className="text-label">解答</span>
          <div className="wrap-value small-width">
            <span className="value">選択肢{formik.values.answer}</span>
          </div>
        </div>

        <div className="indexing">
          <div className="index-number">
            <span>3</span>
          </div>
          <span className="title">解説・その他設定</span>
        </div>
        <div className="item">
          <span className="text-label">解説</span>
          <div className="wrap-value">
            <span className="value description">{formik.values.comment}</span>
          </div>
        </div>
        <div className="item">
          <div className="wrap-value row question-image">
            {[1, 2, 3].map((index) => {
              const attachKey:
                | 'comment_attach1'
                | 'comment_attach2'
                | 'comment_attach3' = `comment_attach${index as 1 | 2 | 3}`;
              return (
                <div className="image-item" key={index}>
                  <p className="title">{`解説画像・動画 ${
                    index === 3 ? '③' : index === 2 ? '②' : '①'
                  }`}</p>
                  {renderMediaItem(formik.values[attachKey][0] as unknown as string, index)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="item">
          <span className="text-label">制限時間</span>
          <div className="wrap-time-limit">
            <div className="wrap-value small-width">
              <span className="value">
                {timeLimit.find((time) => time.option_id === formik.values.time_limit)?.value}
              </span>
            </div>
            <span className="note">※トレーニング用に設定している、設問の制限時間です。 </span>
          </div>
        </div>
        <div className="item">
          <span className="text-label">スコア設定（設問難易度の設定）</span>
          <div className="wrap-value small-width">
            <span className="value">{formik.values.score}</span>
          </div>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default CurriculumDetail;
