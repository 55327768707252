import React from 'react';

import TableMonthlyByUser from './MonthlyByUserDevelopment';
import TableDevelopment from './Development';
import * as Types from 'types';
import { SALE_INFORMATION_REPORT_TYPE } from 'constant/report';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
  valueSelect?: string;
  data: Array<Types.SaleReportInformation.SaleReportInformation>;
  exportPdf?: boolean;
}

const Table: React.FC<Props> = ({
  visibleExpansionOfSale,
  visibleCountUsage,
  valueSelect,
  data,
  exportPdf,
}) => {
  return !valueSelect ||
    ![
      SALE_INFORMATION_REPORT_TYPE.MONTHLY_TRENDS_BY_USER.key,
      SALE_INFORMATION_REPORT_TYPE.CUMULATIVE_TRENDS_BY_USER.key,
    ].includes(valueSelect) ? (
    <TableDevelopment
      visibleExpansionOfSale={visibleExpansionOfSale}
      visibleCountUsage={visibleCountUsage}
      data={data}
      exportPdf={exportPdf}
    />
  ) : (
    <TableMonthlyByUser
      visibleExpansionOfSale={visibleExpansionOfSale}
      visibleCountUsage={visibleCountUsage}
      data={data}
    />
  );
};

export default Table;
