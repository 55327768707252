import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { HEADER_BILLING_DATA_CSV, HEADER_EMPLOYEE_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import CompletedDeleteModal from 'components/Modal/CompletedDelete';
import { settingSelector } from 'containers/AppSettings/selectors';
import PDFBillingDataManagement from './PDFBillingDataManagement';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import PopupConfirmExportFile from './Modal/ConfirmExportModal';
import { Header, PagingNumber, SelectField } from 'components';
import ActionErrorModal from 'components/Modal/ActionError';
import { billingDataManagementSelector } from './selectors';
import { SELECT_RECORD } from 'constant/select.constants';
import { authSelector } from 'containers/Auth/selectors';
import PerPageSelect from 'components/PerPageSelect';
import UploadCSV from 'components/Modal/UploadCSV';
import BillingDataManagementStyled from './styles';
import { getDataBillingManagement } from './thunk';
import { loadingRef } from 'components/Loading';
import { useAppDispatch } from 'hooks';
import { reduce } from 'lodash';
import * as Types from 'types';
import {
  ModalDetailCorporateCreateCardErr,
  ModalDetailCorporate,
  ModalErrorAllDetail,
  CorporationInvoice,
  ModalErrorDetail,
  ModalAppBilling,
} from './Modal';
import { exportCsv } from 'libs/utils/exportCsv';

const { Option } = Select;

const BillingDataManagement: React.FC = () => {
  const [openModalCorporationInvoice, setOpenModalCorporationInvoice] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.BillingDataManagement.ResponseType[]>([]);
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [openModalDetailCorporate, setOpenModalDetailCorporate] = useState<boolean>(false);
  const [dataRecord, setDataRecord] = useState<Types.BillingDataManagement.ResponseType>();
  const [openModalErrorAllDetail, setOpenModalErrorAllDetail] = useState<boolean>(false);
  const [openModalDeleteComplete, setOpenModalDeleteComplete] = useState<boolean>(false);
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [openModalErrorDetail, setOpenModalErrorDetail] = useState<boolean>(false);
  const [openModalAppBilling, setOpenModalAppBilling] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [openModalDetailCorporateCreateCardErr, setOpenModalDetailCorporateCreateCardErr] =
    useState<boolean>(false);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [dataRecordError, setDataRecordError] =
    useState<Types.BillingDataManagement.ResponseType>();

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    dataBillingManagement,
    dataError,
    dataNumError,
    dataSelectProviderDate,
    loading,
    dataSelectBilling,
  } = useSelector(billingDataManagementSelector);
  const dispatch = useAppDispatch();
  const handleChangePage = (value: number) => {
    setPage(value);
    setSelectedRow([]);
  };

  const columns: ColumnsType<Types.BillingDataManagement.ResponseType> = [
    {
      title: (
        <span className="text-left">
          役務
          <br />
          提供年月
        </span>
      ),
      className: 'name-left',
      dataIndex: 'provided_date',
      key: 'provided_date',
      width: '7%',
      render: (text: string) =>
        text ? <span>{dayjs(text).format('YYYY/MM')}</span> : <span>-</span>,
    },
    {
      title: '請求データ番号',
      dataIndex: 'billing_id',
      key: 'billing_id',
      width: '10%',
      render: (text: string) => (text ? <span className="billing">{text}</span> : <span>-</span>),
    },
    {
      title: '名称',
      className: 'name-left',
      dataIndex: 'billing_data_name',
      key: 'billing_data_name',
      width: '18%',
    },
    {
      title: (
        <span className="text-left">
          ユーザー
          <br />
          種類
        </span>
      ),
      dataIndex: 'user_type',
      key: 'user_type',
      width: '6%',
      render: (user_type: number) => (user_type === 1 ? <span>法人</span> : <span>個人</span>),
    },
    {
      title: '決済手段',
      dataIndex: 'payment_method',
      key: 'payment_method',
      width: '8%',
      render: (payment_method: number) =>
        payment_method === 1 ? (
          <span>クレジット</span>
        ) : payment_method === 2 ? (
          <span>請求書</span>
        ) : (
          <span>App内課金</span>
        ),
    },
    {
      title: '請求種類',
      dataIndex: 'billing_type',
      key: 'billing_type',
      width: '10%',
      render: (billing_type: number) =>
        billing_type === 1 ? (
          <span>通常請求</span>
        ) : (
          <span className="text-active">エラー請求</span>
        ),
    },
    {
      title: '請求月',
      dataIndex: 'billing_month',
      key: 'billing_month',
      width: '7%',
      render: (text: string) =>
        text ? <span>{dayjs(text).format('YYYY/MM')}</span> : <span>-</span>,
    },
    {
      title: (
        <span className="text-left">
          請求件数
          <br />
          請求金額
        </span>
      ),
      dataIndex: 'num_of_billings',
      key: 'num_of_billings',
      width: '14%',
      className: 'name-right',
      render: (text: string, item) =>
        item && (
          <>
            <span>{Number(text || 0).toLocaleString()}件</span>
            <br />
            <span>{Number(item.amount_total || 0).toLocaleString()}円</span>
          </>
        ),
    },
    {
      title: 'エラー件数',
      dataIndex: 'num_of_errors',
      key: 'num_of_errors',
      className: 'name-right',
      width: '8%',
      render: (text: number, record) =>
        text ? (
          <span
            onClick={() => {
              setOpenModalErrorDetail(true);
              setDataRecordError(record);
            }}
            className="number-active"
          >
            {text}件
          </span>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: '再送信',
      dataIndex: 'resend',
      key: 'resend',
      width: '7%',
      className: 'resend-style',
      render: (text: string) =>
        text ? <span>{Number(text || 0).toLocaleString()}件</span> : <span>-</span>,
    },
    {
      title: '明細',
      dataIndex: 'operation',
      width: '5%',
      render: (_: string, record) => (
        <FileTextOutlined
          onClick={() => {
            setDataRecord(record);
            if (record.payment_method === 1 && record.billing_type === 1) {
              setOpenModalDetailCorporate(true);
            }
            if (record.payment_method === 1 && record.billing_type !== 1) {
              setOpenModalDetailCorporateCreateCardErr(true);
            }
            if (record.payment_method === 2) {
              setOpenModalCorporationInvoice(true);
            }
            if (record.payment_method === 3) {
              setOpenModalAppBilling(true);
            }
          }}
          className="icon"
        />
      ),
    },
  ];

  const errorResend = useMemo(
    () => reduce(dataBillingManagement, (prev, next) => prev + (next.resend || 0), 0),
    [dataBillingManagement]
  );

  const formik = useFormik({
    initialValues: {
      provided_date: '',
      status: '',
      user_type: undefined,
      payment_method: undefined,
      billing_type: undefined,
      billing_id: undefined,
    },
    onSubmit: async (values) => {
      if (userInfo) {
        const conditions: Array<Types.ConditionsType> = [];
        Object.keys(values).forEach((key) => {
          if (values[key as keyof typeof values]) {
            conditions.push({
              id: key,
              search_value: [String(values[key as keyof typeof values])],
              exact_match: true,
            });
          }
        });
        await dispatch(
          getDataBillingManagement({
            ...(conditions.length ? { conditions } : {}),
            page,
            per_page: 0,
            use_display_id: true,
            include_item_ref: true,
          })
        );
        setPage(1);
      }
    },
    onReset: () => {
      dispatch(
        getDataBillingManagement({
          include_lookups: true,
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
        })
      );
      setPage(1);
    },
  });

  const handleExportCSV = async (value: string) => {
    // const resultAction = await dispatch(
    //   getBillingDataDetailExport({
    //     conditions: [
    //       {
    //         id: 'billing_id',
    //         search_value: [selectedRow.map((row) => row.billing_id).join('|')],
    //       },
    //     ],
    //     page: 1,
    //     per_page: 0,
    //     use_display_id: true,
    //     include_item_ref: true,
    //     use_or_condition: true,
    //   })
    // );
    if (value === 'csv') {
      const listCsv = selectedRow.map((item) => ({
        provided_date: dayjs(item.provided_date).format('YYYY/MM'),
        billing_id: item.billing_id,
        billing_data_name: item.billing_data_name,
        user_type: item.user_type === 1 ? '法人' : '個人',
        payment_method:
          item.payment_method === 1
            ? 'クレジット'
            : item.payment_method === 2
            ? '請求書'
            : 'App内課金',
        billing_type: item.billing_type === 1 ? '通常請求' : 'エラー請求',
        billing_month: dayjs(item.billing_month).format('YYYY/MM'),
        num_of_claim: Number(item.num_of_billings || 0)
          .toLocaleString()
          .toString(),
        num_of_billings: Number(item.amount_total || 0)
          .toLocaleString()
          .toString(),
        num_of_errors: item.num_of_errors || '-',
        resend: item.resend || '-',
      }));

      exportCsv(listCsv, HEADER_BILLING_DATA_CSV, '請求データ管理画面.csv');

      // const csvString = [
      //   HEADER_BILLING_DATA_CSV.map(({ label }) => label),
      //   ...listCsv.map((item) => Object.values(item).map((v) => `"${v}"`)),
      // ]
      //   .map((e) => e.join(','))
      //   .join('\n');
      // const bom = '\uFEFF';
      // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      // saveAs(file, '請求データ管理画面.csv');
    } else {
      const blob = await pdf(
        <PDFBillingDataManagement
          dataBillingDetail={selectedRow}
          provided_date={formik.values.provided_date}
          user_type={formik.values.user_type}
          status={formik.values.status}
          payment_method={formik.values.payment_method}
          billing_type={formik.values.billing_type}
          billing_id={formik.values.billing_id}
          total={dataBillingManagement.length}
          perPage={perPage}
          page={page}
        />
      ).toBlob();
      saveAs(blob, '請求データ管理画面.pdf');
      dispatch(stopLoading());
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const fetchDataSelectBilling = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(
      getDataBillingManagement({
        include_lookups: true,
        page: 1,
        per_page: 0,
        use_display_id: true,
        include_item_ref: true,
      })
    );
    dispatch(stopLoading());
  }, [dispatch]);

  useEffect(() => {
    fetchDataSelectBilling();
  }, [fetchDataSelectBilling]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(dataBillingManagement.length);
    }
  }, [dataBillingManagement]);

  return (
    <BillingDataManagementStyled
      collapsedMenu={collapsedMenu}
      isEmptyData={!dataBillingManagement.length}
    >
      <Header title={headerTitle} />
      <div className="billing-wrapper">
        <p className="text-note">
          請求データの管理画面です。請求データの確認・ダウンロードが可能です。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="provided_date"
                className="item"
                label={<span className="text-label">役務提供年月</span>}
              >
                <SelectField
                  data-testid="provided-date-select"
                  name="provided_date"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectProviderDate?.map((item, index) => (
                    <Option key={index} value={item.provided_date}>
                      {item.provided_date}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                data-testid="billing-id-select"
                name="billing_id"
                className="item"
                label={<span className="text-label">請求データ番号</span>}
              >
                <SelectField name="billing_id" showSearch allowClear>
                  {dataSelectBilling.map((item, index) => (
                    <Option key={index} value={item.billing_id}>
                      {item.billing_id}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="user_type"
                className="item"
                label={<span className="text-label">ユーザー種類</span>}
              >
                <SelectField
                  data-testid="user-type-select"
                  name="user_type"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={1}>法人</Option>
                  <Option value={2}>個人</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="payment_method"
                className="item"
                label={<span className="text-label">決済手段</span>}
              >
                <SelectField
                  data-testid="payment-method-select"
                  name="payment_method"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={1}>クレジット</Option>
                  <Option value={2}>請求書</Option>
                  <Option value={3}>App内課金</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="billing_type"
                className="item"
                label={<span className="text-label">請求種類</span>}
              >
                <SelectField
                  data-testid="billing-type-select"
                  name="billing_type"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={1}>通常請求</Option>
                  <Option value={9}>エラー請求</Option>
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-button">
          <p onClick={() => setOpenModalErrorAllDetail(true)} className="count-error">
            未送信エラー件数：
            <span className="number-active">{Number(dataNumError || 0) - errorResend}件</span>
          </p>
        </div>
        <Table
          data-testid="table-billing-management"
          rowKey="i_id"
          className="table"
          dataSource={dataBillingManagement}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows: Types.BillingDataManagement.ResponseType[]) =>
              setSelectedRow(selectedRows),
          }}
          pagination={{
            pageSize: perPage,
            total: dataBillingManagement.length,
            current: page,
            onChange: handleChangePage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <div className="wrap-select-record">
                <PagingNumber
                  startItem={dataBillingManagement.length ? `${(page - 1) * perPage + 1}` : ''}
                  endItem={
                    page * perPage > dataBillingManagement.length
                      ? dataBillingManagement.length
                      : page * perPage
                  }
                  totalItem={dataBillingManagement.length}
                />
                <PerPageSelect
                  data={dataBillingManagement}
                  perPage={perPage}
                  setPage={setPage}
                  setPerPage={setPerpage}
                />
              </div>
            ),
          }}
        />
        <UploadCSV
          onSubmit={() => {}}
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headersTemplate={HEADER_EMPLOYEE_CSV}
          fileName="template_import_internal_user"
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="請求データが選択されていません"
          description={
            <>
              エクスポートを実行する請求データを選択して、
              <br />
              再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={() => setOpenModalDeleteComplete(true)}
          setVisible={setVisiblePopupConfirmDelete}
        />
        <CompletedDeleteModal
          visible={openModalDeleteComplete}
          setVisible={setOpenModalDeleteComplete}
          onSubmit={() => {}}
        />
        <ModalDetailCorporate
          visible={openModalDetailCorporate}
          setVisible={setOpenModalDetailCorporate}
          dataRecord={dataRecord}
          onSubmit={() => {}}
        />
        <ModalDetailCorporateCreateCardErr
          visible={openModalDetailCorporateCreateCardErr}
          setVisible={setOpenModalDetailCorporateCreateCardErr}
          dataRecord={dataRecord}
          onSubmit={() => {}}
        />
        <ModalErrorDetail
          visible={openModalErrorDetail}
          setVisible={setOpenModalErrorDetail}
          dataRecord={dataRecordError}
          onSubmit={() => {}}
        />
        <ModalErrorAllDetail
          visible={openModalErrorAllDetail}
          setVisible={setOpenModalErrorAllDetail}
          dataError={dataError}
          onSubmit={() => {}}
        />
        <CorporationInvoice
          visible={openModalCorporationInvoice}
          setVisible={setOpenModalCorporationInvoice}
          dataRecord={dataRecord}
        />
        <ModalAppBilling
          visible={openModalAppBilling}
          setVisible={setOpenModalAppBilling}
          dataRecord={dataRecord}
          onSubmit={() => {}}
        />
      </div>
      <div className="wrap-bottom">
        <div className="flex">
          <div className="text-label">
            選択した請求データを処理：
            <Button className="btn btn-active" onClick={handleButtonExport}>
              エクスポート
            </Button>
          </div>
        </div>
      </div>
    </BillingDataManagementStyled>
  );
};

export default BillingDataManagement;
