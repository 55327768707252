import React, { useMemo } from 'react';
import { Table } from 'antd';

import TableSkillCheckInformationStyled from './styles';
import { ColumnType } from 'antd/lib/table';
import { SpinLoading } from 'components';
import { reportSelector } from 'pages/Report/selectors';
import { useSelector } from 'react-redux';
import { SkillCheckInformationData } from 'types';
import { sortBy, uniqBy } from 'lodash';
import styled from 'styled-components';

interface Props {
  visibleCorrespondingSale: boolean;
}

export interface SkillCheckInformationDataCustom extends SkillCheckInformationData {
  children: Array<SkillCheckInformationData>;
  show_text: '売上' | '実地回数';
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  background: #ffffff;
  width: 100%;
  height: 250px;
  color: #777777;
  font-size: 16px;
  border-radius: 3px;
`;

const convertToFormattedDateForField = (input: string): string => {
  const year = input.slice(0, 4);
  const month = input.slice(4, 6);
  return `${year}${month}`;
};

const TableSkillCheckInformation: React.FC<Props> = ({ visibleCorrespondingSale }) => {
  const { loading, dataSkillCheckInformation, listMonths } = useSelector(reportSelector);

  const date = useMemo(() => {
    return listMonths.map((item, index) => ({
      title: item.title,
      dataIndex: item.date,
      key: item.date,
      width: '6%',
      render: (text: string) => {
        return <span>{text || '-'}</span>;
      },
      onCell: () => ({
        className: 'text-right',
      }),
      onHeaderCell: () => ({
        className: 'text-center',
      }),
    }));
  }, [listMonths]);

  const getData = useMemo(() => {
    const sortArray = sortBy(dataSkillCheckInformation, ['rev_no']);

    // make group
    const groupArray = sortArray.reduce(
      (acc: any, item) => {
        switch (item.user_type) {
          case '社内ユーザー': {
            return {
              ...acc,
              社内ユーザー: [
                ...acc.社内ユーザー,
                {
                  ...item,
                  [convertToFormattedDateForField(item.target_month)]:
                    item.number_of_skill_checks_performed,
                },
              ],
            };
          }
          case '面接ユーザー': {
            return {
              ...acc,
              面接ユーザー: [
                ...acc.面接ユーザー,
                {
                  ...item,
                  [convertToFormattedDateForField(item.target_month)]:
                    item.number_of_skill_checks_performed,
                },
              ],
            };
          }
          default: {
            return {
              ...acc,
              全ユーザー: [
                ...acc.全ユーザー,
                {
                  ...item,
                  [convertToFormattedDateForField(item.target_month)]:
                    item.number_of_skill_checks_performed,
                },
              ],
            };
          }
        }
      },
      {
        社内ユーザー: [],
        全ユーザー: [],
        面接ユーザー: [],
      }
    );

    // case 1 group by "user_type"
    const 社内ユーザー = groupArray.社内ユーザー.reduce(
      (acc: any, item: SkillCheckInformationData) => {
        const dateField = convertToFormattedDateForField(item.target_month);
        const other = acc[`${dateField}`] ?? 0;
        const value = item.number_of_skill_checks_performed + other;
        return {
          ...item,
          ...acc,
          [dateField]: value,
        };
      },
      {}
    );

    const 社内ユーザーPhaseSecond = groupArray.社内ユーザー.reduce(
      (acc: any, item: SkillCheckInformationData) => {
        const dateField = convertToFormattedDateForField(item.target_month);
        const other = acc[`${dateField}`] ?? 0;
        const value = item.skill_check_sales + other;
        return {
          ...item,
          ...acc,
          [dateField]: value,
        };
      },
      {}
    );

    const 全ユーザー = groupArray.全ユーザー.reduce((acc: any, item: SkillCheckInformationData) => {
      const dateField = convertToFormattedDateForField(item.target_month);
      const other = acc[`${dateField}`] ?? 0;
      const value = item.number_of_skill_checks_performed + other;
      return {
        ...item,
        ...acc,
        [dateField]: value,
      };
    }, {});

    const 全ユーザーPhaseSecond = groupArray.全ユーザー.reduce(
      (acc: any, item: SkillCheckInformationData) => {
        const dateField = convertToFormattedDateForField(item.target_month);
        const other = acc[`${dateField}`] ?? 0;
        const value = item.skill_check_sales + other;
        return {
          ...item,
          ...acc,
          [dateField]: value,
        };
      },
      {}
    );

    const 面接ユーザー = groupArray.面接ユーザー.reduce(
      (acc: any, item: SkillCheckInformationData) => {
        const dateField = convertToFormattedDateForField(item.target_month);
        const other = acc[`${dateField}`] ?? 0;
        const value = item.number_of_skill_checks_performed + other;
        return {
          ...item,
          ...acc,
          [dateField]: value,
        };
      },
      {}
    );

    const 面接ユーザーPhaseSecond = groupArray.面接ユーザー.reduce(
      (acc: any, item: SkillCheckInformationData) => {
        const dateField = convertToFormattedDateForField(item.target_month);
        const other = acc[`${dateField}`] ?? 0;
        const value = item.skill_check_sales + other;
        return {
          ...item,
          ...acc,
          [dateField]: value,
        };
      },
      {}
    );

    let FirstCase: any = [];
    if (groupArray.全ユーザー.length) {
      FirstCase = [...FirstCase, 全ユーザー];
    }
    if (groupArray.社内ユーザー.length) {
      FirstCase = [...FirstCase, 社内ユーザー];
    }
    if (groupArray.面接ユーザー.length) {
      FirstCase = [...FirstCase, 面接ユーザー];
    }

    const second_case_internal_user = sortArray.find((i) => {
      return i.user_type === '社内ユーザー';
    });

    let second_case_internal_user_result: SkillCheckInformationDataCustom;

    let secoundCase: any = [];

    const second_case_user = sortArray.find((i) => {
      return i.user_type === '全ユーザー';
    });

    const userId = second_case_user ? second_case_user.i_id : null;
    let second_case_user_result: SkillCheckInformationDataCustom;
    if (userId && second_case_user) {
      const children = {
        ...全ユーザー,
        user_type: '',
        show_text: '実施回数',
      };

      second_case_user_result = {
        ...全ユーザーPhaseSecond,
        show_text: '売上',
        children: [children],
      };

      secoundCase = [...secoundCase, second_case_user_result];
    }

    const second_case_interviewer_user = sortArray.find((i) => {
      return i.user_type === '面接ユーザー';
    });

    const internalUserId = second_case_internal_user ? second_case_internal_user.i_id : null;
    if (internalUserId && second_case_internal_user) {
      const children = {
        ...社内ユーザー,
        user_type: '',
        show_text: '実施回数',
      };

      second_case_internal_user_result = {
        ...社内ユーザーPhaseSecond,
        show_text: '売上',
        children: [children],
      };

      secoundCase = [...secoundCase, second_case_internal_user_result];
    }

    const interviewerUserId = second_case_interviewer_user
      ? second_case_interviewer_user.i_id
      : null;
    let second_case_interview_user_result: SkillCheckInformationDataCustom;

    if (interviewerUserId && second_case_interviewer_user) {
      const children = {
        ...面接ユーザー,
        user_type: '',
        show_text: '実施回数',
      };

      second_case_interview_user_result = {
        ...面接ユーザーPhaseSecond,
        show_text: '売上',
        children: [children],
      };

      secoundCase = [...secoundCase, second_case_interview_user_result];
    }

    return {
      FirstCase: FirstCase,
      secoundCase: secoundCase,
    };
  }, [dataSkillCheckInformation]);

  const column: ColumnType<any>[] = [
    {
      title: () => (
        <div
          className="title"
          style={{
            width: 150,
          }}
        >
          <span>ユーザー種類</span>
        </div>
      ),
      dataIndex: 'user_type',
      key: 'user_type',
      width: '100px',
      align: 'left',
      fixed: 'left',
    },
    ...date,
  ];
  const dynamicColumn: ColumnType<any>[] = [
    {
      title: () => (
        <div
          className="title"
          style={{
            width: 150,
          }}
        >
          <span>ユーザー種類</span>
        </div>
      ),
      className: 'col-1-fixed',
      dataIndex: 'user_type',
      key: 'user_type',
      align: 'left',
      fixed: 'left',
    },
    {
      title: () => (
        <div
          className="title"
          style={{
            width: 150,
            textAlign: 'center',
          }}
        >
          <span>項目名</span>
        </div>
      ),
      className: 'col-2-fixed',
      fixed: 'left',
      dataIndex: 'show_text',
      render: (text: any, _item: any) => (
        <div className="name" style={{ textAlign: 'center' }}>
          {text}
        </div>
      ),
    },
    ...date,
  ];

  if (!dataSkillCheckInformation.length) {
    return (
      <>
        <Container>集計条件を選択してください</Container>
      </>
    );
  }

  return (
    <SpinLoading loading={loading}>
      <TableSkillCheckInformationStyled visibleCorrespondingSale={visibleCorrespondingSale}>
        <Table
          className="table"
          rowKey="code"
          columns={visibleCorrespondingSale ? dynamicColumn : column}
          dataSource={visibleCorrespondingSale ? getData.secoundCase : getData.FirstCase}
          expandable={{
            defaultExpandAllRows: true,
          }}
          pagination={false}
          locale={{
            emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
          }}
        />
      </TableSkillCheckInformationStyled>
    </SpinLoading>
  );
};

export default TableSkillCheckInformation;
