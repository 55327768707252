import React, { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { includes } from 'lodash';

import { handleGetFileQuestionDetail, handleGetQuestionDetail } from 'libs/utils/question';
import { questionSelector } from 'containers/CreateEditQuestion/selectors';
import { stopLoading } from 'containers/AppSettings/slice';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { DetailAudio, Modal } from 'components';
import { useFormik } from 'formik';
import { OriginLogoAttach } from 'assets';
import * as Types from 'types';
import { getQuestionDetail } from '../../../../containers/CreateEditQuestion/thunk';
import {
  VALID_MIME_TYPE_VIDEO_QUESTION,
  VIDEO_FILE_EXTENSIONS,
  VALID_MIME_TYPE_AUDIO_QUESTION,
  AUDIO_FILE_EXTENSIONS,
} from 'constant';
import { getFileTypeFromUrl } from 'services/r2Service';
import { Select } from 'antd';

const { Option } = Select;

interface Props {
  visible: { visible: boolean; question_id?: string; original_question_code?: string };
  setVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; question_id?: string }>>;
}

interface ImageProps {
  url: string;
  index: number;
}

const QuestionDetail: React.FC<Props> = ({ visible, setVisible }) => {
  const dispatch = useAppDispatch();
  const [totalVersion, setTotalVersion] = useState<number>(1);
  const [idsVersion, setIdsVersion] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentIdVersion, setCurrentIdVersion] = useState<string>('');
  const CustomHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p
          style={{
            margin: 0,
          }}
        >
          設問編集
        </p>
        <div
          style={{
            width: '240px',
            right: 15,
            position: 'absolute',
            fontSize: '15px',
          }}
        >
          <span className="text-label">他のバージョン:</span>
          <Select
            className="version-select"
            style={{ width: '112px', marginLeft: '10px' }}
            value={currentPage}
            onChange={handlePageChange}
          >
            {Array.from({ length: totalVersion }, (_, index) => (
              <Option key={index + 1} value={index + 1}>
                {index + 1}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  const { time_limit } = useSelector(questionSelector);

  const handleCancel = () => {
    setVisible({
      visible: false,
    });
  };

  const formik = useFormik<Types.CreateEditQuestionFormik>({
    initialValues: {
      name: '',
      description: '',
      version_number: Number(1),
      original_question_code: '',
      problems1: '',
      problems2: '',
      problems3: '',
      answer: '',
      time_limit: '',
      comment: '',
      score: 0,
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
      attachFileID: [],
      commentAttachFileID: [],
      problems1AttachFileID: [],
      problems2AttachFileID: [],
      problems3AttachFileID: [],
      commentFileID1: [],
      commentFileID2: [],
      commentFileID3: [],
      attachFileID1: [],
      attachFileID2: [],
      attachFileID3: [],
      comment_attach1: [],
      comment_attach2: [],
      comment_attach3: [],
      attach1: [],
      attach2: [],
      attach3: [],
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const ImageComponent: FC<ImageProps> = ({ url, index }) => {
    return (
      <div className="image" key={index}>
        {url ? (
          <img src={url} className="image-question" alt={`attach${index}`} />
        ) : (
          <>
            <img src={OriginLogoAttach} className="no-image" alt={`no-image${index}`} />
          </>
        )}
      </div>
    );
  };

  const RenderMediaItem = (url: string, index: number) => {
    const [fileType, setFileType] = useState<string>('');

    const isVideo = url
      ? includes(VALID_MIME_TYPE_VIDEO_QUESTION, url) ||
        includes(fileType as unknown as string, 'video') ||
        VIDEO_FILE_EXTENSIONS.some((e) => url.includes(e))
      : false;

    const isAudio = url
      ? includes(VALID_MIME_TYPE_AUDIO_QUESTION, fileType) ||
        AUDIO_FILE_EXTENSIONS.some((e) => url.includes(e)) ||
        includes(fileType as unknown as string, 'audio')
      : false;

    useEffect(() => {
      (async () => {
        if (url) {
          const checkFileType = await getFileTypeFromUrl(url);
          if (checkFileType) setFileType(checkFileType);
        }
      })();
    }, [url]);

    return isVideo && url ? (
      <div className="image" key={index}>
        <ReactPlayer
          width={'100%'}
          height={'100%'}
          url={url}
          controls
          config={{
            file: {
              attributes: {
                disablePictureInPicture: true,
                controlsList: 'nodownload noplaybackrate',
              },
            },
          }}
        />
      </div>
    ) : isAudio && url ? (
      <div className="image" key={index}>
        <DetailAudio url={url} />
      </div>
    ) : (
      <ImageComponent url={url} index={index} />
    );
  };

  const handlePageChange = (page: number) => {
    const index = page - 1;
    setCurrentIdVersion(idsVersion[index]);
    setCurrentPage(page);
  };

  useEffect(() => {
    (async () => {
      if (visible.original_question_code) {
        const originalQuestionDetail = await dispatch(
          getQuestionDetail({
            page: 1,
            per_page: 0,
            conditions: [
              {
                id: 'original_question_code',
                search_value: [visible.original_question_code],
                exact_match: true,
              },
            ],
          })
        );
        if (getQuestionDetail.fulfilled.match(originalQuestionDetail)) {
          const idVersion = originalQuestionDetail.payload.items.map((item) => item.i_id);
          setCurrentIdVersion(idVersion[0]);
          setIdsVersion(idVersion);
          setTotalVersion(originalQuestionDetail.payload.items.length);
        }
      }
    })();
  }, [visible]);

  useEffect(() => {
    if (!visible.visible || (!visible.question_id && !visible.original_question_code)) return;
    dispatch(stopLoading());
    const i_id = currentIdVersion ? currentIdVersion : visible.question_id;

    handleGetQuestionDetail({
      dispatch,
      handleCancel,
      type: 'edit',
      i_id: i_id,
      original_question_code: visible.original_question_code,
      formik,
    });
    handleGetFileQuestionDetail({
      dispatch,
      handleCancel,
      type: 'edit',
      i_id: i_id,
      original_question_code: visible.original_question_code,
      formik,
    });

    return () => {
      formik.setValues({
        name: '',
        description: '',
        version_number: Number(1),
        original_question_code: '',
        problems1: '',
        problems2: '',
        problems3: '',
        answer: '',
        time_limit: '',
        comment: '',
        score: Number(0),
        attach: [],
        comment_attach: [],
        problems1_attach: [],
        problems2_attach: [],
        problems3_attach: [],
        attachFileID: [],
        commentAttachFileID: [],
        problems1AttachFileID: [],
        problems2AttachFileID: [],
        problems3AttachFileID: [],
        commentFileID1: [],
        commentFileID2: [],
        commentFileID3: [],
        attachFileID1: [],
        attachFileID2: [],
        attachFileID3: [],
        comment_attach1: [],
        comment_attach2: [],
        comment_attach3: [],
        attach1: [],
        attach2: [],
        attach3: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, currentIdVersion]);

  return (
    <Modal
      title={<CustomHeader />}
      width={860}
      visible={visible.visible}
      cancelButton={{
        text: '閉じる',
        onClick: () => {
          setTotalVersion(1);
          setCurrentPage(1);
          setIdsVersion([]);
          setCurrentIdVersion('');
          setVisible({ visible: false });
        },
      }}
      onCancel={() => setVisible({ visible: false })}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <div className="indexing">
          <div className="index-number">
            <span>1</span>
          </div>
          <span className="title">設問内容</span>
        </div>
        <div className="item">
          <span className="text-label">設問名</span>
          <div className="wrap-value">
            <span className="value">{formik.values.name}</span>
          </div>
        </div>
        <div className="item">
          <span className="text-label">設問内容</span>
          <div className="wrap-value description">
            <span className="value">{formik.values.description}</span>
          </div>
        </div>
        <div className="item">
          <div className="wrap-value row question-image">
            {[1, 2, 3].map((index) => {
              const attachKey: 'attach1' | 'attach2' | 'attach3' = `attach${index as 1 | 2 | 3}`;
              return (
                <div className="image-item" key={index}>
                  <p className="title">{`設問画像・動画 ${
                    index === 3 ? '③' : index === 2 ? '②' : '①'
                  }`}</p>
                  {RenderMediaItem(formik.values[attachKey][0] as unknown as string, index)}
                </div>
              );
            })}
          </div>
        </div>

        <div className="indexing">
          <div className="index-number">
            <span>2</span>
          </div>
          <span className="title">解答選択肢</span>
        </div>
        {[1, 2, 3].map((index) => {
          const problemKey: 'problems3' | 'problems2' | 'problems1' = `problems${
            index as 1 | 2 | 3
          }`;
          const attachKey:
            | 'problems3_attach'
            | 'problems2_attach'
            | 'problems1_attach' = `problems${index as 1 | 2 | 3}_attach`;
          return (
            <div className="item" key={index}>
              <span className="text-label">
                選択肢{index === 3 ? 'C' : index === 2 ? 'B' : 'A'}
              </span>
              <div className="wrap-value row answer">
                <span className="value">{formik.values[problemKey]}</span>
                {RenderMediaItem(formik.values[attachKey][0] as unknown as string, index)}
              </div>
            </div>
          );
        })}
        <div className="item">
          <span className="text-label">解答</span>
          <div className="wrap-value small-width">
            <span className="value">選択肢{formik.values.answer}</span>
          </div>
        </div>

        <div className="indexing">
          <div className="index-number">
            <span>3</span>
          </div>
          <span className="title">解説・その他設定 </span>
        </div>
        <div className="item">
          <span className="text-label">解説</span>
          <div className="wrap-value">
            <span className="value description">{formik.values.comment}</span>
          </div>
        </div>
        <div className="item">
          <div className="wrap-value row question-image">
            {[1, 2, 3].map((index) => {
              const attachKey:
                | 'comment_attach1'
                | 'comment_attach2'
                | 'comment_attach3' = `comment_attach${index as 1 | 2 | 3}`;
              return (
                <div className="image-item" key={index}>
                  <p className="title">{`解説画像・動画 ${
                    index === 3 ? '③' : index === 2 ? '②' : '①'
                  }`}</p>
                  {RenderMediaItem(formik.values[attachKey][0] as unknown as string, index)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="item">
          <span className="text-label">制限時間</span>
          <div className="wrap-time-limit">
            <div className="wrap-value small-width">
              <span className="value">
                {time_limit.find((time) => time.option_id === formik.values.time_limit)?.value}
              </span>
            </div>
            <span className="note">※トレーニング用に設定している、設問の制限時間です。 </span>
          </div>
        </div>
        <div className="item">
          <span className="text-label">スコア設定（設問難易度の設定）</span>
          <div className="wrap-value small-width">
            <span className="value">{formik.values.score}</span>
          </div>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default QuestionDetail;
