import React, { useEffect, useMemo, useState } from 'react';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { useSelector } from 'react-redux';
import { Button, message, Upload } from 'antd';
import { debounce, groupBy, includes } from 'lodash';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  WarningFilled,
} from '@ant-design/icons';

import { createFileAttach, deleteFileAttach, editQuestion, getDataAllQuestion } from '../../thunk';
import { deleteFileInR2, uploadFileToR2 } from 'services/r2Service';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import { questionMasterSelector } from '../../selectors';
import { SectionStyled } from './styles';
import { DetailAudio, Modal } from 'components';
import * as Types from 'types';
import { convertQuestionAttachTextToCode } from 'libs/utils/question';
import {
  VALID_MIME_IMAGE_QUESTION,
  VALID_MIME_TYPE_AUDIO_QUESTION,
  VALID_MIME_TYPE_QUESTION,
  VALID_MIME_TYPE_VIDEO_QUESTION,
} from 'constant';
import _ from 'lodash';
import { getQuestionAssignedToCurriculum } from '../../../Curriculum/thunk';
import {
  createQuestion,
  deleteQuestionTrans,
  executeBatchAction,
  getQuestionDetail,
  getQuestionImplementTrans,
} from '../../../../containers/CreateEditQuestion/thunk';
import { QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM } from '../../../../configs';

interface Props {
  visible: boolean;
  headersTemplate?: Array<{ label: string; key: string }>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  fetchData: () => void;
}

const { Dragger } = Upload;

const UploadManyImageVideo: React.FC<Props> = ({ visible, setVisible, fetchData }) => {
  const [isUploadSuccessfully, setUploadSuccessfully] = useState<boolean>(false);
  const [showErrorImages, setShowErrorImages] = useState<boolean>(false);
  const [files, setFiles] = useState<
    Array<{
      file: UploadFile<File>;
      status: boolean;
      code?: string;
      attach?: string;
    }>
  >([]);

  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const { dataAllQuestion } = useSelector(questionMasterSelector);
  const { timeLimit } = useSelector(questionMasterSelector);

  const onClose = () => {
    setVisible(false);
    setFiles([]);
    setUploadSuccessfully(false);
    setShowErrorImages(false);
  };

  const getItemUpdateFile = (
    file: {
      file: UploadFile<File>;
      status: boolean;
      code?: string;
      attach?: string;
    },
    minioId: string
  ) => {
    switch (file.attach) {
      case 'attach1':
        return {
          attach_fileID1: minioId,
          attach_filename1: file.file.name,
        };
      case 'attach2':
        return {
          attach_fileID2: minioId,
          attach_filename2: file.file.name,
        };
      case 'attach3':
        return {
          attach_fileID3: minioId,
          attach_filename3: file.file.name,
        };

      case 'problems1_attach':
        return {
          problems1_attach_fileID: minioId,
          problems1_attach_filename: file.file.name,
        };
      case 'problems2_attach':
        return {
          problems2_attach_fileID: minioId,
          problems2_attach_filename: file.file.name,
        };
      case 'problems3_attach':
        return {
          problems3_attach_fileID: minioId,
          problems3_attach_filename: file.file.name,
        };

      case 'comment_attach1':
        return {
          comment_attach_fileID1: minioId,
          comment_attach_filename1: file.file.name,
        };
      case 'comment_attach2':
        return {
          comment_attach_fileID2: minioId,
          comment_attach_filename2: file.file.name,
        };
      case 'comment_attach3':
        return {
          comment_attach_fileID3: minioId,
          comment_attach_filename3: file.file.name,
        };

      default:
        return {};
    }
  };

  const fileId = (
    file: {
      file: UploadFile<File>;
      status: boolean;
      code?: string | undefined;
      attach?: string | undefined;
    },
    questionFound: Types.Questions.ResponseType
  ) => {
    switch (file.attach) {
      case 'attach1':
        return questionFound.attach_fileID1;
      case 'attach2':
        return questionFound.attach_fileID2;
      case 'attach3':
        return questionFound.attach_fileID3;

      case 'problems1_attach':
        return questionFound.problems1_attach_fileID;
      case 'problems2_attach':
        return questionFound.problems2_attach_fileID;
      case 'problems3_attach':
        return questionFound.problems3_attach_fileID;

      case 'comment_attach1':
        return questionFound.comment_attach_fileID1;
      case 'comment_attach2':
        return questionFound.comment_attach_fileID2;
      case 'comment_attach3':
        return questionFound.comment_attach_fileID3;

      default:
        return '';
    }
  };

  const uploadFileMinioHandler = async (
    chunk: {
      file: UploadFile<File>;
      status: boolean;
      code?: string | undefined;
      attach?: string | undefined;
    }[],
    questionFound: Types.Questions.ResponseType
  ) => {
    const results = await Promise.all(
      chunk.map(async (file) => {
        const uploadToMinIo = await uploadFileToR2(file.file.originFileObj as UploadFile<File>);
        if (uploadToMinIo) {
          const resultActionCreateImages = await dispatch(
            createFileAttach({
              item: {
                fileID: uploadToMinIo,
                filename: file.file.name,
                file_location: '2',
                file_extension: file.file?.type,
                file_size: `${file.file?.size}`,
                company_id: userInfo?.company_id,
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
            })
          );
          if (createFileAttach.fulfilled.match(resultActionCreateImages)) {
            const item = getItemUpdateFile(file, uploadToMinIo);
            const actionResultEditQuestion = await dispatch(
              editQuestion({
                id: questionFound.i_id,
                data: {
                  item,
                  return_item_result: true,
                  is_force_update: true,
                },
              })
            );

            if (editQuestion.fulfilled.match(actionResultEditQuestion)) {
              const fileDelete = fileId(file, questionFound);
              if (fileDelete) {
                await Promise.all([
                  deleteFileInR2(fileId(file, questionFound)),
                  dispatch(
                    deleteFileAttach({
                      conditions: [
                        {
                          id: 'fileID',
                          search_value: [fileId(file, questionFound)],
                          exact_match: true,
                        },
                      ],
                    })
                  ),
                ]);
              }
            } else if (uploadToMinIo) {
              await Promise.all([
                deleteFileInR2(uploadToMinIo),
                dispatch(
                  deleteFileAttach({
                    conditions: [
                      { id: 'fileID', search_value: [uploadToMinIo], exact_match: true },
                    ],
                  })
                ),
              ]);
            }
          } else {
            setShowErrorImages(false);
          }
        }
      })
    );
    return results;
  };

  const handleCreateQuestion = async (
    chunk: {
      file: UploadFile<File>;
      status: boolean;
      code?: string | undefined;
      attach?: string | undefined;
    }[],
    ques: Types.Questions.ResponseType
  ) => {
    const { i_id, original_question_code, code, version_number } = ques;
    if (!i_id) return;
    const responseStatus = await Promise.all([
      dispatch(
        getQuestionAssignedToCurriculum({
          conditions: [
            {
              id: 'code',
              search_value: [code],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getQuestionImplementTrans({
          conditions: [
            {
              id: 'code',
              search_value: [code],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);
    if (
      getQuestionAssignedToCurriculum.fulfilled.match(responseStatus[0]) &&
      getQuestionImplementTrans.fulfilled.match(responseStatus[1])
    ) {
      const item_count_curriculum = responseStatus[0].payload.totalItems;
      if (item_count_curriculum > 0) {
        const resultActionGetQuestionDetail = await dispatch(
          getQuestionDetail({
            page: 1,
            per_page: 1,
            sort_field_id: 'version_number',
            sort_order: 'desc',
            conditions: [
              {
                id: 'original_question_code',
                search_value: [original_question_code],
                exact_match: true,
              },
            ],
          })
        );
        if (getQuestionDetail.fulfilled.match(resultActionGetQuestionDetail)) {
          const latestVersionItem = resultActionGetQuestionDetail.payload.items[0];
          if (Number(version_number) !== Number(latestVersionItem.version_number)) {
            // setShowActionErrorModal({
            //   errorVisible: true,
            //   description: '他の方がすでにこの設問を更新している可能性があります',
            //   subTitle: '最新バージョンの設問ではありません',
            // });
          } else {
            const resultAction = await dispatch(
              createQuestion({
                item: {
                  name: ques.name,
                  description: ques.description,
                  problems1: ques.problems1,
                  problems2: ques.problems2,
                  problems3: ques.problems3,
                  answer: ques.answer,
                  comment: ques.comment,
                  time_limit: timeLimit.find((item) => item.value === ques.time_limit)?.option_id,
                  creator: userInfo?.name,
                  company_id: userInfo?.company_id,
                  score: ques.score,
                  createdat: new Date(),
                  createdby: userInfo?.login_id,
                  original_question_code: original_question_code,
                  version_number: version_number ? Number(version_number) + 1 : 1,
                  attach_fileID1: ques.attach_fileID1,
                  attach_fileID2: ques.attach_fileID2,
                  attach_fileID3: ques.attach_fileID3,
                  problems1_attach_fileID: ques.problems1_attach_fileID,
                  problems2_attach_fileID: ques.problems2_attach_fileID,
                  problems3_attach_fileID: ques.problems3_attach_fileID,
                  comment_attach_fileID1: ques.comment_attach_fileID1,
                  comment_attach_fileID2: ques.comment_attach_fileID2,
                  comment_attach_fileID3: ques.comment_attach_fileID3,
                  attach_filename1: ques.attach_filename1,
                  attach_filename2: ques.attach_filename2,
                  attach_filename3: ques.attach_filename3,
                  comment_attach_filename1: ques.comment_attach_filename1,
                  comment_attach_filename2: ques.comment_attach_filename2,
                  comment_attach_filename3: ques.comment_attach_filename3,
                  problems1_attach_filename: ques.problems1_attach_filename,
                  problems2_attach_filename: ques.problems2_attach_filename,
                  problems3_attach_filename: ques.problems3_attach_filename,
                },
                access_key_updates: {
                  roles_to_publish: ['ADMIN', 'MEMBER'],
                },
                return_item_result: true,
              })
            );
            if (createQuestion.fulfilled.match(resultAction)) {
              const createdItem = resultAction.payload.item;
              const dataCurriculumAssignQuestionItems = responseStatus[0].payload.items;
              const dataQuestionImplementTrans = responseStatus[1].payload.items;

              const itemCurriculumActions: Array<any> = dataCurriculumAssignQuestionItems.map(
                (item) => ({
                  operation: 2,
                  i_id: item.i_id,
                  item: {
                    code: item.code === code ? createdItem.code : item.code,
                  },
                  is_force_update: true,
                })
              );

              const resultBatchAction = await Promise.all([
                dispatch(
                  executeBatchAction({
                    ensure_transaction: true,
                    item_actions: {
                      [QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.id]: itemCurriculumActions,
                    },
                    realtime_auto_link: true,
                  })
                ),
              ]);
              await uploadFileMinioHandler(chunk, resultAction.payload.item);
              if (resultBatchAction.some(executeBatchAction.rejected.match)) {
                // setShowActionErrorModal({
                //   errorVisible: true,
                //   description: '設問情報の更新に失敗しました。 再度入力し、お試しください。',
                //   subTitle: '更新に失敗しました',
                // });
              } else {
                const deletePromises = dataQuestionImplementTrans
                  .filter(
                    (item) => item.curriculum_code && !item.skill_check_code && item.code === code
                  )
                  .map((item) => {
                    const itemId = item.i_id;
                    return dispatch(deleteQuestionTrans({ id: itemId }));
                  });

                await Promise.all(deletePromises);
              }
            }
          }
        }
      } else {
        const dataQuestionImplementTrans = responseStatus[1].payload.items;
        const deletePromises = dataQuestionImplementTrans
          .filter((item) => item.curriculum_code && !item.skill_check_code && item.code === code)
          .map((item) => {
            const itemId = item.i_id;
            return dispatch(deleteQuestionTrans({ id: itemId }));
          });
        await Promise.all(deletePromises);

        const resultAction = await dispatch(
          createQuestion({
            item: {
              name: ques.name,
              description: ques.description,
              problems1: ques.problems1,
              problems2: ques.problems2,
              problems3: ques.problems3,
              answer: ques.answer,
              comment: ques.comment,
              time_limit: timeLimit.find((item) => item.value === ques.time_limit)?.option_id,
              score: ques.score,
              original_question_code: original_question_code,
              version_number: version_number ? Number(version_number) + 1 : 1,
              creator: userInfo?.name,
              company_id: userInfo?.company_id,
              createdat: new Date(),
              createdby: userInfo?.login_id,
              attach_fileID1: ques.attach_fileID1,
              attach_fileID2: ques.attach_fileID2,
              attach_fileID3: ques.attach_fileID3,
              problems1_attach_fileID: ques.problems1_attach_fileID,
              problems2_attach_fileID: ques.problems2_attach_fileID,
              problems3_attach_fileID: ques.problems3_attach_fileID,
              comment_attach_fileID1: ques.comment_attach_fileID1,
              comment_attach_fileID2: ques.comment_attach_fileID2,
              comment_attach_fileID3: ques.comment_attach_fileID3,
              attach_filename1: ques.attach_filename1,
              attach_filename2: ques.attach_filename2,
              attach_filename3: ques.attach_filename3,
              comment_attach_filename1: ques.comment_attach_filename1,
              comment_attach_filename2: ques.comment_attach_filename2,
              comment_attach_filename3: ques.comment_attach_filename3,
              problems1_attach_filename: ques.problems1_attach_filename,
              problems2_attach_filename: ques.problems2_attach_filename,
              problems3_attach_filename: ques.problems3_attach_filename,
            },
            access_key_updates: {
              roles_to_publish: ['ADMIN', 'MEMBER'],
            },
            return_item_result: true,
          })
        );
        if (createQuestion.fulfilled.match(resultAction)) {
          await Promise.all([
            dispatch(
              editQuestion({
                id: resultAction.payload.item_id,
                data: {
                  item: {
                    original_question_code: original_question_code,
                  },
                  return_item_result: true,
                  is_force_update: true,
                },
              })
            ),
            uploadFileMinioHandler(chunk, resultAction.payload.item),
          ]);
        }
      }
    }
  };

  const handleUpdateFile = async () => {
    const filesCode = files.filter((file) => file.status);
    const groupByCode = groupBy(filesCode, 'code');
    dispatch(startLoading());
    for (const key of Object.keys(groupByCode)) {
      if (key) {
        const questionFound = dataAllQuestion.find((q) => q.code === key);

        if (questionFound) {
          const chunk = groupByCode[key];
          await handleCreateQuestion(chunk, questionFound);
        }
      }
    }
    fetchData();
    await fetchAllQuestion();
    dispatch(stopLoading());
    setFiles([]);
    setUploadSuccessfully(true);
  };

  const getFilePreview = async (file: UploadFile<File>) => {
    const validFileType = VALID_MIME_TYPE_QUESTION.includes(file.originFileObj?.type || '');

    const arrCode = dataAllQuestion.map((item) => item.code);

    const fieldNameRegex =
      /^(\d{9})(\d)(設問[1-3]|解説[1-3]|選択[ABC])\.(jpeg|png|gif|svg|webp|mpeg|aac|ogg|webm|wav|mp4|mp3|vnd.dlna.adts)$/;
    const matchResult = file.name.match(fieldNameRegex);

    const isOfficialCurriculum = !!dataAllQuestion.find((item) => matchResult?.[1] === item.code)
      ?.official_curriculum;

    const isCodeInArr = arrCode.includes(matchResult?.[1] || '');

    const isAccept = await new Promise<boolean>((resolve) => {
      if (!file.originFileObj) return resolve(false);
      const src = URL.createObjectURL(new Blob([file.originFileObj]));

      if (includes(VALID_MIME_IMAGE_QUESTION, file.originFileObj.type)) {
        const image = new Image();
        image.src = src;
        image.onload = function () {
          resolve(true);
          URL.revokeObjectURL(src);
        };

        image.onerror = function () {
          console.error('Failed to load the image');
          resolve(true);
        };
      } else if (VALID_MIME_TYPE_AUDIO_QUESTION.includes(file.originFileObj.type)) {
        const audio = document.createElement('audio');
        audio.onloadedmetadata = () => {
          URL.revokeObjectURL(src);
          resolve(true);
        };
        audio.src = src;
        audio.load();
      } else {
        const video = document.createElement('video');
        video.onloadedmetadata = () => {
          URL.revokeObjectURL(src);
          resolve(true);
        };
        video.src = src;
        video.load();
      }
    });
    if (!validFileType || !isAccept || !matchResult || !isCodeInArr || isOfficialCurriculum) {
      return { file: file, status: false };
    } else {
      return {
        file: file,
        status: true,
        code: matchResult[1],
        attach: convertQuestionAttachTextToCode(matchResult[3]),
      };
    }
  };

  const dataSource = useMemo(
    () => files.filter((file) => (showErrorImages ? !file.status : true)),
    [files, showErrorImages]
  );

  const onChange = debounce(async (info: UploadChangeParam<UploadFile<File>>) => {
    const fileList: Array<{
      file: UploadFile<File>;
      status: boolean;
      code?: string;
      attach?: string;
    }> = [];
    dispatch(startLoading());
    for (const chunkFiles of _.chunk(info.fileList, 10)) {
      const filePreviews = await Promise.all(chunkFiles.map((file) => getFilePreview(file)));
      fileList.push(...filePreviews);
    }
    setFiles(fileList);
    dispatch(stopLoading());
  }, 1000);

  const { lengthFileError, lengthFileSuccess } = useMemo(
    () => ({
      lengthFileError: files.filter((item) => item.status === false).length,
      lengthFileSuccess: files.filter((item) => item.status === true).length,
    }),
    [files]
  );

  const fetchAllQuestion = async () => {
    await dispatch(
      getDataAllQuestion({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  };

  useEffect(() => {
    (async () => {
      if (userInfo && isUserInfoChanged) {
        await fetchAllQuestion();
      }
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <Modal
      title="設問画像・動画 インポート"
      width={!files.length ? 720 : 860}
      open={visible}
      okButton={
        !!files.length || isUploadSuccessfully
          ? {
              text: isUploadSuccessfully
                ? 'OK'
                : `${files.length}件のうち${lengthFileSuccess}件を インポートする`,
              onClick: isUploadSuccessfully ? onClose : handleUpdateFile,
              disabled: lengthFileError === files.length && !isUploadSuccessfully,
            }
          : undefined
      }
      cancelButton={
        !isUploadSuccessfully
          ? {
              text: 'キャンセル',
              onClick: onClose,
            }
          : undefined
      }
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {isUploadSuccessfully ? (
          <p className="text-successful">
            <CheckCircleOutlined className="icon" />
            インポートが完了しました！
          </p>
        ) : (
          <>
            {!files.length ? (
              <div className="form-upload">
                <div className="form-upload-border">
                  <Dragger
                    beforeUpload={(file) => {
                      if (!VALID_MIME_TYPE_QUESTION.includes(file?.type || '')) {
                        message.open({
                          type: 'error',
                          content: 'このファイル形式は対応しておりません。',
                          duration: 3,
                          style: {
                            color: 'red',
                          },
                        });
                      }
                      return false;
                    }}
                    showUploadList={false}
                    onChange={onChange}
                    multiple
                  >
                    <div className="file-upload">
                      <CloudUploadOutlined className="icon" />
                      <p className="ant-upload-text">
                        アップロードするファイルをここにドロップ
                        <br />
                        または
                      </p>

                      <div className="flex">
                        <button type="button" className="btn-upload">
                          ファイルを選択
                        </button>
                      </div>
                    </div>
                  </Dragger>
                </div>
                <div className="note">
                  ※設定先のIDをファイル名称に設定してインポートしてください。
                  <br />
                  &ensp;&ensp;IDは設問マスタをエクスポートすることで確認できます。
                  <br />
                  ※画像の種別はjpegまたはpngまたはgifで、10MBまでになります。
                  <br />
                  ※動画はmp4で、100MBまでになります。
                  <br />
                  ※インポートできるファイル数は500件までです。
                </div>
              </div>
            ) : (
              <div className="upload-image">
                <div className="wrap-info">
                  <p className="text-label">
                    インポート件数：{files.length}件 <span className="border"> / </span>
                    紐づけエラー件数：
                    <span className="text-value">{lengthFileError}件</span>
                  </p>
                  <Button
                    className="btn-outline"
                    onClick={() => setShowErrorImages((prevState) => !prevState)}
                  >
                    {showErrorImages ? 'すべて表示する' : 'エラーのみ表示する'}
                  </Button>
                </div>
                <div className="body">
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(5, 152px)',
                      gridAutoRows: '150px',
                      height: '445px',
                      width: '766px',
                    }}
                  >
                    {dataSource.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          gridColumn: (index % 5) + 1,
                          gridRow: Math.floor(index / 5) + 1,
                        }}
                        className={`item-image${!item.status ? ' error' : ''}`}
                      >
                        {includes(item.file.type, 'svg+xml') ? (
                          <object
                            className="svg-object"
                            type="image/svg+xml"
                            data={URL.createObjectURL(item?.file?.originFileObj as Blob)}
                          ></object>
                        ) : includes(VALID_MIME_IMAGE_QUESTION, item?.file?.type || '') ? (
                          <img
                            src={URL.createObjectURL(new Blob([item.file.originFileObj as Blob]))}
                            alt={item.file.name}
                            className="image"
                          />
                        ) : includes(VALID_MIME_TYPE_VIDEO_QUESTION, item.file?.type) ? (
                          <div className="wrap-video">
                            <video width="100%" height="100%" controls>
                              <source
                                src={URL.createObjectURL(
                                  new Blob([item.file.originFileObj as Blob])
                                )}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        ) : (
                          <DetailAudio
                            url={URL.createObjectURL(new Blob([item.file.originFileObj as Blob]))}
                          />
                        )}
                        <p className="name">
                          {!item.status && <CloseOutlined className="icon" />}
                          {item.file.name}
                        </p>
                        <div className="status">
                          {item.status ? (
                            <CheckCircleFilled className="icon-successful" />
                          ) : (
                            <WarningFilled className="icon-warning" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </SectionStyled>
    </Modal>
  );
};

export default UploadManyImageVideo;
