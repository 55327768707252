import { AnyObject } from 'types';
import { unparse } from 'papaparse';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

// export const exportCsv = (dataExport: AnyObject[], headerCsv: AnyObject[], fileName: string) => {
//   const csvHeader = unparse({
//     fields: [...headerCsv.map((item) => item.label)],
//     data: [],
//   });
//   const csvVal = unparse(dataExport, {
//     header: false,
//     columns: [...headerCsv.map((item) => item.key)],
//   });
//   const csvString = csvHeader + csvVal;

//   const bom = '\uFEFF';
//   const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
//   saveAs(file, fileName);
// };

const format = [
  '\tYYYY/MM/DD hh:mm',
  'YYYY/MM/DD hh:mm',
  'YYYY/MM/DD',
  'YYYY/MM',
  'YYYY/MM/DD HH:mm',
];

export const isValidDate = (value: any): boolean => {
  let isValid = false;
  format.forEach((item) => {
    if (dayjs(value, item, true).isValid()) {
      isValid = true;
    }
  });

  return isValid;
};

export const exportCsv = (dataExport: AnyObject[], headerCsv: AnyObject[], fileName: string) => {
  const convertHeader = headerCsv.map((item) => {
    let label = item.label;
    if (isValidDate(label)) {
      label = `'${label}`;
    }
    return {
      ...item,
      label: label,
    };
  });

  const convertData = dataExport.map((item) => {
    for (const key in item) {
      const value = `${`${item[key] || ''}`.trim()}`;

      if (isValidDate(item[key])) {
        item[key] = `'${value}`;
      } else {
        item[key] = value;
      }
    }
    return item;
  });

  const csvHeader = unparse({
    fields: [...convertHeader.map((item) => item.label)],
    data: [],
  });

  const csvVal = unparse(convertData, {
    header: false,
    columns: [...headerCsv.map((item) => item.key)],
  });

  const csvString = csvHeader + csvVal;

  const bom = '\uFEFF';
  const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
  saveAs(file, fileName);
};

export const formatTextCsv = (text: string) => `\u200B${text || ''}`;
